export const CHARTJS_DEFAULT_COLORS = [
  'rgb(54, 162, 235)', // blue
  'rgb(255, 99, 132)', // red
  'rgb(255, 159, 64)', // orange
  'rgb(255, 205, 86)', // yellow
  'rgb(75, 192, 192)', // green
  'rgb(153, 102, 255)', // purple
  'rgb(201, 203, 207)', // grey
];

export const CHARTJS_DEFAULT_COLORS_TRANSPARENT = [
  'rgba(54, 162, 235, 0.3)', // blue
  'rgba(255, 99, 132, 0.3)', // red
  'rgba(255, 159, 64, 0.3)', // orange
  'rgba(255, 205, 86, 0.3)', // yellow
  'rgba(75, 192, 192, 0.3)', // green
  'rgba(153, 102, 255, 0.3)', // purple
  'rgba(201, 203, 207, 0.3)', // grey
];

export const assignDefaultColor = (
  chartColor: string | string[],
  index: number,
  colors: string[],
): string | string[] => {
  if (Array.isArray(chartColor)) {
    return chartColor.map((color, borderColorIndex) => {
      if (color === '') {
        const retColor = colors[borderColorIndex % colors.length];
        if (retColor === undefined) {
          throw new Error('Default color is undefined (this is impossible)');
        }
        return retColor;
      }
      return color;
    });
  }

  if (chartColor === '') {
    const retColor = colors[index % colors.length];
    if (retColor === undefined) {
      throw new Error('Default color is undefined (this is impossible)');
    }
    return retColor;
  }

  return chartColor;
};
