import { ImageBlock } from 'editor-content/Block.js';
import { isTextSelection } from '../../../../editor/selection/TextSelection.js';
import cond from '../../../../junkDrawer/cond.js';
import guard from '../../../../junkDrawer/guard.js';
import { hydratedIsImageBlock } from './hydratedBlockGuards.js';
import identity from '../../../../junkDrawer/identity.js';
import { BodyStateSelected } from './BodyEditor.js';
import { canAlignImage } from '../canAlignBlockImage.js';

export function setImageWidth(
  initialState: BodyStateSelected,
  width: ImageBlock['width'],
): BodyStateSelected {
  const { content, selection } = initialState;

  if (!isTextSelection(selection)) return initialState;

  return {
    content: content.map(
      cond(
        (a, i) => i === selection.index,
        guard(
          hydratedIsImageBlock,
          (block) => ({
            ...block,
            width: width,
          }),
          identity,
        ),
        identity,
      ),
    ),
    selection,
  };
}

export function setImageAlign(
  initialState: BodyStateSelected,
  align: ImageBlock['align'],
): BodyStateSelected {
  const { content, selection } = initialState;

  if (!isTextSelection(selection)) return initialState;

  return {
    content: content.map(
      cond(
        (a, i) => i === selection.index,
        guard(
          hydratedIsImageBlock,
          (block) => ({
            ...block,
            align: align,
          }),
          identity,
        ),
        identity,
      ),
    ),
    selection,
  };
}

export function replaceImage(
  initialState: BodyStateSelected,
  newData: Pick<ImageBlock, 'guid' | 'dimensions'>,
): BodyStateSelected {
  const { content, selection } = initialState;
  if (!isTextSelection(selection)) return initialState;

  return {
    content: content.map(
      cond(
        (a, i) => i === selection.index,
        guard(
          hydratedIsImageBlock,
          (block) => ({
            ...block,
            align: !canAlignImage(newData) ? 'center' : block.align,
            guid: newData.guid,
            dimensions: newData.dimensions,
          }),
          identity,
        ),
        identity,
      ),
    ),
    selection,
  };
}
