import { ImageBlock } from 'editor-content/Block.ts';
import Image from '../../../../../../../design-system/atoms/Image.tsx';
import useBreakpoints from '../../../../../../../services/useBreakpoints.ts';
import useFetchImage from '../../../../../../../services/useFetchImage.tsx';
import styles from './CommentQuote.module.scss';

const QuotedImageBlock = ({
  block,
  imageEdited,
  imageDeleted,
}: {
  block: ImageBlock;
  imageEdited: boolean;
  imageDeleted: boolean;
}) => {
  const { guid, dimensions } = block;
  const src = useFetchImage(guid);

  const { isMobile } = useBreakpoints();
  const imageWidth = isMobile ? 200 : 160;
  const imageHeight = isMobile ? 160 : 112;
  const originalHeight = dimensions?.height ?? imageWidth;
  const originalWidth = dimensions?.width ?? imageWidth;

  const calculatedHeight =
    (dimensions?.width ?? 0) > (dimensions?.height ?? 0)
      ? (originalHeight / originalWidth) * imageWidth
      : imageHeight;

  const calculatedWidth =
    (dimensions?.width ?? 0) > (dimensions?.height ?? 0)
      ? imageWidth
      : (originalWidth / originalHeight) * imageHeight;

  return (
    <div className={styles.commentOrReply__image}>
      <div style={{ width: calculatedWidth }}>
        <Image
          src={src}
          dimensions={{ width: calculatedWidth, height: calculatedHeight }}
        />
      </div>

      {imageEdited && (
        <div className={styles.commentOrReply__imageBlock__edited}>
          (Image was replaced since this was posted.)
        </div>
      )}

      {imageDeleted && (
        <div className={styles.commentOrReply__imageBlock__edited}>
          (Image was deleted since this was posted.)
        </div>
      )}
    </div>
  );
};

export default QuotedImageBlock;
