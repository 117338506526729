import React from 'react';
import { CommentsState } from './useComments/useComments.js';
import { PublishedSection } from 'api-client/types.js';
import cx from 'classnames';
import styles from './CommentsSidebar.module.scss';
import IconButton from '../../../../../design-system/atoms/IconButton.js';
import CommentsActionBar from './commentsActionBar/CommentsActionBar.js';
import { motion } from 'framer-motion';
import { CommentsSection } from './commentsSection/CommentsSection.js';
import CommentsSidebarModalLayer from '../CommentsSidebarModalLayer.js';
import { preventDefault } from '../../../../../editor/domFacing/events/isKeyMatch.js';
import { useAtomValue } from 'jotai';
import { ReactComponent as DiscussionBubbleImage } from '../../../../../images/DiscussionBubbles.svg';

function CommentsFilteredEmpty({
  filterState,
  onClear,
}: {
  filterState: 'resolved' | 'starred';
  onClear: () => void;
}) {
  let filterText: string;

  switch (filterState) {
    case 'resolved':
      filterText = 'resolved';
      break;
    case 'starred':
      filterText = 'starred';
      break;
  }

  return (
    <div className={styles.commentsSidebar__emptyContainer}>
      <p className={styles.commentsSidebar__emptyContainerText}>
        No comments have
        <br /> been {filterText}
      </p>
      <a
        className={styles.commentsSidebar__emptyContainerClear}
        href="#"
        onClick={preventDefault(onClear)}
      >
        Clear Filter
      </a>
    </div>
  );
}

const TotalCommentsCount: React.FC<{
  commentsState: NonNullable<CommentsState>;
}> = ({ commentsState }) => {
  const totalCommentCount = useAtomValue(commentsState.totalCommentCountAtom);
  return <span>Comments ({totalCommentCount})</span>;
};

const CallToAction: React.FC = () => {
  return (
    <div className={styles.commentsSidebar_callToAction}>
      <div className={styles.commentsSidebar_callToAction__image}>
        <DiscussionBubbleImage />
      </div>
      <div
        className={styles.commentsSidebar_callToAction__title}
        data-testid="start-the-discussion"
      >
        Start the Discussion
      </div>
      <div className={styles.commentsSidebar_callToAction__subtitle}>
        Be the first to add a comment or question.
      </div>
      <div className={styles.commentsSidebar_callToAction__instructions}>
        Add comments by highlighting text. Invite
        <br />
        others into your comment by tagging them
        <br />
        using '@'
      </div>
    </div>
  );
};

const CommentsSidebar: React.FC<{
  isFullscreenMode: boolean;
  commentsState: NonNullable<CommentsState>;
  publishedSections: PublishedSection[];
}> = ({ isFullscreenMode, commentsState, publishedSections }) => {
  const doFilteredCommentsExist = useAtomValue(
    commentsState.filteredCommentsExistAtom,
  );

  const totalCommentCount = useAtomValue(commentsState.totalCommentCountAtom);

  return (
    <div
      className={cx(
        styles.commentsSidebar,
        isFullscreenMode && styles.commentsSidebar_fullscreen,
      )}
    >
      <div className={styles.commentsSidebar__header}>
        <IconButton
          aria-label="hide-comments"
          name="doubleChevronRight"
          onClick={commentsState.closeComments}
          className={styles.commentsSidebar__closeButton}
        />
        <TotalCommentsCount commentsState={commentsState} />
      </div>
      <CommentsActionBar commentsState={commentsState} />
      {totalCommentCount == 0 && <CallToAction />}

      <div
        className={styles.commentsSidebar__main}
        ref={commentsState.setCommentsPanelRef}
      >
        {commentsState.filterState && !doFilteredCommentsExist ? (
          <CommentsFilteredEmpty
            filterState={commentsState.filterState}
            onClear={() => commentsState.setFilter(null)}
          />
        ) : (
          publishedSections.map((publishedSection) => (
            <motion.div
              layout
              key={publishedSection.sectionId}
              transition={{ duration: 0.2 }}
              style={{ backgroundColor: 'white' }}
            >
              <CommentsSection
                key={publishedSection.sectionId}
                state={commentsState.forSection(publishedSection)}
              />
            </motion.div>
          ))
        )}
      </div>
      <CommentsSidebarModalLayer />
    </div>
  );
};

export default CommentsSidebar;
