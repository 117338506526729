import IconButton from '../../../design-system/atoms/IconButton.tsx';
import { CommentsStateForSection } from './publish/commentsSidebar/useComments/useComments.ts';
import Tooltip from '../../../design-system/organisms/Tooltip.tsx';
import styles from './CommentIndicator.module.scss';

type CommentIndicatorProps = {
  commentsState?: CommentsStateForSection;
  commentCount: number;
  blockId: string;
  className?: string;
};

export const CommentIndicator = ({
  commentsState,
  commentCount,
  blockId,
  className,
}: CommentIndicatorProps) => {
  if (commentCount === 0) {
    return null;
  }

  const tipText = commentCount === 1 ? '1 comment' : `${commentCount} comments`;
  return (
    <Tooltip
      tipText={tipText}
      active
      className={className}
      tipClassName={styles.commentIndicator__tip}
    >
      <span
        data-testid="comment-indicator"
        className={styles.commentIndicator__wrapper}
      >
        <IconButton
          name="speechBubbleWithText"
          aria-label="Comment"
          className={styles.commentIndicator__icon}
          onClick={(e) => {
            if (!commentsState) return;
            const commentsForBlock =
              commentsState.filteredCommentsWithActions.filter(
                (c) => c.selection?.block.id === blockId,
              );
            const mostRecentComment = commentsForBlock[0];
            if (mostRecentComment) {
              commentsState.openComment(mostRecentComment.id);
            }
            e.preventDefault();
            e.stopPropagation();
          }}
        ></IconButton>
        <span className={styles.commentIndicator__count}>{commentCount}</span>
      </span>
    </Tooltip>
  );
};

export default CommentIndicator;
