import { isError } from 'lodash';
import useApi from '../api/useApi.ts';
import { ErrorWithContext, logError, logInfo } from '../logging/logger.ts';
import { getBase64 } from '../pages/zeck/edit/image/getImageDimensions.ts';

export const IMAGE_FILE_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/webp',
  'image/svg+xml',
];

const useImageUpload = () => {
  const { createImage, aiDescribeImage } = useApi();

  return async (file: File, companyId: string) => {
    const error = (message: string, logString: string) => {
      logInfo('Image Upload Error: ' + message + ' ' + logString, {
        fingerprint: [message],
      });
      return { type: 'error' as const, message };
    };

    const success = <T>(data: T) => {
      return { type: 'success' as const, data };
    };

    if (file.size > 50 * 1000 * 1000) {
      return error(
        'Uploaded images must be less than 50MB. Sorry for being so mean about it.',
        'File size: ' + file.size,
      );
    }

    if (file.type === 'image/gif' && file.size > 20 * 1000 * 1000) {
      return error(
        'Uploaded gifs must be less than 20MB. Sorry for being so mean about it.',
        'File size: ' + file.size,
      );
    }

    if (!IMAGE_FILE_TYPES.includes(file.type)) {
      return error(
        "This image type isn't supported, please select different one.",
        'File type: ' + file.type,
      );
    }

    try {
      const {
        dimensions: { width, height },
      } = await getBase64(file);

      let imageId: string;
      try {
        imageId = await createImage({
          companyId,
          file,
        });
      } catch (e) {
        return error(
          'There was an issue uploading this image, please try again.',
          'Error: ' + (e as Error)?.message || '(unknown error message)',
        );
      }

      try {
        await aiDescribeImage(imageId);
      } catch (e) {
        logError(
          new ErrorWithContext(
            'failed to ai describe image',
            {
              imageId,
              sourceError: (e as Error)?.message,
            },
            'aiDescribeImage',
          ),
        );
      }

      return success({ imageId, width, height });
    } catch (e) {
      let errorMessage = '(unknown error message)';
      if (isError(e)) {
        errorMessage = e.message;
      } else if (typeof e === 'string') {
        errorMessage = e;
      }

      return error(
        'There was an issue processing this image, please select different one.',
        'Error: ' + errorMessage,
      );
    }
  };
};
export default useImageUpload;
