import { RefObject, useState } from 'react';
import { Zeck, Section } from '../../../../types.ts';
import PreviewBanner from './PreviewBanner.tsx';
import ViewTopBar from '../ViewTopBar.tsx';
import PreviewSidebar from './PreviewSidebar.tsx';
import ViewPageLayout from '../../../../design-system/layouts/ViewPageLayout.tsx';
import { BrandKitResource } from '../../../../design-system/zeck/WithBrandKit.tsx';
import BrandLogo from '../BrandLogo.tsx';
import { UserAndCompany } from '../../../../userAndCompany/FetchUserAndCompany.tsx';
import Link from '../../../../design-system/atoms/Link.tsx';
import { zeckPreviewPath } from '../../../../services/Paths.ts';

type PreviewPageProps = {
  brandKitResource: BrandKitResource;
  zeck: Zeck & {
    actions: { publish: () => Promise<void> };
  };
  section?: Section;
  onClickExitPreview(): void;
  userAndCompany: UserAndCompany;
  children: React.ReactNode;
  scrollContainerRef?: RefObject<HTMLDivElement>;
  scrollToSectionOrZeckCover: (sectionId?: string) => void;
};

const PreviewPage = ({
  brandKitResource,
  zeck,
  section,
  onClickExitPreview,
  userAndCompany,
  children,
  scrollContainerRef,
  scrollToSectionOrZeckCover,
}: PreviewPageProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <ViewPageLayout
      {...{
        scrollContainerRef,
        bannerSlot: (
          <PreviewBanner {...{ zeck, section, onClickExitPreview }} />
        ),
        headerSlot: (
          <ViewTopBar
            onClickOpenSidebar={(e) => {
              setMenuIsOpen(true);
              e.stopPropagation();
            }}
            zeck={zeck}
            showKebab={false}
            showComments={false}
          >
            <Link href={zeckPreviewPath(zeck)}>
              <BrandLogo
                brandKitResource={brandKitResource}
                company={userAndCompany.activeCompany}
              />
            </Link>
          </ViewTopBar>
        ),
        sidebarSlot: (
          <PreviewSidebar
            {...{
              isOpen: menuIsOpen,
              onRequestClose() {
                setMenuIsOpen(false);
              },
              zeck,
              scrollToSectionOrZeckCover,
            }}
          />
        ),
      }}
    >
      {children}
    </ViewPageLayout>
  );
};

export default PreviewPage;
