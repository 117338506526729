import useLocalStorageState from '../services/useLocalStorageState.js';
import { useCallback, useEffect } from 'react';
import { decodeAccessToken } from './jwt.js';
import { atom, useAtom } from 'jotai';

type ValidatedAccessToken = {
  accessToken: string;
  exp: number;
};

function isExpired(exp: number | null) {
  return exp !== null && exp * 1000 < Date.now();
}

export function validateAccessToken(
  accessToken: string | null,
): ValidatedAccessToken | null {
  const data = decodeAccessToken(accessToken);

  if (!accessToken || !data || !data.exp || isExpired(data.exp)) {
    return null;
  }

  return { accessToken, exp: data.exp * 1000 };
}

// use a fudge factor of 30 minutes to account for clock skew
const FUDGE_FACTOR = 30 * 60 * 1000;

export const accessTokenAtom = atom<null | string>(null);

const useAccessToken = () => {
  const [accessToken, setAccessTokenState] =
    useLocalStorageState('accessToken');
  const validatedAccessToken = validateAccessToken(accessToken);
  const [, setAccessTokenAtom] = useAtom(accessTokenAtom);

  const setAccessToken = useCallback(
    (accessToken: string | null) => {
      setAccessTokenState(accessToken);
      setAccessTokenAtom(accessToken);
    },
    [setAccessTokenState, setAccessTokenAtom],
  );

  useEffect(() => {
    if (!validatedAccessToken) {
      setAccessToken(null);
      return;
    }
  }, [validatedAccessToken, setAccessToken, setAccessTokenAtom]);

  const validatedAccessTokenValue = validatedAccessToken?.accessToken ?? null;

  useEffect(() => {
    setAccessTokenAtom(validatedAccessTokenValue);
  }, [validatedAccessTokenValue, setAccessTokenAtom]);

  function isTokenExpired() {
    return (
      validatedAccessToken &&
      validatedAccessToken.exp &&
      Date.now() > validatedAccessToken.exp * 1000 - FUDGE_FACTOR
    );
  }

  return {
    accessToken: validatedAccessToken?.accessToken ?? null,
    setAccessToken,
    isTokenExpired,
  };
};

export default useAccessToken;
