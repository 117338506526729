import React from 'react';
import LabelEditable from './LabelEditable.tsx';
import { LabelBlock } from 'editor-content/Block.js';
import useTextBlockKeyboardEvents from '../selection/useTextBlockKeyboardEvents.ts';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import { WithHoverNextToSelection } from './HoverNextToSelection.tsx';
import EditableProps from './EditableProps.ts';

type LabelEditableWithKeyboardProps = {
  className?: string;
  onNavUp(): void;
  onNavDown(): void;
  onSelectOut(): void;
  formattingMenu: React.ReactNode;
} & EditableProps<LabelBlock>;

const LabelEditableWithKeyboard = React.forwardRef<
  HTMLElement,
  LabelEditableWithKeyboardProps
>(
  (
    {
      linkables,
      onSelect,
      value,
      onChange,
      selection,
      onNavUp,
      onNavDown,
      onSelectOut,
      formattingMenu,
      ...otherProps
    },
    ref,
  ) => {
    const { handleKeyDown, ref: keyboardEventRef } =
      useTextBlockKeyboardEvents<HTMLParagraphElement>({
        onArrowUpOut() {
          onNavUp();
        },
        onArrowDownOut() {
          onNavDown();
        },
        onSelectOut() {
          onSelectOut();
        },
      });

    return (
      <WithHoverNextToSelection
        hoverContent={formattingMenu}
        selection={selection}
      >
        {(selectionRef) => (
          <LabelEditable
            {...otherProps}
            {...{
              value,
              onChange,
              selection,
              onSelect,
              linkables,
              onKeyDown: handleKeyDown,
            }}
            ref={mergeRefs([ref, keyboardEventRef, selectionRef])}
          />
        )}
      </WithHoverNextToSelection>
    );
  },
);

export default LabelEditableWithKeyboard;
