import { createAgendaBlock } from 'editor-content/AgendaBlock.js';
import {
  createBulletedListItemBlock,
  createCartaCapTableBlock,
  createDividerBlock,
  createHeadingBlock,
  createLabelBlock,
  createNumberedListItemBlock,
  createParagraphBlock,
  createVideoBlock,
} from 'editor-content/Block.js';
import { compressTableBlock } from 'editor-content/TableBlock.js';
import React from 'react';
import { IntegrationListItemData } from '../../../api/endpoints/createIntegrationApi.js';
import useApi from '../../../api/useApi.js';
import { ElementAndData } from '../../../junkDrawer/useElementAndDataArray.js';
import EmbedVideoModal from '../../../pages/zeck/editor/addBlock/EmbedVideoModal.js';
import IntegrationsModal from '../../../pages/zeck/editor/addBlock/integrationsModal/IntegrationsModal.js';
import {
  apiKey,
  clientId,
} from '../../../pages/zeck/editor/addBlock/integrationsModal/utils/google/googleEnvVariables.js';
import { HydratedBlock } from '../../../types/HydratedBlock.js';
import { useActiveCompany } from '../../../userAndCompany/activeCompanyAtom.tsx';
import { createOutstandingVoteBlock } from '../../../VoteBlockHydrated.js';
import { FocusedBlock } from '../../actions/pressForwardSlash.ts';
import {
  FileUploadErrorModal,
  FileUploadLoadingModal,
} from '../../domFacing/components/FileUploadModal.js';
import { useAddBlock } from '../AddBlockExperience.js';
import { AddBlockItem } from '../AddBlockConfiguration.js';
import { uploadAndCreateFileBlock } from './uploadAndCreateFileBlock.js';
import useImageUpload from '../../../services/imageUpload.js';
import {
  ImageUploadErrorModal,
  ImageUploadLoadingModal,
} from '../../domFacing/components/ImageUploadModal.js';
import { uploadAndCreateImageBlock } from './uploadAndCreateImageBlock.js';

export const useAddBlockZeck = ({
  blocksWithEl,
  leftGutterRef,
  onAddNewBlock,
  onReplaceNewBlock,
  onConfigureComplexBlock,
  onForwardSlash,
  integrationData,
}: {
  blocksWithEl: ElementAndData<HydratedBlock>[];
  leftGutterRef: React.RefObject<HTMLElement>;
  onAddNewBlock: (targetIndex: number, newBlock: HydratedBlock) => void;
  onReplaceNewBlock: (
    newContent: HydratedBlock[],
    targetBlockId: string,
  ) => void;
  onConfigureComplexBlock: () => void;
  onForwardSlash: () => FocusedBlock | void;
  // props we don't like
  integrationData: IntegrationListItemData;
}) => {
  const hasGoogleIntegration = !!clientId && !!apiKey;

  const { createFile } = useApi();
  const uploadImage = useImageUpload();
  const company = useActiveCompany();

  const uploadFile = (file: File) =>
    createFile({
      companyId: company.id,
      file,
    });

  const options: (AddBlockItem<HydratedBlock> | false)[] = [
    {
      type: 'block',
      label: 'Heading',
      blockType: 'heading',
      description: 'A large text heading',
      iconName: 'h1',
      createNewBlock: () => [createHeadingBlock([])],
    },
    {
      type: 'block',
      label: 'Label',
      blockType: 'label',
      description: 'Label a section of content',
      iconName: 'label',
      createNewBlock: () => [createLabelBlock([])],
    },
    {
      type: 'async-block',
      label: 'Image',
      blockType: 'image',
      description: 'A photo or graphic',
      iconName: 'image',
      createNewBlock: uploadAndCreateImageBlock((file) =>
        uploadImage(file, company.id),
      ),
      renderError: ({ message, onClose }) => (
        <ImageUploadErrorModal
          message={message}
          onRequestClose={onClose}
          isOpen
        />
      ),
      renderLoading: () => <ImageUploadLoadingModal isOpen />,
    },
    {
      type: 'block',
      label: 'Bulleted List',
      blockType: 'bulleted-list-item',
      description: 'A simple list of items',
      iconName: 'bulletedList',
      createNewBlock: () => [createBulletedListItemBlock([], 0)],
    },
    {
      type: 'block',
      label: 'Numbered List',
      blockType: 'numbered-list-item',
      description: 'A list with numerical order',
      iconName: 'numberedList',
      createNewBlock: () => [createNumberedListItemBlock([], 0)],
    },
    hasGoogleIntegration && {
      type: 'complex-block',
      label: 'Google Sheets',
      blockType: 'googleSheets',
      description: 'Add content from a Sheets file',
      iconName: 'googleSpreadsheet',
      renderAdding: ({ onAdd, onCancel }) => (
        <IntegrationsModal
          isOpen={true}
          integrationData={integrationData}
          integrationType="google"
          onCreateTable={(table) => {
            onAdd([compressTableBlock(table)]);
          }}
          onAddCapTable={(capTable) => {
            onAdd([createCartaCapTableBlock(capTable)]);
          }}
          onClose={onCancel}
        />
      ),
    },

    {
      type: 'complex-block',
      label: 'Microsoft Excel',
      blockType: 'microsoftExcel',
      description: 'Add content from an Excel file',
      iconName: 'microsoftExcel',
      renderAdding: ({ onAdd, onCancel }) => (
        <IntegrationsModal
          isOpen={true}
          integrationData={integrationData}
          integrationType="excelUpload"
          onCreateTable={(table) => {
            onAdd([compressTableBlock(table)]);
          }}
          onAddCapTable={(capTable) => {
            onAdd([createCartaCapTableBlock(capTable)]);
          }}
          onClose={onCancel}
        />
      ),
    },
    {
      type: 'complex-block',
      label: 'Carta Cap Table',
      blockType: 'carta-cap-table',
      description: 'Add a summary cap table from Carta',
      iconName: 'carta',
      renderAdding: ({ onAdd, onCancel }) => (
        <IntegrationsModal
          isOpen={true}
          integrationData={integrationData}
          integrationType="cartaCapTable"
          onCreateTable={(table) => {
            onAdd([compressTableBlock(table)]);
          }}
          onAddCapTable={(capTable) => {
            onAdd([createCartaCapTableBlock(capTable)]);
          }}
          onClose={onCancel}
        />
      ),
    },
    {
      type: 'block',
      label: 'Divider',
      blockType: 'divider',
      description: 'A visual divider to break up content',
      iconName: 'divider',
      createNewBlock: () => [createDividerBlock()],
    },
    {
      type: 'complex-block',
      label: 'Video',
      blockType: 'video',
      description: 'Embed a Youtube or Vimeo video',
      iconName: 'video',
      renderAdding: ({ onAdd, onCancel }) => (
        <EmbedVideoModal
          isOpen={true}
          onEmbedVideo={({ url }) => {
            onAdd([createVideoBlock(url)]);
          }}
          onClose={onCancel}
        />
      ),
    },
    {
      type: 'async-block',
      label: 'File',
      blockType: 'file',
      description: 'Upload a file',
      iconName: 'paperclip',
      createNewBlock: uploadAndCreateFileBlock(uploadFile),
      renderLoading: () => <FileUploadLoadingModal isOpen />,
      renderError: ({ message, onClose }) => (
        <FileUploadErrorModal
          isOpen
          message={message}
          onRequestClose={onClose}
        />
      ),
    },
    { type: 'separator', label: 'ZECK BLOCKS™' },
    {
      type: 'block',
      label: 'Vote',
      blockType: 'vote',
      description: 'Review, approve, and record votes',
      iconName: 'vote',
      createNewBlock: () => [createOutstandingVoteBlock('', '')],
    },
    {
      type: 'block',
      label: 'Meeting Agenda',
      blockType: 'agenda',
      description: 'Build an agenda from sections',
      iconName: 'calendar',
      createNewBlock: () => [createAgendaBlock('', '', [])],
    },
  ];

  const addBlockBehavior = useAddBlock({
    blocksWithEl,
    leftGutterRef,
    onAddNewBlock: (targetIndex) => {
      const newBlock = createParagraphBlock([]);
      onAddNewBlock(targetIndex, newBlock);
      return newBlock.id;
    },
    onReplaceNewBlock,
    onForwardSlash,
    onConfigureComplexBlock,
    addBlockOptions: options,
  });
  return addBlockBehavior;
};
