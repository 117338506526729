import { isNotPendingUser, MaybePendingUser } from '../../../../../types.ts';
import CommentVisibilityHelp from './CommentVisibilityHelp.tsx';
import PublishedCommentForm from './PublishedCommentForm.js';
import { CommentThreadPanel } from './CommentThread.js';
import CommentNameplate from './CommentNameplate.js';
import PublishedCommentLayout from './PublishedCommentLayout.tsx';
import { Comment } from 'api-client/types.js';

type CommentFormMainProps = {
  userName: string;
  viewers: MaybePendingUser[];
  onSubmit: (comment: Pick<Comment, 'type' | 'content'>) => Promise<unknown>;
  onCancel: () => void;
  className?: string;
};

const NewCommentForm: React.FC<CommentFormMainProps> = ({
  userName,
  viewers,
  onSubmit,
  className,
  onCancel,
}) => {
  return (
    <CommentThreadPanel isSelected>
      <PublishedCommentLayout>
        <CommentNameplate userName={userName} />
        <PublishedCommentForm
          {...{
            testId: 'new-comment',
            onCancel: onCancel,
            initialValue: { content: [], isDirectMessage: false },
            canToggleDirectMessage: true,
            userName,
            availableTags: viewers.filter(isNotPendingUser).map((u) => ({
              userId: u.id,
              displayName: `${u.firstName} ${u.lastName}`,
            })),
            onSubmit: async (value) => {
              await onSubmit({
                content: value.content,
                type: value.isDirectMessage ? 'DirectMessage' : 'Comment',
              });
            },
            renderHelp: ({ isDirectMessage }) =>
              isDirectMessage ? null : (
                <CommentVisibilityHelp viewers={viewers} />
              ),
            placeholder: 'Add a comment or question...',
            submitText: 'Comment',
            className,
          }}
        />
      </PublishedCommentLayout>
    </CommentThreadPanel>
  );
};

export default NewCommentForm;
