import { CommentContentNode } from 'editor-content/CommentContent.js';
import { FileBlock } from 'editor-content/FileBlock.js';
import { useState } from 'react';
import useApi from '../../../api/useApi.js';
import FileFormattingMenu from '../../../design-system/organisms/FileFormattingMenu.tsx';
import HoverNextToPoint from '../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import { uploadAndCreateFileBlock } from '../../../editor/addBlock/zeck/uploadAndCreateFileBlock.js';
import {
  FileUploadErrorModal,
  FileUploadLoadingModal,
} from '../../../editor/domFacing/components/FileUploadModal.tsx';
import { isSameSelection } from '../../../SelectionComment.ts';
import { User } from '../../../types.ts';
import SelectionCommentsMenu from './comments/components/SelectionCommentsMenu.tsx';
import SelectionCommentWithActions from './comments/SelectionCommentWithActions.ts';
import { useActiveCompany } from '../../../userAndCompany/activeCompanyAtom.tsx';
import { match } from 'ts-pattern';

type FileFormattingExperienceProps = {
  block: FileBlock;
  getEl: () => HTMLElement | undefined;
  onReplaceFile: (file: Pick<FileBlock, 'guid' | 'filename'>) => void;
  onDeleteFile: () => void;
  onAddSelectionComment: (
    blockId: string,
    commentText: CommentContentNode[],
  ) => Promise<void>;
  user: User;
  selectionComments: SelectionCommentWithActions[];
  zeckId: string;
  sectionId: string;
};

type BlockInteractiveRenderState =
  | { type: 'formatting' }
  | { type: 'commenting' }
  | { type: 'replacing' }
  | { type: 'replacing-error'; message: string };

const FileFormattingExperience: React.FC<FileFormattingExperienceProps> = ({
  block,
  getEl,
  onReplaceFile,
  onDeleteFile,
  onAddSelectionComment,
  user,
  selectionComments,
  zeckId,
  sectionId,
}) => {
  const { createFile } = useApi();
  const company = useActiveCompany();

  const uploadFile = (file: File) =>
    createFile({
      companyId: company.id,
      file,
    });

  const [interactiveState, setInteractiveState] =
    useState<BlockInteractiveRenderState>({ type: 'formatting' });

  return (
    <>
      {interactiveState.type === 'formatting' && (
        <HoverNextToPoint
          viewportPolicy="none"
          containerStyles={{ zIndex: 'initial' }}
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height - 16,
            ];
          }}
          usePortal
        >
          <FileFormattingMenu
            {...{
              onClickReplace: async () => {
                setInteractiveState({ type: 'replacing' });
                const result = await uploadAndCreateFileBlock(uploadFile)();

                match(result)
                  .with({ type: 'cancel' }, () =>
                    setInteractiveState({ type: 'formatting' }),
                  )
                  .with({ type: 'success' }, (result) => {
                    setInteractiveState({ type: 'formatting' });
                    onReplaceFile({
                      guid: result.data.guid,
                      filename: result.data.filename,
                    });
                  })
                  .with({ type: 'error' }, (result) => {
                    setInteractiveState({
                      type: 'replacing-error',
                      message: result.message,
                    });
                  })
                  .exhaustive();
              },
              onClickDelete: () => {
                onDeleteFile();
              },
              onClickComment: () => {
                setInteractiveState({ type: 'commenting' });
              },
            }}
          />
        </HoverNextToPoint>
      )}
      {interactiveState.type === 'commenting' && (
        <HoverNextToPoint
          usePortal
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height + 24,
            ];
          }}
        >
          <SelectionCommentsMenu
            autofocus
            user={user}
            comments={selectionComments.filter((selectionComment) =>
              isSameSelection(selectionComment, block),
            )}
            onPostComment={(content) =>
              onAddSelectionComment(block.id, content)
            }
            zeckId={zeckId}
            sectionId={sectionId}
          />
        </HoverNextToPoint>
      )}
      <FileUploadLoadingModal isOpen={interactiveState.type === 'replacing'} />;
      <FileUploadErrorModal
        message={
          interactiveState.type === 'replacing-error'
            ? interactiveState.message
            : ''
        }
        onRequestClose={() => setInteractiveState({ type: 'formatting' })}
        isOpen={interactiveState.type === 'replacing-error'}
      />
    </>
  );
};

export default FileFormattingExperience;
