import { TableBlock } from 'editor-content/TableBlock.ts';
import { useErrorModal } from '../../../../../../editor/errorModal/ErrorModal.tsx';

const useTableSyncStickyMergeCheck = () => {
  const { setError } = useErrorModal();

  const getStickyValuesAndMaybeSetError = (
    block: TableBlock,
    updatedTableBlock: TableBlock['data'],
  ) => {
    const hasMergeInFrozenRow =
      (block.stickyRows ?? -1) >= 0 &&
      (updatedTableBlock.merges ?? []).some(
        (merge) => merge.startRow < (block.stickyRows ?? -1),
      );

    const hasMergeInFrozenColumn =
      (block.stickyColumns ?? -1) >= 0 &&
      (updatedTableBlock.merges ?? []).some(
        (merge) => merge.startColumn < (block.stickyColumns ?? -1),
      );

    let stickyInfo = {
      stickyRows: block.stickyRows,
      stickyColumns: block.stickyColumns,
    };

    if (hasMergeInFrozenRow || hasMergeInFrozenColumn) {
      stickyInfo = {
        stickyRows: hasMergeInFrozenRow ? undefined : block.stickyRows,
        stickyColumns: hasMergeInFrozenColumn ? undefined : block.stickyColumns,
      };

      setError({
        title: 'Invalid Merge Cell in Frozen Area',
        message: `Your updated table had a merge cell in a ${hasMergeInFrozenRow ? 'row' : 'column'} you had frozen. We have unfrozen the table for you.`,
      });
    }

    return stickyInfo;
  };

  return { getStickyValuesAndMaybeSetError };
};

export default useTableSyncStickyMergeCheck;
