import { ValueType, Workbook } from 'exceljs';
import {
  ModalActions,
  ModalPanel,
} from '../../../../../../design-system/organisms/Modal.tsx';
import { TableBlock } from 'editor-content/TableBlock.ts';
import {
  SectionList,
  SectionListItem,
} from '../../../../../../design-system/organisms/SectionList.tsx';
import { useState } from 'react';
import OutlineButton from '../../../../../../design-system/atoms/OutlineButton.tsx';
import createTableFromExcelWorksheet, {
  getDefinedNameData,
} from '../utils/excel/createTableFromExcelWorksheet.ts';
import Button from '../../../../../../design-system/atoms/Button.tsx';
import styles from './MicrosoftExcelSheetPicker.module.scss';
import Tabs from '../../../../../../design-system/organisms/Tabs.tsx';
import { compact } from 'lodash';
import { DocumentMeta } from '../integrationModalTypes.ts';

type MicrosoftExcelSheetPickerProps = {
  documentMeta: DocumentMeta;
  onClose: () => void;
  onPickSheet: (
    tableData: TableBlock['data'],
    integrationData: IntegrationData,
  ) => void;
  workbook: Workbook;
  valueTypes: typeof ValueType;
};

type IntegrationData = {
  sheetId: number;
  sheetOrRangeName: string;
  selectionType: 'sheet' | 'range';
};

const EntireSheetPicker: React.FC<MicrosoftExcelSheetPickerProps> = ({
  onClose,
  onPickSheet,
  workbook,
  valueTypes,
  documentMeta,
}) => {
  const [selectedSheet, setSelectedSheet] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onSelectSheet = () => {
    if (selectedSheet !== null) {
      const worksheet = workbook.getWorksheet(selectedSheet);

      if (!worksheet) {
        setErrorMessage(`Could not find worksheet ${selectedSheet}`);
        return;
      }

      if (!worksheet.rowCount) {
        setErrorMessage(`No data available for ${worksheet.name}`);
      } else {
        onPickSheet(createTableFromExcelWorksheet(worksheet, valueTypes), {
          sheetId: worksheet.id,
          sheetOrRangeName: worksheet.name,
          selectionType: 'sheet',
        });
      }
    }
  };

  return (
    <div className={styles.microsoftSheetPicker__body}>
      {errorMessage && (
        <div className={styles.microsoftSheetPicker__empty_message}>
          <div className={styles.microsoftSheetPicker__error_message}>
            {errorMessage}
          </div>

          <a
            className={styles.microsoftSheetPicker__try_again_link}
            onClick={() => setErrorMessage(null)}
          >
            Try again
          </a>
        </div>
      )}

      {!errorMessage && (
        <SectionList>
          {documentMeta.sheets.map((sheet) => (
            <SectionListItem
              key={sheet.sheetId}
              active={sheet.title === selectedSheet}
              onClick={() => {
                setSelectedSheet(sheet.title);
              }}
            >
              {sheet.title}
            </SectionListItem>
          ))}
        </SectionList>
      )}

      <ModalActions className={styles.modalActions}>
        <OutlineButton color="secondary" size="medium" onClick={onClose}>
          Cancel
        </OutlineButton>
        <Button
          color="primary"
          size="medium"
          disabled={selectedSheet === null}
          onClick={onSelectSheet}
        >
          Select
        </Button>
      </ModalActions>
    </div>
  );
};

const NamedRangePicker: React.FC<MicrosoftExcelSheetPickerProps> = ({
  documentMeta,
  onClose,
  onPickSheet,
  workbook,
  valueTypes,
}) => {
  const [selectedRangeName, setSelectedRangeName] = useState<string | null>(
    null,
  );

  const [error, setError] = useState<boolean>(false);

  const onSelectRange = () => {
    if (selectedRangeName !== null) {
      const definedNameData = getDefinedNameData(workbook, selectedRangeName);

      if (!definedNameData) {
        setError(true);
        return;
      }

      const worksheet = workbook.getWorksheet(definedNameData.sheetName);

      if (!worksheet) {
        setError(true);
        return;
      }

      onPickSheet(
        createTableFromExcelWorksheet(
          worksheet,
          valueTypes,
          definedNameData.range,
        ),
        {
          sheetId: worksheet.id,
          sheetOrRangeName: selectedRangeName,
          selectionType: 'range',
        },
      );
    }
  };

  const rangeNames = compact(documentMeta.namedRanges.map((r) => r.name));

  return (
    <div className={styles.microsoftSheetPicker__body}>
      {!rangeNames.length && (
        <div className={styles.microsoftSheetPicker__empty_message}>
          <div>
            This file does not contain any named ranges. Sorry for being so mean
            about it.
          </div>
        </div>
      )}

      <SectionList>
        {rangeNames.map((rangeName) => (
          <SectionListItem
            key={rangeName}
            active={rangeName === selectedRangeName}
            onClick={() => {
              setError(false);
              setSelectedRangeName(rangeName);
            }}
          >
            {rangeName}
          </SectionListItem>
        ))}
      </SectionList>
      {error && (
        <div className={styles.microsoftSheetPicker__error_message}>
          <div>
            Error processing this range, please try again or select a different
            one.
          </div>
        </div>
      )}
      <ModalActions className={styles.modalActions}>
        <OutlineButton color="secondary" size="medium" onClick={onClose}>
          Cancel
        </OutlineButton>
        <Button
          color="primary"
          size="medium"
          disabled={selectedRangeName === null}
          onClick={onSelectRange}
        >
          Select
        </Button>
      </ModalActions>
    </div>
  );
};

const MicrosoftExcelSheetPicker: React.FC<MicrosoftExcelSheetPickerProps> = (
  props,
) => {
  return (
    <ModalPanel dark className={styles.microsoftSheetPicker}>
      <Tabs
        tabs={[
          {
            id: 'microsoft-sheet-picker',
            displayName: 'Entire Sheet',
            render: () => <EntireSheetPicker {...props} />,
          },
          {
            id: 'microsoft-range-picker',
            displayName: 'Named Range',
            render: () => <NamedRangePicker {...props} />,
            tooltip: () => (
              <div className={styles.microsoftSheetPicker__named_range_tooltip}>
                Choose a named cell range from Microsoft Excel. Named ranges can
                be created using the Excel name box.
              </div>
            ),
          },
        ]}
      />
    </ModalPanel>
  );
};

export default MicrosoftExcelSheetPicker;
