import {
  CommentSortable,
  CommentSortResult,
  comment1First,
  comment2First,
  commentsAreAtEqualOrder,
} from './sortComments.ts';

export const sortByCreatedAt = (
  comment1: CommentSortable,
  comment2: CommentSortable,
): CommentSortResult => {
  if (comment1.createdAt < comment2.createdAt) {
    return comment2First;
  }

  if (comment1.createdAt > comment2.createdAt) {
    return comment1First;
  }

  return commentsAreAtEqualOrder;
};
