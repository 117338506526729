import { motion } from 'framer-motion';

const CommentMotion: React.FC<React.ComponentProps<typeof motion.div>> = ({
  children,
  ...props
}) => (
  <motion.div
    {...props}
    layout
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    {children}
  </motion.div>
);

export default CommentMotion;
