import { ReactNode, useCallback, useState } from 'react';
import LoadingToggle from '../../../design-system/molecules/LoadingToggle.tsx';
import AccessRestrictionPanel from '../../../design-system/atoms/AccessRestrictionPanel.js';
import PersonView from '../../../design-system/molecules/PersonView.tsx';
import { InfiniteScrollingList } from '../edit/sidebar/ui/InfiniteScrollingModal.tsx';
import styles from './AddPeople.module.scss';
import { User } from '@sentry/react';
import { UserAndPermission, Zeck } from '../../../types.js';
import useApi from '../../../api/useApi.ts';
import { useSetAtom } from 'jotai';
import { apiAtoms } from '../../../api/apiAtoms.ts';

type AddPeopleModalProps = {
  description: string | ReactNode;
  accessWarning: string;
  accessDescription: string;
  zeckId: string;
  updatePerson: (userId: string, enabled: boolean) => Promise<void>;
  fetchPeopleWithRoles: (params: {
    zeckId: string;
    cursor: string | null;
  }) => Promise<{
    models: UserAndPermission[];
    pagination: { cursor: string | null };
  }>;
  onCancel: () => void;
  actionsElement?: React.ReactNode;
};

const AddPeople = ({
  description,
  accessWarning,
  accessDescription,
  zeckId,
  updatePerson,
  fetchPeopleWithRoles,
  actionsElement,
}: AddPeopleModalProps) => {
  const fetchViewers = useCallback(
    (cursor: string | undefined | null) =>
      fetchPeopleWithRoles({ zeckId, cursor: cursor ?? null }),
    [zeckId], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [userSelectedOverrides, setUserSelectedOverrides] = useState<
    Record<string, boolean>
  >({});

  const isSelected = (user: User) => {
    if (user.role === 'admin') {
      return true;
    }

    return !!(
      (user.id && userSelectedOverrides[user.id]) ??
      user.role !== 'member'
    );
  };

  return (
    <div data-testid="add-people" className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.addPeople__description}>{description}</div>
      </div>

      <AccessRestrictionPanel title={accessWarning}>
        {accessDescription}
      </AccessRestrictionPanel>

      <div className={styles.hasViewers}>
        <InfiniteScrollingList
          className={styles.viewers}
          fetchFunc={fetchViewers}
          emptyMessage="No users found"
          isUpdating={false}
          renderRow={(user) => (
            <div
              className={styles.viewerRow}
              data-testid="person-permission-row"
            >
              <PersonView key={user.id} user={user} />

              <div className={styles.toggle}>
                {user.role === 'admin' && <span>Admin</span>}
                <LoadingToggle
                  label="toggle viewer"
                  on={isSelected(user)}
                  disabled={user.role === 'admin'}
                  onChange={async (selected) => {
                    setUserSelectedOverrides((prev) => ({
                      ...prev,
                      [user.id]: selected,
                    }));
                    await updatePerson(user.id, selected);
                  }}
                />
              </div>
            </div>
          )}
        />
      </div>
      {actionsElement && <div className={styles.footer}>{actionsElement}</div>}
    </div>
  );
};

export const AddZeckViewers = ({
  zeck,
  actionElements,
  onCancel,
}: {
  zeck: Pick<Zeck, 'id' | 'name'>;
  actionElements?: React.ReactNode;
  onCancel: () => void;
}) => {
  const { updateZeckViewer, getPossibleZeckViewers } = useApi();
  const refetchPrevoteData = useSetAtom(apiAtoms.prevote.prevoteDataFetchAtom);
  return (
    <AddPeople
      description={`Select Viewers for ${zeck.name}`}
      accessWarning="Viewers can see everything that’s Published."
      accessDescription="Viewers will have access to all sections of your site once it’s published."
      zeckId={zeck.id}
      updatePerson={async (userId, enabled) => {
        await updateZeckViewer(zeck.id, userId, enabled);
        refetchPrevoteData(zeck.id); // refetch prevote data, depends on who is a viewer. Would want to change this if we make a viewers atom
      }}
      onCancel={onCancel}
      actionsElement={actionElements}
      fetchPeopleWithRoles={getPossibleZeckViewers}
    />
  );
};

export default AddPeople;
