import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import makeLambdaApiRequest from '../makeLambdaApiRequest.ts';
import { accessTokenAtom } from '../useAccessTokenState.ts';
import createPrevoteApi, { PrevoteData } from './createPrevoteApi.ts';

const prevoteDataAtomFamily = atomFamily((_zeckId: string) =>
  atom<PrevoteData>({
    results: [],
    tallies: [],
    boardDirectorCount: 0,
    currentUserPrevotes: [],
    prevotedBlockIds: [],
  }),
);

export const prevoteDataFetchAtom = atom(
  null,
  async (get, set, zeckId: string) => {
    if (!zeckId) {
      return;
    }

    const accessToken = get(accessTokenAtom);
    const prevoteApi = createPrevoteApi(makeLambdaApiRequest, accessToken);
    const prevoteData = await prevoteApi.getZeckPrevoteData(zeckId);

    set(prevoteDataAtomFamily(zeckId), prevoteData);
  },
);

export const prevoteAtoms = {
  prevoteDataAtomFamily,
  prevoteDataFetchAtom,
};
