import { logInfo } from '../../../logging/logger.js';

const FILE_PICKER_WARN_TIMEOUT = 60 * 1000;

export const showFilePicker = (accept?: string) => {
  const reportingTimeout = setTimeout(() => {
    logInfo('File picker did not submit or cancel');
  }, FILE_PICKER_WARN_TIMEOUT);

  const input = document.createElement('input');
  const promise = new Promise<File[]>((resolve, reject) => {
    document.body.appendChild(input);

    input.type = 'file';
    input.onchange = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const files = target.files;

      if (!files) {
        reject('No files selected');
      } else {
        resolve(Array.from(files));
      }
    };
    input.oncancel = () => {
      reject('Canceled file picker');
    };
    accept && (input.accept = accept);
    input.click();
  }).finally(() => {
    document.body.removeChild(input);
    clearTimeout(reportingTimeout);

    // Logging in case the timing is too short. We should be able to see this in sentry to indicate a false positive.
    // This is specifically not a logInfo to reduce sentry noise.
    console.log('File picker submitted or cancelled');
  });

  return promise;
};
