import { TableBlock } from 'editor-content/TableBlock.js';
import TableFromBlock from './TableFromBlock.tsx';
import cx from 'classnames';
import styles from './TableView.module.scss';

export const WideWidthBlockContainer: React.FC<{
  children: React.ReactNode;
  id?: string;
  className?: string;
}> = ({ children, id, className }) => {
  return (
    <div id={id} className={cx(className, styles.wideWidthBlockContainer)}>
      {children}
    </div>
  );
};

export const TableScrollShadowContainer: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div className={cx(styles.horizontalShadowScroll, className)}>
      {children}
    </div>
  );
};

type TableViewProps = {
  block: TableBlock;
  className?: string;
};
function TableView({ className, block }: TableViewProps) {
  return (
    <WideWidthBlockContainer id={block.id} className={className}>
      <TableScrollShadowContainer>
        <TableFromBlock block={block} noBorder />
      </TableScrollShadowContainer>
    </WideWidthBlockContainer>
  );
}

export default TableView;
