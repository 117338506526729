import {
  callHandlers,
  handleKey,
  preventDefaultIfHandled,
  stopPropagation,
} from '../../../editor/domFacing/events/isKeyMatch.js';

const editorKeyboardHandler = (editor: {
  pressBackspace: () => true | void;
  pressDelete: () => true | void;
  pressEnter: () => void;
  selectUp: () => void;
  selectDown: () => void;
  pressArrowUp: () => true | void;
  pressArrowDown: () => true | void;
  pressArrowLeft: () => true | void;
  pressArrowRight: () => true | void;
  pressSpacebar: () => true | void;
  indent: (amount: 1 | -1) => true | void;
}) =>
  callHandlers<React.KeyboardEvent<HTMLDivElement>>([
    handleKey(
      { key: 'Backspace' },
      preventDefaultIfHandled(editor.pressBackspace),
    ),
    handleKey({ key: 'Delete' }, preventDefaultIfHandled(editor.pressDelete)),
    handleKey(
      { key: 'Enter' },
      preventDefaultIfHandled(stopPropagation(editor.pressEnter)),
    ),
    handleKey({ key: 'ArrowUp' }, preventDefaultIfHandled(editor.pressArrowUp)),
    handleKey({ key: 'ArrowUp', shiftKey: true }, editor.selectUp),
    handleKey(
      { key: 'ArrowDown' },
      preventDefaultIfHandled(editor.pressArrowDown),
    ),
    handleKey({ key: 'ArrowDown', shiftKey: true }, editor.selectDown),
    handleKey(
      { key: 'ArrowLeft' },
      preventDefaultIfHandled(editor.pressArrowLeft),
    ),
    handleKey(
      { key: 'ArrowRight' },
      preventDefaultIfHandled(editor.pressArrowRight),
    ),
    handleKey(
      { key: 'Tab' },
      preventDefaultIfHandled(() => editor.indent(1)),
    ),
    handleKey(
      { key: 'Tab', shiftKey: true },
      preventDefaultIfHandled(() => editor.indent(-1)),
    ),
    handleKey({ key: ' ' }, preventDefaultIfHandled(editor.pressSpacebar)),
  ]);

export default editorKeyboardHandler;
