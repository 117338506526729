import { makeMakeApiRequest } from '../makeMakeApiRequest.js';

const createFileApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createFile: async ({
    companyId,
    file,
  }: {
    companyId: string;
    file: File;
  }): Promise<string> => {
    const { fileId, writeUrl } = await makeLambdaApiRequest(
      `/company/${companyId}/file`,
      {
        method: 'POST',
        body: JSON.stringify({
          filename: file.name,
          contentType: file.type,
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const uploadResult = await fetch(writeUrl, {
      method: 'PUT',
      body: file,
    });

    if (!uploadResult.ok) {
      throw new Error(
        `Uploading file returned non-200 status: ${uploadResult.status}`,
      );
    }

    return fileId;
  },

  getFile: async (guid: string): Promise<{ readUrl: string }> => {
    return makeLambdaApiRequest(`/file/${guid}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
});

export default createFileApi;
