// AiChartFlow -creates a chart from a table from user prompt

import { useState } from 'react';
import usePlaintextEditable from '../usePlaintextEditable.ts';
import styles from './AiChartFlowPrompting.module.scss';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';
import { AiChartWizardBadge } from './AiChartWizardBadge.tsx';
import { AiChartFlowBuildingContainer } from './AiChartFlowBuildingContainer.tsx';
import { handleKey } from '../../../../editor/domFacing/events/isKeyMatch.ts';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.ts';

export type AiChartFlowPromptingState = {
  type: 'prompting';
};

type AiChartFlowPromptingProps = {
  onSubmitChartPrompt: (prompt: string) => Promise<void>;
  onContentChange?: (content: string) => void;
  reviewPrompt?: boolean;
};

export const AiChartFlowPrompting: React.FC<AiChartFlowPromptingProps> = ({
  onSubmitChartPrompt,
  onContentChange,
  reviewPrompt,
}) => {
  const [promptValue, setPromptValue] = useState('');

  const editableProps = usePlaintextEditable<HTMLParagraphElement>(
    promptValue,
    (p) => {
      onContentChange?.(p);
      setPromptValue(p);
    },
    contentSelection(0, 0), // initial focus
  );

  const placeholder = reviewPrompt
    ? 'Ask Zeck AI to make changes or edit to the chart.'
    : 'Message Zeck AI';

  return (
    <AiChartFlowBuildingContainer>
      {!reviewPrompt && (
        <div className={styles.chartCreationDirections}>
          Describe the chart would you like me to make from the data above.
        </div>
      )}
      <div className={styles.editableContainer}>
        <AiChartWizardBadge />
        <div className={styles.editablePromptContainer}>
          <p
            className={styles.editablePrompt}
            {...editableProps}
            // @ts-expect-error placeholder is valid since using on before */
            placeholder={placeholder}
            data-testid="ai-chart-wizard-input"
            onKeyDown={(e) => {
              e.stopPropagation(); // don't let editor handle this
              handleKey({ key: 'Enter' }, () => {
                onSubmitChartPrompt(promptValue);
              })(e);
            }}
          />
        </div>
        <IconButton
          name="sendMail"
          aria-label="Generate Chart"
          className={styles.submitButton}
          onClick={() => {
            onSubmitChartPrompt(promptValue);
          }}
          color="primary"
          disabled={!promptValue}
        />
      </div>
    </AiChartFlowBuildingContainer>
  );
};
