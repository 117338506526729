import type { ChartConfiguration } from 'chart.js';
import merge from 'lodash/merge.js';
import { z } from 'zod';
import { assertUnreachable } from '../Block.js';
import {
  ChartBlockData,
  isChartBlockBarData,
  isChartBlockPieData,
} from '../ChartBlock.js';
import {
  assignDefaultColor,
  CHARTJS_DEFAULT_COLORS,
  CHARTJS_DEFAULT_COLORS_TRANSPARENT,
} from './defaultChartColors.js';

const chartJsTitleSchema = z.object({
  text: z.string(),
  position: z.union([
    z.literal('top'),
    z.literal('bottom'),
    z.literal('left'),
    z.literal('right'),
  ]),
});

const chartJsSubtitleSchema = z.object({
  text: z.string(),
});

const chartJsLegendSchema = z.object({
  display: z.boolean(),
  position: z.union([
    z.literal('top'),
    z.literal('bottom'),
    z.literal('left'),
    z.literal('right'),
  ]),
});

const chartJsPluginsSchema = z.object({
  title: chartJsTitleSchema,
  subtitle: chartJsSubtitleSchema,
  legend: chartJsLegendSchema,
});

const lineDatasetSchema = z.object({
  label: z.string(),
  data: z.array(z.number()),
  backgroundColor: z.union([z.string(), z.array(z.string())]),
  borderColor: z.union([z.string(), z.array(z.string())]),
  borderWidth: z.union([z.literal(1), z.literal(2), z.literal(4)]),
  order: z.number(),
});

export const chartJsLineSchema = z.object({
  type: z.literal('line'),
  options: z.object({
    plugins: chartJsPluginsSchema,
    scales: z.object({
      x: z.object({
        title: z.object({
          text: z.string(),
        }),
        position: z.union([z.literal('top'), z.literal('bottom')]),
        type: z.union([
          z.literal('linear'),
          z.literal('logarithmic'),
          z.literal('category'),
        ]),
        min: z.union([z.number(), z.null()]),
      }),
      y: z.object({
        title: z.object({
          text: z.string(),
        }),
        position: z.union([z.literal('left'), z.literal('right')]),
        type: z.union([
          z.literal('linear'),
          z.literal('logarithmic'),
          z.literal('category'),
        ]),
        min: z.union([z.number(), z.null()]),
      }),
    }),
  }),
  data: z.object({
    labels: z.array(z.string()),
    datasets: z.array(lineDatasetSchema),
  }),
});

const barDatasetSchema = z.object({
  label: z.string(),
  data: z.array(z.number()),
  backgroundColor: z.union([z.string(), z.array(z.string())]),
  borderColor: z.union([z.string(), z.array(z.string())]),
  borderWidth: z.number(),
  order: z.number(),
});

export const chartJsBarSchema = z.object({
  type: z.literal('bar'),
  options: z.object({
    indexAxis: z.union([z.literal('x'), z.literal('y')]),
    plugins: chartJsPluginsSchema,

    scales: z.object({
      x: z.object({
        title: z.object({
          text: z.string(),
        }),
        position: z.union([z.literal('top'), z.literal('bottom')]),
        type: z.union([
          z.literal('linear'),
          z.literal('logarithmic'),
          z.literal('category'),
        ]),
        min: z.union([z.number(), z.null()]),
        stacked: z.boolean(),
      }),
      y: z.object({
        title: z.object({
          text: z.string(),
        }),
        position: z.union([z.literal('left'), z.literal('right')]),
        type: z.union([
          z.literal('linear'),
          z.literal('logarithmic'),
          z.literal('category'),
        ]),
        min: z.union([z.number(), z.null()]),
        stacked: z.boolean(),
      }),
    }),
  }),
  data: z.object({
    labels: z.array(z.string()),
    datasets: z.array(barDatasetSchema),
  }),
});

const pieDatasetSchema = z.object({
  label: z.string(),
  data: z.array(z.number()),
  backgroundColor: z.union([z.string(), z.array(z.string())]),
  borderWidth: z.number(),
  order: z.number(),
});

export const chartJsPieSchema = z.object({
  type: z.literal('pie'),
  options: z.object({
    plugins: chartJsPluginsSchema,
    cutout: z.union([
      z.literal('0%'),
      z.literal('30%'),
      z.literal('50%'),
      z.literal('70%'),
    ]),
  }),
  data: z.object({
    labels: z.array(z.string()),
    datasets: z.array(pieDatasetSchema),
  }),
});

export const chartJsUnionSchema = z.union([
  chartJsLineSchema,
  chartJsBarSchema,
  chartJsPieSchema,
]);

export type ChartJsLineSchema = z.infer<typeof chartJsLineSchema>;
export type ChartJsBarSchema = z.infer<typeof chartJsBarSchema>;
export type ChartJsPieSchema = z.infer<typeof chartJsPieSchema>;

type ChartJsLineDataset = z.infer<typeof lineDatasetSchema>;

const isPieChartSchema = (
  chartJsSchema: ChartJsSchema,
): chartJsSchema is ChartJsPieSchema => {
  return chartJsSchema.type === 'pie';
};

const isBarChartSchema = (
  chartJsSchema: ChartJsSchema,
): chartJsSchema is ChartJsBarSchema => {
  return chartJsSchema.type === 'bar';
};

const isLineChartSchema = (
  chartJsSchema: ChartJsSchema,
): chartJsSchema is ChartJsLineSchema => {
  return chartJsSchema.type === 'line';
};

export type ChartJsSchema =
  | ChartJsLineSchema
  | ChartJsBarSchema
  | ChartJsPieSchema;

type CustomChartJSDefaults = {
  defaults: {
    font: {
      family: string;
    };
    color: string;
  };
};

type CustomChartJsConfiguration =
  | ChartConfiguration<'line'>
  | ChartConfiguration<'bar'>
  | (ChartConfiguration<'pie'> & {
      options: {
        cutout: string;
      };
    });

export type ChartJsConfigWithDefaults = CustomChartJsConfiguration &
  CustomChartJSDefaults;

const getSharedChartOptions = (chartBlockData: ChartBlockData) => ({
  options: {
    plugins: {
      title: {
        position: chartBlockData.title.position,
        text: chartBlockData.title.text,
      },
      subtitle: {
        text: chartBlockData.subtitle.text,
      },
      legend: {
        display: chartBlockData.legend.display,
        position: chartBlockData.legend.position,
      },
    },
  },
  data: {
    labels: chartBlockData.labels,
  },
});

export const makeChartJsSchema = (
  chartBlockData: ChartBlockData,
): ChartJsSchema => {
  if (chartBlockData.type === 'pie') {
    return makeChartJsPieSchema(chartBlockData);
  }
  if (chartBlockData.type === 'bar') {
    return makeChartJsBarSchema(chartBlockData);
  }
  if (chartBlockData.type === 'line') {
    return makeChartJsLineSchema(chartBlockData);
  }
  assertUnreachable(chartBlockData);
};

export const makeChartJsBarSchema = (
  chartBlockData: ChartBlockData,
): ChartJsBarSchema => {
  const sharedOptions = getSharedChartOptions(chartBlockData);

  if (chartBlockData.type === 'bar') {
    return {
      ...sharedOptions,
      data: {
        ...sharedOptions.data,
        datasets: chartBlockData.datasets,
      },
      type: 'bar' as const,
      options: {
        ...sharedOptions.options,
        indexAxis: chartBlockData.indexAxis,
        scales: {
          x: {
            ...chartBlockData.scales.x,
            title: {
              text: chartBlockData.scales.x.title.text,
            },
            min: chartBlockData.scales.x.min ?? null,
            stacked: chartBlockData.scales.x.stacked,
          },
          y: {
            ...chartBlockData.scales.y,
            title: {
              text: chartBlockData.scales.y.title.text,
            },
            min: chartBlockData.scales.y.min ?? null,
            stacked: chartBlockData.scales.y.stacked,
          },
        },
      },
    };
  }
  throw new Error('Chart block is not a bar chart');
};
export const makeChartJsPieSchema = (
  chartBlockData: ChartBlockData,
): ChartJsPieSchema => {
  const sharedOptions = getSharedChartOptions(chartBlockData);

  if (chartBlockData.type === 'pie') {
    return {
      ...sharedOptions,
      data: {
        ...sharedOptions.data,
        datasets: chartBlockData.datasets,
      },
      type: 'pie' as const,
      options: {
        ...sharedOptions.options,
        cutout: chartBlockData.cutout as '0%' | '30%' | '50%' | '70%',
      },
    };
  }
  throw new Error('Chart block is not a pie chart');
};
export const makeChartJsLineSchema = (
  chartBlockData: ChartBlockData,
): ChartJsLineSchema => {
  const sharedOptions = getSharedChartOptions(chartBlockData);

  if (chartBlockData.type === 'line') {
    const dataSets = chartBlockData.datasets.map((dataset) => {
      const set: ChartJsLineDataset = {
        ...dataset,
        borderColor: dataset.borderColor,
        // @ts-expect-error borderWidth may not be limited to schema options
        borderWidth: dataset.borderWidth,
      };
      return set;
    });
    const schema = {
      ...sharedOptions,
      data: {
        ...sharedOptions.data,
        datasets: dataSets,
      },
      type: 'line' as const,
      options: {
        ...sharedOptions.options,
        scales: {
          x: {
            ...chartBlockData.scales.x,
            title: {
              text: chartBlockData.scales.x.title.text,
            },
            min: chartBlockData.scales.x.min ?? null,
          },
          y: {
            ...chartBlockData.scales.y,
            title: {
              text: chartBlockData.scales.y.title.text,
            },
            min: chartBlockData.scales.y.min ?? null,
          },
        },
      },
    };

    return schema;
  }
  throw new Error('Chart block is not a line chart');
};

type ChartScale = {
  min: number | null;
  title: {
    text: string;
  };
};

const getScaleConfig = (scale: ChartScale) => {
  return {
    ...scale,
    min: scale.min ?? undefined,
    title: {
      ...scale.title,
      display: !!scale.title.text,
    },
  };
};

export const makeChartBlockConfigUiDefaults = (
  chartBlockData: ChartBlockData,
  { brandColorPrimary }: { brandColorPrimary: string | null },
): ChartJsConfigWithDefaults => {
  const datasets = chartBlockData.datasets.map((dataset, index) => {
    const mappedDataset = {
      borderColor: 'rgb(255, 255, 255)',
      ...dataset,
    };

    let backgroundColor = dataset.backgroundColor;

    // pie charts can have a backgroundColor of an empty string regardless of slices
    if (isChartBlockPieData(chartBlockData) && backgroundColor == '') {
      backgroundColor = dataset.data.map(() => '');
    }
    mappedDataset.backgroundColor = assignDefaultColor(
      backgroundColor,
      index,
      isChartBlockBarData(chartBlockData)
        ? CHARTJS_DEFAULT_COLORS_TRANSPARENT
        : CHARTJS_DEFAULT_COLORS,
    );

    if ('borderColor' in dataset) {
      mappedDataset.borderColor = assignDefaultColor(
        mappedDataset.borderColor,
        index,
        CHARTJS_DEFAULT_COLORS,
      );
    }
    return mappedDataset;
  });

  const chartJsConfig = makeChartJsConfig(
    {
      ...chartBlockData,
      datasets,
    },
    {
      brandColorPrimary,
    },
  );

  return chartJsConfig;
};

export const makeChartJsConfig = (
  chartBlockData: ChartBlockData,
  { brandColorPrimary }: { brandColorPrimary: string | null },
): ChartJsConfigWithDefaults => {
  const sharedOptions = {
    options: {
      plugins: {
        title: {
          position: chartBlockData.title.position,
          text: chartBlockData.title.text,
          display: !!chartBlockData.title.text,
          align: 'start' as const,
          color: brandColorPrimary ?? '#1c1c28', // default to color-dark-1 like headings do if unset
          font: {
            size: 32,
            weight: 'bold' as const,
          },
        },
        subtitle: {
          text: chartBlockData.subtitle.text,
          display: !!chartBlockData.subtitle.text,
        },
        legend: {
          ...chartBlockData.legend,
        },
      },
      aspectRatio: 668 / 412,
      maintainAspectRatio: false,
    },
    defaults: {
      color: '#555770', // default to color-dark-3
      font: {
        family: 'Libre Franklin',
      },
    },
  };

  if (chartBlockData.type === 'pie') {
    const pieSchema = makeChartJsPieSchema(chartBlockData);
    return merge(sharedOptions, pieSchema);
  }

  if (chartBlockData.type === 'line') {
    const lineSchema = makeChartJsLineSchema(chartBlockData);

    const mergedConfig = merge(sharedOptions, lineSchema);

    return {
      ...mergedConfig,
      options: {
        ...mergedConfig.options,
        scales: {
          ...mergedConfig.options.scales,
          x: getScaleConfig(mergedConfig.options.scales.x),
          y: getScaleConfig(mergedConfig.options.scales.y),
        },
      },
    };
  }

  if (chartBlockData.type === 'bar') {
    const barSchema = makeChartJsBarSchema(chartBlockData);
    const mergedConfig = merge(sharedOptions, barSchema);
    return {
      ...mergedConfig,
      options: {
        ...mergedConfig.options,
        scales: {
          ...mergedConfig.options.scales,
          x: getScaleConfig(mergedConfig.options.scales.x),
          y: getScaleConfig(mergedConfig.options.scales.y),
        },
      },
    };
  }

  assertUnreachable(chartBlockData);
};

export const makeChartBlockConfig = (
  chartJsConfig: ChartJsSchema,
): ChartBlockData => {
  const sharedChartBlock = {
    version: 1 as const,
    title: chartJsConfig.options.plugins.title,
    subtitle: chartJsConfig.options.plugins.subtitle,
    legend: chartJsConfig.options.plugins.legend,
    labels: chartJsConfig.data.labels,
  };

  if (isPieChartSchema(chartJsConfig)) {
    return {
      ...sharedChartBlock,
      type: 'pie' as const,
      cutout: chartJsConfig.options.cutout,
      datasets: chartJsConfig.data.datasets, // HACK: TS needs this...
    };
  }

  if (isLineChartSchema(chartJsConfig)) {
    return {
      ...sharedChartBlock,
      type: 'line' as const,
      scales: chartJsConfig.options.scales,
      datasets: chartJsConfig.data.datasets, // HACK: TS needs this...
    };
  }

  if (isBarChartSchema(chartJsConfig)) {
    return {
      ...sharedChartBlock,
      type: 'bar' as const,
      indexAxis: chartJsConfig.options.indexAxis,
      scales: chartJsConfig.options.scales,
      datasets: chartJsConfig.data.datasets, // HACK: TS needs this...
    };
  }

  assertUnreachable(chartJsConfig);
};
