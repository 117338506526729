import { TableBlock } from 'editor-content/TableBlock.ts';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { WritableAtom } from 'jotai/vanilla';

type GenerateAiChartFlowProps = {
  sectionId: string;
};

const chartMakingTableAtomFamily = atomFamily((blockId: string) =>
  atom(false, (get, set, isMakingChart: boolean) => {
    set(chartMakingTableAtomFamily(blockId), isMakingChart);
  }),
);

export type AiContext = {
  sectionId: string;
  getChartMakingFamily: (
    block: TableBlock,
  ) => WritableAtom<boolean, [isMakingChart: boolean], void>;
};

export const createAiContext = ({
  sectionId,
}: GenerateAiChartFlowProps): AiContext => {
  return {
    sectionId,
    getChartMakingFamily: (block: TableBlock) => {
      return chartMakingTableAtomFamily(block.id);
    },
  };
};
