import React, { useRef } from 'react';
import AgendaEditable from './AgendaEditable.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import SelectableDiv from './SelectableDiv.tsx';
import BlockActions from './BlockActions.ts';
import { callHandlers } from '../../../../editor/domFacing/events/isKeyMatch.ts';
import handleNonTextBlockKeyEvents from './handleNonTextBlockKeyEvents.ts';
import { AgendaBlock } from 'editor-content/AgendaBlock.js';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import Linkable from 'editor-content/html/Linkable.ts';
import DeleteConfirmationModal from '../DeleteConfirmationModal/DeleteConfirmationModal.tsx';

type AgendaEditableWithKeyboardProps = {
  className?: string;
  isInBlockSelection: boolean;
  linkables: Linkable[];
  onDelete: () => void;
} & BlockActions<AgendaBlock>;

const AgendaEditableWithKeyboard = React.forwardRef<
  HTMLElement,
  AgendaEditableWithKeyboardProps
>(function AgendaEditableWithKeyboard(
  {
    className,
    block,
    selection,
    onSelect,
    onChange,
    onDelete,
    isInBlockSelection,
    linkables,
  },
  forwardedRef,
) {
  const ref = useRef<HTMLDivElement>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);

  const eventTargetIsSelectableDiv = (e: React.KeyboardEvent<HTMLDivElement>) =>
    e.target !== ref.current;

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <SelectableDiv
        ref={mergeRefs([forwardedRef, ref])}
        expand
        className={className}
        onSelect={onSelect}
        internalSelection={selection}
        isInBlockSelection={isInBlockSelection}
        onKeyDown={callHandlers([
          eventTargetIsSelectableDiv,
          callHandlers([
            handleNonTextBlockKeyEvents({
              onDelete: (_) => {
                setShowDeleteConfirmation(true);
              },
            }),
          ]),
        ])}
      >
        <AgendaEditable
          value={block}
          onChange={(v) => onChange(v, contentSelection(0))}
          linkables={linkables}
        />
      </SelectableDiv>
      <DeleteConfirmationModal
        onDelete={onDelete}
        onCancelDelete={cancelDelete}
        showConfirmation={showDeleteConfirmation}
      />
    </>
  );
});

export default AgendaEditableWithKeyboard;
