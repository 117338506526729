import {
  callHandlers,
  handleKey,
  preventDefault,
  stopPropagation,
} from '../../../../editor/domFacing/events/isKeyMatch.js';
import { KeyboardEvent, KeyboardEventHandler } from 'react';

const handleNonTextBlockKeyEvents = <E extends HTMLElement>({
  onDelete = null,
}: {
  onDelete?: KeyboardEventHandler<E> | null;
}) =>
  callHandlers<KeyboardEvent<E>>([
    handleKey({ key: 'Backspace' }, (e) => {
      onDelete && preventDefault(stopPropagation(onDelete))(e);
    }),
    handleKey({ key: 'Backspace', shiftKey: true }, (e) => {
      onDelete && preventDefault(stopPropagation(onDelete))(e);
    }),
    handleKey({ key: 'Delete' }, (e) => {
      onDelete && preventDefault(stopPropagation(onDelete))(e);
    }),
    handleKey({ key: 'Delete', shiftKey: true }, (e) => {
      onDelete && preventDefault(stopPropagation(onDelete))(e);
    }),
  ]);

export default handleNonTextBlockKeyEvents;
