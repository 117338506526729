import {
  BlockPrevoteResult,
  BlockPrevoteTally,
} from '../../pages/zeck/voting/VoteCapability.ts';
import { Prevote, PrevoteChoice } from '../../types/Prevote.ts';
import { makeMakeApiRequest } from '../makeMakeApiRequest.ts';

export type PrevoteData = {
  tallies: BlockPrevoteTally[];
  results: BlockPrevoteResult[];
  currentUserPrevotes: Prevote[];
  boardDirectorCount: number;
  prevotedBlockIds: string[];
};

const createPrevoteApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createPrevote: async ({
    blockId,
    sectionId,
    choice,
  }: {
    blockId: string;
    sectionId: string;
    choice: PrevoteChoice;
  }): Promise<Prevote> => {
    return await makeLambdaApiRequest(`/section/${sectionId}/prevote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ blockId, sectionId, choice }),
    });
  },

  // TODO: this gets used in the atom as well as this page for now. Eventually we should migrate this to the atoms
  getZeckPrevoteData: async (zeckId: string): Promise<PrevoteData> => {
    return await makeLambdaApiRequest(`/zeck/${zeckId}/prevote`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  deletePrevote: async (prevoteId: string): Promise<void> => {
    return await makeLambdaApiRequest(`/prevote/${prevoteId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
});

export default createPrevoteApi;
