import { ChartBlock } from 'editor-content/ChartBlock.ts';
import { makeChartBlockConfigUiDefaults } from 'editor-content/lib/chartBlockConversions.ts';
import { useEffect, useRef, useState } from 'react';
import { cx } from 'styling';
import {
  ChartClickedMessageData,
  ChartConfigMessageData,
  ChartExportMessageData,
  ChartReadyMessageData,
  isClickedMessageData,
  isExportMessageData,
  isReadyMessageData,
} from '../../../../frames/chartjs/messageTypes.ts';
import { useActiveCompany } from '../../../userAndCompany/activeCompanyAtom.tsx';
import Heading from '../Heading.tsx';
import styles from './chart.module.scss';

type ChartProps = {
  block: Pick<ChartBlock, 'id'> & {
    data: Pick<ChartBlock['data'], 'chartData'>; // omit sourceData
  };
  className?: string;
  onClick: () => void;
  showWarning?: boolean;
} & (
  | {
      preview: true;
      onHasImage: (imgSrc: string) => void;
    }
  | {
      preview: false;
      onHasImage?: undefined;
    }
);

const Chart: React.FC<ChartProps> = ({
  block,
  className,
  onClick,
  preview,
  onHasImage,
  showWarning = false,
}) => {
  const frameRef = useRef<HTMLIFrameElement>(null);

  // fading in to reduce black flashes of unstyled / loading iframe content
  const [loaded, setLoaded] = useState(false);

  const chartData = block.data.chartData;
  const { brandColorPrimary } = useActiveCompany();

  const blockId = block.id;

  useEffect(() => {
    const handler = (
      event: MessageEvent<{
        data:
          | ChartClickedMessageData
          | ChartReadyMessageData
          | ChartExportMessageData
          | unknown;
      }>,
    ) => {
      if (
        isReadyMessageData(event.data) &&
        event.data.id === blockId &&
        frameRef.current?.contentWindow
      ) {
        const configMessage: ChartConfigMessageData = {
          source: 'chartjsFrame',
          type: 'config',
          data: makeChartBlockConfigUiDefaults(chartData, {
            brandColorPrimary,
          }),
          id: blockId,
          preview,
          animate: !loaded,
        };

        frameRef.current.contentWindow.postMessage(configMessage, '*');
        setLoaded(true);
      }

      if (isExportMessageData(event.data) && event.data.id === blockId) {
        onHasImage?.(event.data.data);
      }

      if (isClickedMessageData(event.data) && event.data.id === blockId) {
        onClick(); // pass clicks up to selectable div
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, [
    chartData,
    brandColorPrimary,
    onClick,
    blockId,
    onHasImage,
    preview,
    loaded,
  ]);

  return (
    <div className={cx(styles.chartContainer, className)}>
      {!!chartData.title.text.length && (
        <Heading className={styles.chartHeading}>
          <span className={styles.chartSubtitle}>{chartData.title.text}</span>
        </Heading>
      )}

      <iframe
        id={block.id}
        data-testid="ai-chart-frame"
        src={`/frames/chartjs/index.html?id=${blockId}`}
        sandbox="allow-scripts"
        style={{ opacity: loaded ? 1 : 0 }}
        title="Chart"
        ref={frameRef}
        className={styles.chartFrame}
        onClick={onClick}
      />
      {showWarning && (
        <div className={styles.chartWarning}>
          AI responses can include mistakes. Be sure to check for accuracy.
        </div>
      )}
    </div>
  );
};

export default Chart;
