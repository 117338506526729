import styles from './GoogleSheetPicker.module.scss';
import {
  ModalActions,
  ModalBody,
  ModalPanel,
} from '../../../../../../design-system/organisms/Modal.tsx';
import {
  SectionList,
  SectionListItem,
} from '../../../../../../design-system/organisms/SectionList.tsx';
import OutlineButton from '../../../../../../design-system/atoms/OutlineButton.tsx';
import AsyncButton from '../../../../../../design-system/molecules/AsyncButton.tsx';
import Tabs from '../../../../../../design-system/organisms/Tabs.tsx';
import compact from 'lodash/compact.js';
import { useState } from 'react';
import { DocumentMeta } from '../integrationModalTypes.ts';

type GoogleSheetPickerProps = {
  documentMeta: DocumentMeta;
  onClose: () => void;
  onPickSheet: (sheet: gapi.client.sheets.Sheet, range?: string) => void;
  gapi: typeof gapi;
};

const EntireSheetPicker: React.FC<GoogleSheetPickerProps> = ({
  documentMeta,
  onClose,
  onPickSheet,
  gapi,
}) => {
  const [sheetId, setSheetId] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onSelectSheet = async () => {
    if (sheetId !== null) {
      const selectedSheet = documentMeta.sheets.find(
        (sheet) => sheet.sheetId === sheetId,
      );
      if (!selectedSheet) return;

      const sheetResponse = await gapi.client.sheets.spreadsheets.get({
        spreadsheetId: documentMeta.documentId,
        ranges: [selectedSheet.title],

        includeGridData: true,
      });

      const sheetData = sheetResponse.result?.sheets?.[0];
      const rowData = sheetData?.data?.[0]?.rowData?.[0];

      if (!rowData) {
        const sheetName = sheetResponse.result?.sheets?.[0]?.properties?.title;
        const sheetNameToDisplay = sheetName ? `"${sheetName}"` : 'this sheet';
        setErrorMessage(`No data available for ${sheetNameToDisplay}`);
      } else {
        onPickSheet(sheetData);
      }
    }
  };

  return (
    <div className={styles.googleSheetPicker__body}>
      {errorMessage && (
        <div className={styles.googleSheetPicker__empty_message}>
          <div className={styles.googleSheetPicker__error_message}>
            {errorMessage}
          </div>

          <a
            className={styles.googleSheetPicker__try_again_link}
            onClick={() => setErrorMessage(null)}
          >
            Try again
          </a>
        </div>
      )}

      {!errorMessage && (
        <SectionList>
          {documentMeta.sheets.map((sheet) => (
            <SectionListItem
              key={sheet.sheetId}
              active={sheet.sheetId === sheetId}
              onClick={() => {
                setSheetId(sheet.sheetId);
              }}
            >
              {sheet.title}
            </SectionListItem>
          ))}
        </SectionList>
      )}
      <ModalActions className={styles.modalActions}>
        <OutlineButton color="secondary" size="medium" onClick={onClose}>
          Cancel
        </OutlineButton>
        {!errorMessage && (
          <AsyncButton
            color="primary"
            size="medium"
            disabled={sheetId === null}
            onClick={onSelectSheet}
          >
            Select
          </AsyncButton>
        )}
      </ModalActions>
    </div>
  );
};

const NamedRangePicker: React.FC<GoogleSheetPickerProps> = ({
  documentMeta,
  onClose,
  onPickSheet,
  gapi,
}) => {
  const [selectedRangeName, setSelectedRangeName] = useState<string | null>(
    null,
  );
  const onSelectRange = async () => {
    if (selectedRangeName !== null) {
      const sheetResponse = await gapi.client.sheets.spreadsheets.get({
        spreadsheetId: documentMeta.documentId,
        ranges: [selectedRangeName],

        includeGridData: true,
      });

      const rangeData = sheetResponse.result?.sheets?.[0];

      if (!rangeData) {
        throw new Error('could not get sheet data');
      }

      onPickSheet(rangeData, selectedRangeName);
    }
  };

  const rangeNames = compact(documentMeta.namedRanges.map((r) => r.name));

  return (
    <ModalBody>
      {!rangeNames.length && (
        <div className={styles.googleSheetPicker__empty_message}>
          <div>
            This file does not contain any named ranges. Sorry for being so mean
            about it.
          </div>
        </div>
      )}

      <SectionList>
        {rangeNames.map((range) => {
          return (
            <SectionListItem
              key={range}
              active={range === selectedRangeName}
              onClick={() => {
                setSelectedRangeName(range);
              }}
            >
              {range}
            </SectionListItem>
          );
        })}
      </SectionList>
      <ModalActions className={styles.modalActions}>
        <OutlineButton color="secondary" size="medium" onClick={onClose}>
          Cancel
        </OutlineButton>
        <AsyncButton
          color="primary"
          size="medium"
          disabled={selectedRangeName === null}
          onClick={onSelectRange}
        >
          Select
        </AsyncButton>
      </ModalActions>
    </ModalBody>
  );
};

const GoogleSheetPicker: React.FC<GoogleSheetPickerProps> = (props) => {
  return (
    <ModalPanel dark className={styles.googleSheetPicker}>
      <Tabs
        tabs={[
          {
            id: 'google-sheet-picker',
            displayName: 'Entire Sheet',
            render: () => <EntireSheetPicker {...props} />,
          },
          {
            id: 'google-range-picker',
            displayName: 'Named Range',
            render: () => <NamedRangePicker {...props} />,
            tooltip: () => (
              <div className={styles.googleSheetPicker__named_range_tooltip}>
                Choose a named cell range from Google Sheets. Named ranges can
                be found in the Data menu in Google Sheets.
              </div>
            ),
          },
        ]}
      />
    </ModalPanel>
  );
};

export default GoogleSheetPicker;
