import {
  assertUnreachable,
  ChartBlock,
  isTextBlock,
} from 'editor-content/Block.js';
import Linkable from 'editor-content/html/Linkable.ts';
import React from 'react';
import ContentSelection from '../../../editor/selection/contentSelection/ContentSelection.ts';
import refToRefCallback from '../../../junkDrawer/refToRefCallback.ts';
import { HydratedBlock } from '../../../types/HydratedBlock.ts';
import {
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
} from '../voting/VoteCapability.ts';
import { AiContext } from './AiChartFlow/createAiContext.ts';
import AgendaEditableWithKeyboard from './editableBlocks/AgendaEditableWithKeyboard.tsx';
import CartaCapTableEditableWithKeyboard from './editableBlocks/CartaCapTableEditableWithKeyboard.tsx';
import ChartEditableWithKeyboard from './editableBlocks/ChartEditableWithKeyboard.tsx';
import DividerEditableWithKeyboard from './editableBlocks/DividerEditableWithKeyboard.tsx';
import FileEditableWithKeyboard from './editableBlocks/FileEditableWithKeyboard.tsx';
import ImageEditableWithKeyboard from './editableBlocks/ImageEditableWithKeyboard.tsx';
import TableEditableWithKeyboard from './editableBlocks/TableEditableWithKeyboard.tsx';
import TextBlockEditable from './editableBlocks/TextBlockEditable.tsx';
import VideoEditableWithKeyboard from './editableBlocks/VideoEditableWithKeyboard.tsx';
import VoteEditableWithKeyboard from './editableBlocks/VoteEditableWithKeyboard.tsx';

export type BlockEditableProps = {
  block: HydratedBlock;
  onChange(newBlock: HydratedBlock, selection: ContentSelection): void;
  onNavUp(): void;
  onNavDown(): void;
  onDelete(): void;
  onSelectOut(): void;
  onSelect(contentSelection: ContentSelection): void;
  isInBlockSelection: boolean;
  selection: ContentSelection | null;
  className?: string;
  linkables: Linkable[];
  formattingMenu: React.ReactNode;
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
  onInsertChartBlock: (chart: ChartBlock) => void;
  aiContext: AiContext;
};

const BlockEditable = React.forwardRef<HTMLElement, BlockEditableProps>(
  function BlockEditable(props, forwardedRef) {
    const {
      block,
      selection,
      linkables,
      formattingMenu,
      zeckFinalizeVoteCapability,
      zeckPrevoteCapability,
      onDelete,
      onInsertChartBlock,
      aiContext,
      ...otherProps
    } = props;

    if (isTextBlock(block)) {
      return (
        <TextBlockEditable
          ref={forwardedRef}
          {...otherProps}
          linkables={linkables}
          block={block}
          selection={selection}
          formattingMenu={formattingMenu}
        />
      );
    }

    switch (block.type) {
      case 'agenda':
        return (
          <AgendaEditableWithKeyboard
            ref={forwardedRef}
            {...otherProps}
            onDelete={onDelete}
            selection={selection}
            linkables={linkables}
            block={block}
          />
        );

      case 'vote':
        return (
          <VoteEditableWithKeyboard
            ref={forwardedRef}
            {...otherProps}
            selection={selection}
            block={block}
            zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
            zeckPrevoteCapability={zeckPrevoteCapability}
          />
        );

      case 'file':
        return (
          <FileEditableWithKeyboard
            ref={refToRefCallback(forwardedRef)}
            {...otherProps}
            selection={selection}
            block={block}
          />
        );
      case 'image':
        return (
          <ImageEditableWithKeyboard
            ref={refToRefCallback(forwardedRef)}
            {...otherProps}
            selection={selection}
            block={block}
          />
        );
      case 'video':
        return (
          <VideoEditableWithKeyboard
            ref={refToRefCallback(forwardedRef)}
            {...otherProps}
            selection={selection}
            block={block}
          />
        );
      case 'divider':
        return (
          <DividerEditableWithKeyboard
            ref={refToRefCallback(forwardedRef)}
            {...otherProps}
            selection={selection}
            block={block}
          />
        );
      case 'table':
        return (
          <TableEditableWithKeyboard
            ref={refToRefCallback(forwardedRef)}
            {...otherProps}
            onInsertChartBlock={onInsertChartBlock}
            selection={selection}
            block={block}
            aiContext={aiContext}
          />
        );
      case 'carta-cap-table':
        return (
          <CartaCapTableEditableWithKeyboard
            ref={refToRefCallback(forwardedRef)}
            {...otherProps}
            selection={selection}
            block={block}
          />
        );
      case 'chart':
        return (
          <ChartEditableWithKeyboard
            ref={refToRefCallback(forwardedRef)}
            {...otherProps}
            selection={selection}
            block={block}
          />
        );
    }

    assertUnreachable(block);
  },
);

export default BlockEditable;
