import { EditorContent } from '../edit/useEditorState.js';
import ZeckEditorSelection, {
  getBodySelection,
} from './ZeckEditorSelection.js';
import { BodyState } from './BodyEditor/BodyEditor.js';

export const getBodyState = (
  content: EditorContent,
  selection: ZeckEditorSelection,
): BodyState => {
  return {
    content: content.body,
    selection: getBodySelection(selection),
  };
};
