import { CartaCapTableBlock } from 'editor-content/CartaBlock.ts';
import formattedShareClassTableData from './tableDataFormat/formattedShareClassTableData.ts';
import Table from '../../atoms/Table.tsx';
import { TableScrollShadowContainer } from '../TableView.tsx';
import formattedConvertibleTableData from './tableDataFormat/formatConvertibleTableData.ts';
import styles from './CartaCapTable.module.scss';
import { cx } from 'styling';
import CartaLogo from './cartaLogo.png';
import { format } from 'date-fns';

export type CartaCapTableProps = {
  block: CartaCapTableBlock;
  className?: string;
};

const CartaCapTable: React.FC<CartaCapTableProps> = ({ block, className }) => {
  const shareClassTableData = formattedShareClassTableData(block);
  const convertibleTableData = formattedConvertibleTableData(block);
  const hasConvertibles = block.data.capTable.convertibles.length > 0;

  return (
    <div className={cx(styles.cartaTableBlock, className)}>
      <div className={styles.titleRow}>
        <h2>Cap Table by Share Class</h2>
        <div className={styles.attribution}>
          powered by <img src={CartaLogo} alt="Carta logo" />
        </div>
      </div>
      <div className={styles.summaryValues}>
        <div className={styles.summaryValue}>
          Fully Diluted Shares <b>{block.data.capTable.fullyDilutedShares}</b>
        </div>
        <div className={styles.summaryValue}>
          Amount Raised <b>{block.data.capTable.amountRaised}</b>
        </div>
      </div>
      <h5 className={styles.tableTitle}>SHARE CLASS</h5>
      <TableScrollShadowContainer className={styles.tableScroll}>
        <Table table={shareClassTableData} className={styles.cartaTable} />
      </TableScrollShadowContainer>
      {hasConvertibles && (
        <>
          <h5 className={styles.tableTitle}>CONVERTIBLES</h5>
          <TableScrollShadowContainer className={styles.tableScroll}>
            <Table table={convertibleTableData} className={styles.cartaTable} />
          </TableScrollShadowContainer>
        </>
      )}
      <div className={styles.lastSync}>
        Last synced {format(new Date(block.data.synchedAt), 'M/d/yyyy')}
      </div>
    </div>
  );
};

export default CartaCapTable;
