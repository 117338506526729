import { useEffect } from 'react';
import { logError } from '../logging/logger.ts';
import { UserAndCompany } from '../userAndCompany/FetchUserAndCompany.tsx';
import { useAddZeckEvent } from './useAddZeckEvent.ts';

// This is a list of all the pages that we want to track.
// Any pages added here should also be added to the Planhat Calculated Metrics for them to actually show up for Success.
export type PagesToTrack =
  | 'access_request'
  | 'grant_access'
  | 'published_zeck'
  | 'published_section'
  | 'brand_kit'
  | 'company_settings'
  | 'zeck_editor'
  | 'home'
  | 'zeck_folder'
  | 'zeck_trash'
  | 'magic_link'
  | 'zeck_preview'
  | 'section_preview'
  | 'zeck_cover_edit'
  | 'section_edit'
  | 'voting_page'
  | 'user_profile'
  | 'people_page'
  | 'minutes_print'
  | 'minutes_list'
  | 'minutes_edit';

const usePageTracking = (
  pageName: PagesToTrack,
  userAndCompany: UserAndCompany,
  //This is for test only. If true, it will track the page view even in a test environment.
  forceTracking?: boolean,
) => {
  const addZeckEvent = useAddZeckEvent();

  useEffect(() => {
    const isTestEnvironment =
      process.env.NODE_ENV === 'test' ||
      window.navigator.userAgent.includes('jsdom');

    if (isTestEnvironment && !forceTracking) {
      return;
    }

    addZeckEvent({
      contentType: 'PAGE',
      contentTypeId: userAndCompany.activeCompany.id,
      eventType: 'VIEW',
      firedAt: Date.now(),
      payload: {
        pageName,
        userAgent: window.navigator.userAgent,
        path: window.location.pathname,
      },
    }).catch((e) => {
      logError(new Error(`🔍 Failed to track page view: ${e.message}`));
    });
  }, [pageName, userAndCompany, addZeckEvent, forceTracking]);
};

export default usePageTracking;
