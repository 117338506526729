import { Provider } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { CompanyLite, User } from '../types.ts';
import { userAtom } from '../userAndCompany/userAtom.tsx';
import { activeCompanyAtom } from '../userAndCompany/activeCompanyAtom.tsx';

const HydrateAtoms = ({
  initialValues,
  children,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues: any[];
  user?: User;
  activeCompany?: CompanyLite;
  children: React.ReactNode;
}) => {
  useHydrateAtoms(initialValues);
  return children;
};

export const TestProvider = ({
  initialValues = [],
  user,
  activeCompany,
  children,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues?: any[];
  user?: User;
  activeCompany?: CompanyLite;
  children: React.ReactNode;
}) => {
  const atoms = [
    user && [userAtom, user],
    activeCompany && [activeCompanyAtom, activeCompany],
    ...initialValues,
  ];

  return (
    <Provider>
      <HydrateAtoms initialValues={atoms}>{children}</HydrateAtoms>
    </Provider>
  );
};
