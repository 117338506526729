import React, { SVGProps } from 'react';

export function TableGroupIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group">
        <path
          id="Vector"
          d="M16.1111 1H1.88889C1.65314 1 1.42705 1.09365 1.26035 1.26035C1.09365 1.42705 1 1.65314 1 1.88889V16.1111C1 16.3469 1.09365 16.573 1.26035 16.7397C1.42705 16.9064 1.65314 17 1.88889 17H16.1111C16.3469 17 16.573 16.9064 16.7397 16.7397C16.9064 16.573 17 16.3469 17 16.1111V1.88889C17 1.65314 16.9064 1.42705 16.7397 1.26035C16.573 1.09365 16.3469 1 16.1111 1V1Z"
          stroke="#8F90A6"
          strokeWidth="2"
          strokeLinejoin="round"
          fill="black"
        />
        <path
          id="Vector_2"
          d="M5.49911 1L1 5M6.77778 3.66667L1 9M6.77778 7.66667L1 13M6.77778 11.6667L1 17M6.77778 1V17"
          stroke="#8F90A6"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}
