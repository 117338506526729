import { EditorConfiguration } from '../../../../editor/EditorAction.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import { PressShiftArrowDownBlockInterface } from '../../../../editor/actions/pressShiftArrowDown.js';
import { PressShiftArrowUpBlockEditorInterface } from '../../../../editor/actions/pressShiftArrowUp.js';
import { PressArrowLeftBlockInterface } from '../../../../editor/keyboardNavigation/pressArrowLeft.js';
import { PressArrowRightBlockInterface } from '../../../../editor/keyboardNavigation/pressArrowRight.js';
import { createParagraphBlock, isTextBlock } from 'editor-content/Block.js';
import textBlockPressShiftArrowDownStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockPressShiftArrowDownStrategy.js';
import textBlockPressShiftArrowUpStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockPressShiftArrowUpStrategy.js';
import { isCollapsed } from '../../../../editor/selection/contentSelection/ContentSelection.js';
import getEndOfBlock from '../../../../editor/blocks/textBlocksStrategies/getEndOfBlock.js';

export const editorConfigurationBody: EditorConfiguration<
  HydratedBlock,
  PressShiftArrowDownBlockInterface &
    PressShiftArrowUpBlockEditorInterface &
    PressArrowLeftBlockInterface &
    PressArrowRightBlockInterface
> = {
  generateBlockEditor: (block) => ({
    pressShiftArrowDown(selection) {
      if (isTextBlock(block)) {
        return textBlockPressShiftArrowDownStrategy(block, selection);
      }

      return true;
    },
    pressShiftArrowUp(selection) {
      if (isTextBlock(block)) {
        return textBlockPressShiftArrowUpStrategy(selection);
      }

      return true;
    },
    isAtBeginningOfBlock(selection) {
      if (!isTextBlock(block)) return true;
      if (!isCollapsed(selection)) return false;
      if (selection.anchorOffset === 0) return true;
      return false;
    },
    getEndOfBlockSelection() {
      return getEndOfBlock(block);
    },
    isAtEndOfBlock(selection) {
      if (!isTextBlock(block)) return true;
      if (!isCollapsed(selection)) return false;
      if (selection.anchorOffset === getEndOfBlock(block).anchorOffset)
        return true;
      return false;
    },
  }),
  createDefaultBlock: createParagraphBlock,
};
