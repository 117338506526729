import { CSSProperties } from 'react';
import { FormattedTableRow } from '../zeck/table/formattedTableData.ts';
import TableCell from './TableCell.tsx';

type TableRowProps = {
  row: FormattedTableRow;
};

const TableRow: React.FC<TableRowProps> = ({ row }) => {
  const { frozen } = row;
  const frozenStyle: CSSProperties = frozen
    ? {
        position: 'sticky',
        top: 0,
        backgroundColor: 'var(--color-light-4)',
        borderBottom: '4px solid var(--color-dark-5)',
        zIndex: 2, // needs to be above a sticky column
      }
    : {};
  return (
    <tr style={{ ...row.style, ...frozenStyle }}>
      {row.cells.map((cell, i) => (
        <TableCell key={i} cell={cell} frozenHeader={frozen} />
      ))}
    </tr>
  );
};

export default TableRow;
