import React, { useEffect, useRef, useState } from 'react';
import styles from './CommentCelebration.module.scss';
import UserInitials from '../../../../../design-system/atoms/UserInitials.js';
import initials from 'initials';
import Confetti from 'react-confetti';
import { useActiveCompany } from '../../../../../../app/userAndCompany/activeCompanyAtom.tsx';
import { useUser } from '../../../../../../app/userAndCompany/userAtom.tsx';

const CommentCelebration: React.FC = () => {
  const activeCompany = useActiveCompany();
  const user = useUser();
  const fullName = `${user.firstName} ${user.lastName}`;

  const brandColor = activeCompany.brandColorPrimary ?? '#5445FF';

  const brandColorShades: string[] = [
    brandColor,
    brandColor + 'bb',
    brandColor + '99',
    brandColor + '66',
  ];

  const elementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      const { width } = elementRef.current.getBoundingClientRect();
      setWidth(width);
    }
  }, []);

  return (
    <div
      className={styles.commentCelebration}
      data-testid="comment-celebration"
    >
      <div ref={elementRef} className={styles.commentCelebration__confetti}>
        <Confetti
          height={100}
          width={width}
          numberOfPieces={60}
          friction={0.95}
          colors={brandColorShades}
        />
        <div className={styles.commentCelebration__confetti__initials}>
          <UserInitials size="large">{initials(fullName)}</UserInitials>
        </div>
        <div className={styles.commentCelebration__confetti__fade} />
      </div>
      <div className={styles.commentCelebration__message}>
        <div className={styles.commentCelebration__message__title}>
          Way to Go, {user.firstName}.
        </div>
        <div className={styles.commentCelebration__message__subtitle}>
          Comments drive better
          <br />
          discussions.
        </div>
      </div>
    </div>
  );
};

export default CommentCelebration;
