import { BlockCommentEffectiveHighlight } from '../commentsSidebar/useComments/getBlockCommentContexts.ts';
import ContentSelection from '../../../../../editor/selection/contentSelection/ContentSelection.ts';
import { Atom, useStore } from 'jotai';

export const addSelectionHighlightIfNeeded = (
  highlights: BlockCommentEffectiveHighlight[],
  store: ReturnType<typeof useStore>,
  shouldShowFormValue: boolean,
  selectionAtom: Atom<ContentSelection | null>,
): BlockCommentEffectiveHighlight[] => {
  const selectionValue = store.get(selectionAtom);

  const selectionHighlight =
    selectionValue && shouldShowFormValue
      ? [
          {
            commentId: 'new-highlight',
            contentSelection: selectionValue,
          },
        ]
      : [];

  return [...(highlights ?? []), ...selectionHighlight];
};
