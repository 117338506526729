import React from 'react';

import { CommentWithActions } from '../../useComments/useComments.ts';
import { isImageBlock, isTextBlock, TextBlock } from 'editor-content/Block.ts';
import QuotedImageBlock from './QuotedImageBlock.tsx';
import { getTextFromNodes } from 'editor-content/TextNode.ts';
import ContentSelection from '../../../../../../../editor/selection/contentSelection/ContentSelection.ts';
import splitTextNodesFromContentSelection from '../../../../../../../editor/blocks/textNode/splitTextNodesFromContentSelection.ts';
import styles from './CommentQuote.module.scss';
import { truncate } from '../../../../../../../junkDrawer/truncate.ts';
import {
  CommentSelectionState,
  isBlockNotFoundCommentState,
  isSelectionTextNotFoundCommentState,
  isValidCommentState,
} from '../../useComments/getEffectiveCommentContent.ts';

const getQuotedTextFromBlock = (
  block: TextBlock,
  snippetSelection: ContentSelection,
) => {
  const [, selectedTextNodes] = splitTextNodesFromContentSelection(
    block.content,
    snippetSelection,
  );
  const text = getTextFromNodes(selectedTextNodes);
  return truncate(text, 960);
};

const TextBlockQuoteStatus: React.FC<{
  comment: CommentSelectionState;
}> = ({ comment }) => {
  let statusText: string | null = null;

  if (isValidCommentState(comment) && comment.blockHasChanged) {
    statusText = '(Text may have been edited since this was posted.)';
  }

  if (
    isBlockNotFoundCommentState(comment) ||
    isSelectionTextNotFoundCommentState(comment)
  ) {
    statusText = '(Text was edited or deleted since this was posted.)';
  }

  if (!statusText) {
    return null;
  }

  return <div className={styles.commentOrReply__quoteStatus}>{statusText}</div>;
};

export const CommentQuote: React.FC<{
  comment: Pick<CommentWithActions, 'selection'> & CommentSelectionState;
}> = ({ comment }) => {
  const block = comment.selection?.block;
  const snippetSelection = comment.selection?.snippetSelection;
  if (!block) {
    return null;
  }

  if (isImageBlock(block)) {
    return (
      <QuotedImageBlock
        block={block}
        imageEdited={isValidCommentState(comment) && comment.blockHasChanged}
        imageDeleted={!isValidCommentState(comment)}
      />
    );
  }

  if (isTextBlock(block) && snippetSelection) {
    return (
      <div data-testid="comment-quote" className={styles.commentOrReply__quote}>
        {getQuotedTextFromBlock(block, snippetSelection)}
        <TextBlockQuoteStatus comment={comment} />
      </div>
    );
  }

  return null;
};

export default CommentQuote;
