import React from 'react';
import SelectableDiv from './SelectableDiv.tsx';
import BlockActions from './BlockActions.ts';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import { WideWidthBlockContainer } from '../../../../design-system/zeck/TableView.tsx';
import { CartaCapTableBlock } from 'editor-content/CartaBlock.ts';
import CartaCapTable from '../../../../design-system/zeck/cartaCapTable/CartaCapTable.tsx';
import cx from 'classnames';
import styles from './CartaCapTableEditableWithKeyboard.module.scss';

type CartaCapTableEditableWithKeyboardProps = {
  className?: string;
  isInBlockSelection: boolean;
  'data-testid'?: string;
} & BlockActions<CartaCapTableBlock>;

export default React.forwardRef<
  HTMLElement,
  CartaCapTableEditableWithKeyboardProps
>(function CartaCapTableEditableWithKeyboard(
  {
    className,
    block,
    selection,
    onSelect,
    isInBlockSelection,
    'data-testid': dataTestid,
  },
  forwardedRef,
) {
  return (
    <WideWidthBlockContainer>
      <SelectableDiv
        square
        onSelect={onSelect}
        className={cx(styles.selectableWrapper, className)}
        internalSelection={selection}
        isInBlockSelection={isInBlockSelection}
        data-testid={dataTestid}
        ref={mergeRefs([forwardedRef])}
      >
        <CartaCapTable block={block} />
      </SelectableDiv>
    </WideWidthBlockContainer>
  );
});
