import {
  Block,
  ImageBlock,
  isImageBlock,
  isTextBlock,
  TextBlock,
} from 'editor-content/Block.ts';
import { equals } from 'lodash/fp.js';

type BlockType = ImageBlock | TextBlock;

const getTextBlockSubset = (block: TextBlock) => {
  return {
    content: block.content,
  };
};

export const isBlockChangedForComments = (
  baseBlock: BlockType,
  newBlock: Block,
): boolean => {
  if (!isTextBlock(newBlock) && !isImageBlock(newBlock)) {
    return true; // somehow block has changed to unsupported block type
  }

  if (isImageBlock(baseBlock) && isImageBlock(newBlock)) {
    return baseBlock.guid !== newBlock.guid;
  }

  if (isTextBlock(baseBlock) && isTextBlock(newBlock)) {
    return !equals(getTextBlockSubset(baseBlock), getTextBlockSubset(newBlock));
  }

  return true;
};
