import { useRef, useState } from 'react';
import { CompanyLite, PublishedZeck } from '../../../../types.ts';
import ViewTopBar from '../ViewTopBar.tsx';
import PublishedSidebar from './PublishedSidebar.tsx';
import ViewPageLayout from '../../../../design-system/layouts/ViewPageLayout.tsx';
import { BrandKitResource } from '../../../../design-system/zeck/WithBrandKit.tsx';
import BrandLogo from '../BrandLogo.tsx';
import Link from '../../../../design-system/atoms/Link.tsx';
import { publishedZeckCoverPath } from '../../../../services/Paths.ts';
import mergeRefs from '../../../../junkDrawer/mergeRefs.js';
import { CommentsState } from './commentsSidebar/useComments/useComments.ts';

type ViewPageProps = {
  brandKitResource: BrandKitResource;
  zeck: PublishedZeck;
  company: CompanyLite;
  children: React.ReactNode;
  scrollContainerRef?: React.Ref<HTMLDivElement>;
  scrollToSectionOrZeckCover: (sectionId?: string) => void;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  commentsState: CommentsState;
};

const PublishPage = ({
  brandKitResource,
  zeck,
  company,
  children,
  onScroll,
  scrollContainerRef,
  scrollToSectionOrZeckCover,
  commentsState,
}: ViewPageProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const scrollToRef = useRef<HTMLElement>(null);

  return (
    <ViewPageLayout
      {...{
        scrollContainerRef: mergeRefs([scrollToRef, scrollContainerRef]),
        onScroll,
        headerSlot: (
          <ViewTopBar
            onClickOpenSidebar={(e) => {
              e.stopPropagation();
              setMenuIsOpen(true);
            }}
            zeck={zeck}
            showKebab={!zeck.settings.disablePrintToPdf}
            showComments={!zeck.settings.disableComments}
            commentsState={commentsState}
          >
            <Link
              href={publishedZeckCoverPath(zeck)}
              aria-label={'current zeck link'}
              onClick={() => {
                if (publishedZeckCoverPath(zeck) === window.location.pathname) {
                  scrollToRef.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                }
              }}
            >
              <BrandLogo
                brandKitResource={brandKitResource}
                company={company}
              />
            </Link>
          </ViewTopBar>
        ),
        sidebarSlot: (
          <PublishedSidebar
            {...{
              isOpen: menuIsOpen,
              onRequestClose() {
                setMenuIsOpen(false);
              },
              scrollToSectionOrZeckCover,
              zeck,
            }}
          />
        ),
      }}
    >
      {children}
    </ViewPageLayout>
  );
};

export default PublishPage;
