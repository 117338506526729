import ContentSelection, {
  contentSelection,
} from '../selection/contentSelection/ContentSelection.js';
import { EditorConfiguration, EditorState } from '../EditorAction.js';
import { isBlockSelection } from '../EditorSelection.js';
import getSelectedBlock from '../../pages/zeck/editor/BodyEditor/getSelectedBlock.js';
import { TextSelection, textSelection } from '../selection/TextSelection.js';

export type PressArrowRightBlockInterface = {
  isAtEndOfBlock: (selection: ContentSelection) => boolean;
};

type PressArrowRightResult = {
  type: 'selection';
  selection: TextSelection;
} | void;

export const pressArrowRight =
  <BlockType>({
    generateBlockEditor,
  }: EditorConfiguration<BlockType, PressArrowRightBlockInterface>) =>
  (initialState: EditorState<BlockType>): PressArrowRightResult => {
    const { content, selection } = initialState;
    if (!selection) return;
    if (isBlockSelection(selection)) return;

    const selectedBlock = getSelectedBlock(content, selection);
    if (!selectedBlock) return;

    const isAtEndOfBlock = generateBlockEditor(selectedBlock).isAtEndOfBlock(
      selection.offset,
    );
    if (!isAtEndOfBlock) return;

    const newBlockIndex = selection.index + 1;
    if (newBlockIndex >= content.length) return;

    return {
      type: 'selection',
      selection: textSelection(newBlockIndex, contentSelection(0)),
    };
  };
