import { showFilePicker } from './showFilePicker.js';
import { createImageBlock } from 'editor-content/Block.js';
import { IMAGE_FILE_TYPES } from '../../../services/imageUpload.js';

type UploadImageResult =
  | {
      type: 'success';
      data: { imageId: string; width: number; height: number };
    }
  | { type: 'error'; message: string };

export const uploadAndCreateImageBlock =
  (uploadImage: (file: File) => Promise<UploadImageResult>) => async () => {
    let files: File[];
    try {
      files = await showFilePicker(IMAGE_FILE_TYPES.join(','));
    } catch (e) {
      return { type: 'cancel' as const };
    }

    const file = files[0];

    if (!file) {
      return {
        type: 'error' as const,
        message: 'Please pick an image',
      };
    }

    const result = await uploadImage(file);

    switch (result.type) {
      case 'error':
        return result;
      case 'success': {
        const { imageId, width, height } = result.data;
        return {
          type: 'success' as const,
          data: createImageBlock(imageId, '', 'column', 'center', {
            width,
            height,
          }),
        };
      }
    }
  };
