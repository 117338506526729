import useDownsampleScrollEvents from './useDownsampleScrollEvents.ts';
import { logError } from '../logging/logger.ts';
import { useMemo } from 'react';
import { useAddZeckEvent } from './useAddZeckEvent.ts';

const SCROLL_EVENT_SAMPLE_WINDOW_MS = 1000 * 30;

const useAnalyticsEvents = () => {
  const addZeckEvent = useAddZeckEvent();

  const downsampledScrollInSection = useDownsampleScrollEvents(
    ({
      sectionId,
      zeckId,
      timestamp,
    }: {
      sectionId: string;
      zeckId: string;
      timestamp: number;
    }) => {
      addZeckEvent({
        contentTypeId: sectionId,
        contentType: 'SECTION',
        eventType: 'SCROLL',
        firedAt: timestamp,
        payload: {
          zeckId,
          userAgent: window.navigator.userAgent,
        },
      }).catch((e) => {
        logError(
          new Error(
            `exception occurred while registering section scroll: ${e.message}`,
          ),
        );
      });
    },
    SCROLL_EVENT_SAMPLE_WINDOW_MS,
  );

  return useMemo(() => {
    return {
      trackScrollInSection: (data: { zeckId: string; sectionId: string }) => {
        downsampledScrollInSection({
          sectionId: data.sectionId,
          zeckId: data.zeckId,
          timestamp: Date.now(),
        });
      },
      trackViewZeck: (zeckId: string) => {
        addZeckEvent({
          contentTypeId: zeckId,
          contentType: 'ZECK',
          eventType: 'VIEW',
          firedAt: Date.now(),
          payload: {
            userAgent: window.navigator.userAgent,
          },
        }).catch((e) => {
          logError(
            new Error(
              `exception occurred while registering published zeck view: ${e.message}`,
            ),
          );
        });
      },
    };
  }, [downsampledScrollInSection, addZeckEvent]);
};

export default useAnalyticsEvents;
