import { CommentContentNode } from 'editor-content/CommentContent.js';
import { VoteBlock } from 'editor-content/VoteBlock.js';
import { useState } from 'react';
import Toolbar, {
  ToolbarButton,
} from '../../../design-system/organisms/Toolbar.tsx';
import HoverNextToPoint from '../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import { isSameSelection } from '../../../SelectionComment.ts';
import { User } from '../../../types.ts';
import SelectionCommentsMenu from './comments/components/SelectionCommentsMenu.tsx';
import SelectionCommentWithActions from './comments/SelectionCommentWithActions.ts';

type VoteFormattingExperienceProps = {
  block: VoteBlock;
  getEl: () => HTMLElement | undefined;
  onAddSelectionComment: (
    blockId: string,
    commentText: CommentContentNode[],
  ) => Promise<void>;
  user: User;
  selectionComments: SelectionCommentWithActions[];
  zeckId: string;
  sectionId: string;
};

type BlockInteractiveRenderState = 'formatting' | 'commenting';

const VoteFormattingExperience: React.VFC<VoteFormattingExperienceProps> = ({
  getEl,
  block,
  onAddSelectionComment,
  user,
  selectionComments,
  zeckId,
  sectionId,
}) => {
  const [interactiveState, setInteractiveState] =
    useState<BlockInteractiveRenderState>('formatting');

  return (
    <>
      {interactiveState === 'formatting' && (
        <HoverNextToPoint
          usePortal
          viewportPolicy="none"
          containerStyles={{ zIndex: 'initial' }}
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height - 16,
            ];
          }}
        >
          <Toolbar>
            <ToolbarButton
              iconName="speechBubbleWithText"
              onClick={() => setInteractiveState('commenting')}
            >
              Comment
            </ToolbarButton>
          </Toolbar>
        </HoverNextToPoint>
      )}
      {interactiveState === 'commenting' && (
        <HoverNextToPoint
          usePortal
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height + 24,
            ];
          }}
        >
          <SelectionCommentsMenu
            autofocus
            user={user}
            comments={selectionComments.filter((selectionComment) =>
              isSameSelection(selectionComment, block),
            )}
            onPostComment={async (content) =>
              await onAddSelectionComment(block.id, content)
            }
            zeckId={zeckId}
            sectionId={sectionId}
          />
        </HoverNextToPoint>
      )}
    </>
  );
};

export default VoteFormattingExperience;
