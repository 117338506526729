import { EditorContent } from '../../edit/useEditorState.js';
import ZeckEditorSelection, {
  isBodySelection,
  isHeadlineSelection,
  isTitleSelection,
} from '../ZeckEditorSelection.js';
import { ZeckEditorState } from './ZeckEditorState.js';
import {
  contentSelection,
  isCollapsed,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import BodyEditor from '../BodyEditor/BodyEditor.js';
import { getBodyState } from '../getBodyState.js';
import { getTextNodesLength } from 'editor-content/TextNode.js';
import { textSelection } from '../../../../editor/selection/TextSelection.js';

export const pressArrowRight = (
  content: EditorContent,
  selection: ZeckEditorSelection,
): ZeckEditorState | void => {
  if (isTitleSelection(selection)) {
    if (!isCollapsed(selection)) return;
    if (selection.anchorOffset === content.title.length) {
      return {
        content,
        selection: {
          target: 'headline',
          ...contentSelection(0),
        },
      };
    }
  } else if (isHeadlineSelection(selection)) {
    if (!isCollapsed(selection)) return;
    if (selection.anchorOffset === getTextNodesLength(content.headline)) {
      return {
        content,
        selection: {
          target: 'body',
          ...textSelection(0, contentSelection(0)),
        },
      };
    }
  } else if (isBodySelection(selection)) {
    const result = BodyEditor.pressArrowRight(getBodyState(content, selection));
    if (!result) return;

    return {
      content,
      selection: {
        target: 'body',
        ...result.selection,
      },
    };
  }
};
