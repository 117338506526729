import { Navigate, useNavigate } from 'react-router-dom';
import useDocumentTitleFromResult from '../../junkDrawer/useDocumentTitleFromResult.js';
import {
  ErrorResult,
  SuccessResult,
  chainResult,
  isSuccessResult,
  mapResult,
  pipe,
} from '../../result/Result.ts';
import { useRequiredParams } from '../../routing/useRequiredParams.ts';
import { sectionEditPath } from '../../services/Paths.ts';
import { getSectionIdFromSlug } from '../../types/Section.ts';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';
import FullPageLoading from '../FullPageLoading.tsx';
import UnauthorizedPage from '../error/unauthorized/UnauthorizedPage.tsx';
import ConflictModal from './ConflictModal.tsx';
import EditSectionPageView, {
  EditSectionPageLoading,
} from './EditSectionPageView.tsx';
import redirectAfterSectionDelete from './edit/redirectAfterSectionDelete.ts';
import SectionWithActions from './edit/zeckCover/SectionWithActions.ts';
import useFetchSelectionComments from './useFetchSelectionComments.ts';
import useFetchZeckForSection from './useFetchZeckForSection.ts';
import withTouchRedirect from './withTouchRedirect.tsx';
import { zeckResourceToLinkable } from '../../types/ZeckResource.ts';
import { useFetchIntegrationsForSection } from './useFetchIntegrationsForSection.ts';
import { isTableBlock } from 'editor-content/TableBlock.ts';
import { isString, uniq } from 'lodash';
import usePageTracking from '../../services/usePageTracking.ts';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { apiAtoms } from '../../api/apiAtoms.ts';
const CONFLICT_DESCRIPTION =
  "is editing this section right now too. You both risk losing work if you continue to edit the same section at the same time. You should wait until they've completed their editing to continue.";

type EditSectionPageProps = {
  userAndCompany: UserAndCompany;
  onLogout: () => void;
};

const EditSectionPage: React.FC<EditSectionPageProps> = ({
  userAndCompany,
  onLogout,
}) => {
  const { slug } = useRequiredParams('slug');
  const sectionId = getSectionIdFromSlug(slug);
  const { zeckResult, isReloading } = useFetchZeckForSection(sectionId);

  const prevoteDataFetch = useSetAtom(apiAtoms.prevote.prevoteDataFetchAtom);
  const maybeZeckId = isSuccessResult(zeckResult) ? zeckResult.data.id : null;
  useEffect(() => {
    if (maybeZeckId) {
      prevoteDataFetch(maybeZeckId);
    }
  }, [maybeZeckId, prevoteDataFetch]);

  const prevoteAtom = apiAtoms.prevote.prevoteDataAtomFamily(maybeZeckId ?? '');
  const prevoteData = useAtomValue(prevoteAtom);

  useDocumentTitleFromResult(zeckResult);
  usePageTracking('section_edit', userAndCompany);

  let integrationIds: string[] = [];
  if (zeckResult.type === 'success' || zeckResult.type === 'mutating') {
    const currentSection = zeckResult.data.sections.find(
      ({ id }) => id === sectionId,
    );

    if (currentSection) {
      integrationIds = uniq(
        currentSection.body
          .filter(isTableBlock)
          .map(({ integrationId }) => integrationId)
          .filter(isString),
      );
    }
  }

  const integrationData = useFetchIntegrationsForSection(
    sectionId,
    integrationIds,
  );

  const { selectionComments, createSelectionComment } =
    useFetchSelectionComments(sectionId);

  const navigate = useNavigate();

  const serverStateResult = pipe(
    zeckResult,
    mapResult((zeck) => ({
      ...zeck,
      sections: zeck.sections.map(
        (section): SectionWithActions => ({
          ...section,
          actions: {
            ...section.actions,
            async remove() {
              await section.actions.remove();
              redirectAfterSectionDelete({
                zeck: zeck,
                currentSectionId: sectionId,
                navTo: navigate,
                sectionId: section.id,
              });
            },
          },
        }),
      ),
      actions: {
        ...zeck.actions,
        createSection: async (isSupplemental: boolean) => {
          const newSection = await zeck.actions.createSection(isSupplemental);

          navigate(sectionEditPath(newSection, zeck.companyId));

          return newSection;
        },
      },
    })),
    chainResult((zeck) => {
      const currentSection = zeck.sections.find(({ id }) => id === sectionId);

      if (!currentSection) {
        return ErrorResult({ type: 'current-section' as const });
      }

      return SuccessResult({
        zeck,
        currentSection,
      });
    }),
  );

  switch (serverStateResult.type) {
    case 'loading':
      return <FullPageLoading out={false} />;
    case 'error':
      if (serverStateResult.error) {
        switch (serverStateResult.error.type) {
          case 'conflict': {
            return (
              <ConflictModal
                description={CONFLICT_DESCRIPTION}
                isOpen={true}
                user={serverStateResult.error.updatedBy}
              />
            );
          }
          case 'current-section': {
            return (
              <UnauthorizedPage
                email={userAndCompany.user.email}
                onClickLogout={onLogout}
              />
            );
          }
        }
      }
      return <Navigate to="/404" />;
    case 'mutating':
    case 'success':
      break;
  }

  const currentSection = serverStateResult.data.currentSection;
  const zeck = serverStateResult.data.zeck;

  if (isReloading) {
    return <EditSectionPageLoading zeck={zeck} section={currentSection} />;
  }

  return (
    <EditSectionPageView
      zeck={zeck}
      linkables={zeck.resources.map((resource) =>
        zeckResourceToLinkable(resource, zeck.companyId, 'edit'),
      )}
      section={currentSection}
      integrationData={integrationData}
      userAndCompany={userAndCompany}
      selectionComments={selectionComments}
      createSelectionComment={createSelectionComment}
      prevoteData={prevoteData}
    />
  );
};

export default withTouchRedirect(EditSectionPage);
