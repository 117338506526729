import React from 'react';
import File from '../../../../design-system/zeck/File.tsx';
import cx from 'classnames';
import SelectableDiv from './SelectableDiv.tsx';
import BlockActions from './BlockActions.ts';
import { FileBlock } from 'editor-content/Block.js';

type FileEditableWithKeyboardProps = {
  isInBlockSelection: boolean;
  className?: string;
  'data-testid'?: string;
} & BlockActions<FileBlock>;

const FileEditableWithKeyboard = React.forwardRef<
  HTMLDivElement,
  FileEditableWithKeyboardProps
>(function FileEditable(
  {
    block,
    selection,
    onSelect,
    isInBlockSelection,
    className,
    'data-testid': testid,
  },
  forwardedRef,
) {
  return (
    <SelectableDiv
      className={cx(className)}
      onSelect={onSelect}
      internalSelection={selection}
      isInBlockSelection={isInBlockSelection}
      data-testid={testid}
    >
      <File ref={forwardedRef} filename={block.filename} clickable={false} />
    </SelectableDiv>
  );
});

export default FileEditableWithKeyboard;
