import {
  CommentSelectionSelectionTextNotFoundState,
  CommentSelectionValidState,
  isBlockNotFoundCommentState,
  isNoneCommentState,
  isSelectionTextNotFoundCommentState,
  isValidCommentState,
} from './getEffectiveCommentContent.ts';
import { CommentSelectionBlockNotFoundState } from './getEffectiveCommentContent.ts';
import { CommentSelectionNoneState } from './getEffectiveCommentContent.ts';
import { sortByCreatedAt } from './sortByCreatedAt.ts';

type CommentSortableBase = {
  createdAt: number;
};

export type CommentSortResult = -1 | 0 | 1;

type CommentSortableNoneState = CommentSortableBase & CommentSelectionNoneState;
type CommentSortableBlockNotFoundState = CommentSortableBase &
  CommentSelectionBlockNotFoundState;
type CommentSortableSelectionTextNotFoundState = CommentSortableBase &
  CommentSelectionSelectionTextNotFoundState;
type CommentSortableValidState = CommentSortableBase &
  CommentSelectionValidState;

export const comment1First = -1;
export const comment2First = 1;
export const commentsAreAtEqualOrder = 0;

export type CommentSortable =
  | CommentSortableNoneState
  | CommentSortableBlockNotFoundState
  | CommentSortableSelectionTextNotFoundState
  | CommentSortableValidState;

const hasBlockIndex = (
  comment: CommentSortable,
): comment is
  | CommentSortableSelectionTextNotFoundState
  | CommentSortableValidState => 'blockIndex' in comment;

const areEitherSectionComments = (
  comment1: CommentSortable,
  comment2: CommentSortable,
): boolean => {
  return isNoneCommentState(comment1) || isNoneCommentState(comment2);
};

const areEitherBlockNotFoundComments = (
  comment1: CommentSortable,
  comment2: CommentSortable,
): boolean => {
  return (
    isBlockNotFoundCommentState(comment1) ||
    isBlockNotFoundCommentState(comment2)
  );
};

const handleSectionComments = (
  comment1: CommentSortable,
  comment2: CommentSortable,
): CommentSortResult => {
  if (isNoneCommentState(comment1) && !isNoneCommentState(comment2)) {
    return comment1First;
  }

  if (!isNoneCommentState(comment1) && isNoneCommentState(comment2)) {
    return comment2First;
  }

  return sortByCreatedAt(comment1, comment2);
};

const handleBlockNotFoundComments = (
  comment1: CommentSortable,
  comment2: CommentSortable,
): CommentSortResult => {
  if (
    isBlockNotFoundCommentState(comment1) &&
    !isBlockNotFoundCommentState(comment2)
  ) {
    return comment2First;
  }

  if (
    !isBlockNotFoundCommentState(comment1) &&
    isBlockNotFoundCommentState(comment2)
  ) {
    return comment1First;
  }

  return sortByCreatedAt(comment1, comment2);
};

const handleCommentsWithinSameBlock = (
  comment1: CommentSortable,
  comment2: CommentSortable,
): CommentSortResult => {
  if (
    isSelectionTextNotFoundCommentState(comment1) &&
    isValidCommentState(comment2)
  ) {
    return comment1First;
  }

  if (
    isValidCommentState(comment1) &&
    isSelectionTextNotFoundCommentState(comment2)
  ) {
    return comment2First;
  }

  if (
    isSelectionTextNotFoundCommentState(comment1) &&
    isSelectionTextNotFoundCommentState(comment2)
  ) {
    return sortByCreatedAt(comment1, comment2);
  }

  if (isValidCommentState(comment1) && isValidCommentState(comment2)) {
    if (
      comment1.effectiveStartOfSelection === null ||
      comment2.effectiveStartOfSelection === null
    ) {
      return sortByCreatedAt(comment1, comment2);
    }

    if (
      comment1.effectiveStartOfSelection < comment2.effectiveStartOfSelection
    ) {
      return comment1First;
    }

    if (
      comment1.effectiveStartOfSelection > comment2.effectiveStartOfSelection
    ) {
      return comment2First;
    }

    return sortByCreatedAt(comment1, comment2);
  }

  return commentsAreAtEqualOrder;
};

export const commentSortOrder = (
  comment1: CommentSortable,
  comment2: CommentSortable,
): CommentSortResult => {
  // try to handle section comments first
  if (areEitherSectionComments(comment1, comment2)) {
    return handleSectionComments(comment1, comment2);
  }

  // next, try to handle block not found comments
  if (areEitherBlockNotFoundComments(comment1, comment2)) {
    return handleBlockNotFoundComments(comment1, comment2);
  }

  if (hasBlockIndex(comment1) && hasBlockIndex(comment2)) {
    if (comment1.blockIndex < comment2.blockIndex) {
      return comment1First;
    }

    if (comment1.blockIndex > comment2.blockIndex) {
      return comment2First;
    }

    return handleCommentsWithinSameBlock(comment1, comment2);
  }

  //shouldn't be able to get here if comments have been built properly
  return commentsAreAtEqualOrder;
};
