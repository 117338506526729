import { Block } from 'editor-content/Block.ts';
import ContentSelection from '../../../../../../editor/selection/contentSelection/ContentSelection.ts';
import { PublishedSection } from '../../../../../../types.ts';
import { isValidCommentState } from './getEffectiveCommentContent.ts';
import { CommentWithActions } from './useComments.ts';

export type BlockCommentEffectiveHighlight = {
  commentId: string;
  contentSelection: ContentSelection;
};

export type BlockCommentContext = {
  orphanedCommentIds: string[];
  commentCount: number;
  highlights: BlockCommentEffectiveHighlight[];
};

export const getBlockCommentContexts = (
  comments: CommentWithActions[],
  publishedSection: PublishedSection,
): Record<string, BlockCommentContext> => {
  const blockCommentContexts: Record<string, BlockCommentContext> = {};

  for (const block of publishedSection.body) {
    blockCommentContexts[block.id] = _getBlockCommentContext(comments, block);
  }

  return blockCommentContexts;
};

export const _getBlockCommentContext = (
  comments: CommentWithActions[],
  block: Block,
) => {
  const commentsForBlock = comments.filter(
    (c) => c.selection?.block.id === block.id,
  );

  const orphanedCommentIds = commentsForBlock
    .filter((c) => !isValidCommentState(c))
    .map((c) => c.id);

  const highlights: BlockCommentEffectiveHighlight[] = [];

  commentsForBlock.filter(isValidCommentState).forEach((c) => {
    if (c.selection?.snippetSelection && c.effectiveStartOfSelection !== null) {
      highlights.push({
        commentId: c.id,
        contentSelection: {
          anchorOffset: c.effectiveStartOfSelection,
          focusOffset:
            c.effectiveStartOfSelection +
            Math.abs(
              c.selection.snippetSelection.focusOffset -
                c.selection?.snippetSelection?.anchorOffset,
            ),
        },
      });
    }
  });

  return {
    orphanedCommentIds,
    commentCount: commentsForBlock.length,
    highlights,
  };
};
