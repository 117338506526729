import { HydratedBlock } from '../../../types/HydratedBlock.js';
import { TextNode } from 'editor-content/TextNode.js';
import EditorData, { BlockData } from './EditorData.js';
import Linkable from 'editor-content/html/Linkable.js';
import useImageUpload from '../../../services/imageUpload.js';
import useCopyPasteHandlers from './useCopyPasteHandlers.js';
import {
  handlePaste,
  writeEditorDataToClipboard,
} from './copyPasteHandlers.js';
import { cleanupBlocksForPaste } from './cleanupBlocksForPaste.js';
import cleanupTextNodesForPaste from './cleanupTextNodesForPaste.js';
import usePasteContentProcessing from './usePasteContentProcessing.js';
import ConfirmPasteModal from './ConfirmPasteModal.js';
import {
  Block,
  isAssetContainingBlock,
  isFileBlock,
  isImageBlock,
} from 'editor-content/Block.js';
import useApi from '../../../api/useApi.js';
import { match } from 'ts-pattern';

type PasteInfo = {
  editorData: EditorData<Block>;
  sourceCompanyId: string | null;
  destCompanyId: string;
};

const CopyPasteExperience: React.FC<{
  onPasteBlocks: (pastedBlocks: HydratedBlock[]) => void;
  onPasteText: (textNodes: TextNode[]) => void;
  onPastePlaintext: (plainText: string) => void;
  onPasteImage: (imageId: string, width: number, height: number) => void;
  onCut: () => EditorData<HydratedBlock> | void;
  onCopy: () => EditorData<HydratedBlock> | void;
  linkables: Linkable[];
  companyId: string;
}> = ({
  onPasteBlocks,
  onPasteText,
  onPastePlaintext,
  onPasteImage,
  onCut,
  onCopy,
  linkables,
  companyId,
}) => {
  const uploadImage = useImageUpload();
  const { duplicateContentToCompany } = useApi();

  const { handlePasteContent, handleConfirmPaste, handleCancelPaste, state } =
    usePasteContentProcessing<PasteInfo>(
      {
        onPasteContent: ({ editorData }) => {
          switch (editorData.type) {
            case 'block':
              return onPasteBlocks(
                cleanupBlocksForPaste(editorData.content, linkables),
              );
            case 'text':
              return onPasteText(
                cleanupTextNodesForPaste(editorData.content, linkables),
              );
          }
        },
      },
      {
        needsConfirmation: ({ sourceCompanyId, destCompanyId }) =>
          !!sourceCompanyId && sourceCompanyId !== destCompanyId,
        needsCopy: (a) =>
          a.editorData.type == 'block' &&
          a.editorData.content.some(
            (block) =>
              isImageBlock(block) ||
              isFileBlock(block) ||
              isAssetContainingBlock(block),
          ),
        performCopy: async (a) => {
          if (a.editorData.type !== 'block') return a;
          if (!a.sourceCompanyId) return a;

          const newContent = await duplicateContentToCompany(
            a.editorData.content,
            a.sourceCompanyId,
            a.destCompanyId,
          );
          return {
            ...a,
            editorData: BlockData(newContent),
          };
        },
      },
    );

  useCopyPasteHandlers({
    onPaste(clipboardData) {
      handlePaste(
        {
          onPasteEditorData: (data, sourceCompanyId) => {
            handlePasteContent({
              editorData: data,
              sourceCompanyId,
              destCompanyId: companyId,
            });
          },
          onPastePlaintext,
          onPasteImage: async (imageFile: File) => {
            const result = await uploadImage(imageFile, companyId);
            match(result)
              .with({ type: 'success' }, (result) =>
                onPasteImage(
                  result.data.imageId,
                  result.data.width,
                  result.data.height,
                ),
              )
              .with({ type: 'error' }, (result) => {
                console.error(result.message);
              });
          },
        },
        clipboardData,
      );
    },
    onCut(clipboardData) {
      const editorData = onCut();
      if (!editorData) return false;
      writeEditorDataToClipboard(editorData, companyId, clipboardData);
      return true;
    },
    onCopy(clipboardData) {
      const editorData = onCopy();
      if (!editorData) return false;
      writeEditorDataToClipboard(editorData, companyId, clipboardData);
      return true;
    },
  });

  return (
    <ConfirmPasteModal
      state={state}
      onConfirm={handleConfirmPaste}
      onCancel={handleCancelPaste}
    />
  );
};

export default CopyPasteExperience;
