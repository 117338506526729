import React from 'react';
import { AvailableTag } from '../../../../../types/AvailableTag.ts';
import CommentCelebration from './CommentCelebration.tsx';
import CommentMotion from './CommentMotion.tsx';
import CommentThread from './CommentThread.tsx';
import { CommentWithActions } from './useComments/useComments.js';

export type CommentCelebrationWrapperProps = {
  comment: CommentWithActions;
  viewersForComment: AvailableTag[];
  setCommentElementRef: (commentId: string) => (el: HTMLElement | null) => void;
  selectedCommentThreadId: string | null;
  selectCommentThread: (commentId: string, scrollToSelection?: boolean) => void;
  isCommentToCelebrate: boolean;
};

export const CommentCelebrationWrapper: React.FC<
  CommentCelebrationWrapperProps
> = ({
  comment,
  viewersForComment,
  setCommentElementRef,
  selectedCommentThreadId,
  selectCommentThread,
  isCommentToCelebrate,
}) => {
  return (
    <>
      {isCommentToCelebrate ? (
        <CommentMotion key="comment-celebration">
          <CommentCelebration />
        </CommentMotion>
      ) : (
        <CommentMotion>
          <CommentThread
            comment={comment}
            commentReplies={comment.replies}
            commentViewers={viewersForComment}
            resolved={comment.resolved}
            isDM={comment.type === 'DirectMessage'}
            setCommentElementRef={setCommentElementRef}
            selectedCommentThreadId={selectedCommentThreadId}
            selectCommentThread={selectCommentThread}
          />
        </CommentMotion>
      )}
    </>
  );
};
