import cx from 'classnames';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import React, { ReactNode, useRef, useState } from 'react';
import useApi from '../../../../../api/useApi.ts';
import UserInitials from '../../../../../design-system/atoms/UserInitials.tsx';
import {
  callHandlers,
  handleKey,
  stopPropagation,
} from '../../../../../editor/domFacing/events/isKeyMatch.js';
import mergeRefs from '../../../../../junkDrawer/mergeRefs.ts';
import noop from '../../../../../junkDrawer/noop.js';
import { foldResult, pipe } from '../../../../../result/Result.js';
import useFetch from '../../../../../services/useFetch/useFetch.ts';
import { isNotPendingUser, User } from '../../../../../types.ts';
import { AvailableTag } from '../../../../../types/AvailableTag.ts';
import { useActiveCompany } from '../../../../../userAndCompany/activeCompanyAtom.tsx';
import SelectionCommentWithActions from '../SelectionCommentWithActions.ts';
import Comment from './Comment.tsx';
import ConfirmDeleteCommentModal from './ConfirmDeleteCommentModal.tsx';
import EditorCommentForm from './EditorCommentForm.tsx';
import styles from './SelectionCommentsMenu.module.scss';

type CommentsMenuAddCommentProps = {
  autofocus?: boolean;
  userInitials: string;
  availableTags: AvailableTag[];
  onSubmit: (content: CommentContentNode[]) => Promise<void>;
  scrollContainerRef: React.RefObject<HTMLElement>;
};

export const CommentsMenuAddComment: React.FunctionComponent<
  CommentsMenuAddCommentProps
> = ({
  autofocus,
  userInitials,
  onSubmit,
  availableTags,
  scrollContainerRef,
}) => {
  return (
    <div className={styles.comment__addComment}>
      <UserInitials
        size="small"
        className={cx(
          styles.comment__initials,
          styles.comment__initials_addComment,
        )}
      >
        {userInitials}
      </UserInitials>
      <div className={styles.comment__interactable}>
        <EditorCommentForm
          onSubmit={onSubmit}
          placeholder={'Comment or add others with @'}
          autofocus={autofocus}
          availableTags={availableTags}
          submitText="Post"
          scrollContainerRef={scrollContainerRef}
        />
      </div>
    </div>
  );
};

type CommentsMenuContainerProps = { children: ReactNode };

export const CommentsMenuContainer: React.FunctionComponent<
  CommentsMenuContainerProps
> = ({ children }) => {
  return <div className={styles.comments__container}>{children}</div>;
};

type SelectionCommentsMenuWrapperProps = {
  comments: SelectionCommentWithActions[];
  onPostComment: (content: CommentContentNode[]) => Promise<void>;
  user: User;
  autofocus?: boolean;
  zeckId: string;
  sectionId: string;
};

type SelectionCommentsMenuProps = SelectionCommentsMenuWrapperProps & {
  companyId: string;
};

export const SelectionCommentsMenu = React.forwardRef<
  HTMLDivElement,
  SelectionCommentsMenuProps
>(function SelectionCommentsMenu(
  { comments, onPostComment, user, autofocus, zeckId, sectionId, companyId },
  forwardedRef,
) {
  const [isDeletingComment, setIsDeletingComment] =
    useState<SelectionCommentWithActions | null>(null);
  const [disableDeleteCommentModalButton, setDisableDeleteCommentModalButtons] =
    useState(false);

  const { getSectionContributors } = useApi();

  const taggableUsersQuery = useFetch(
    () => getSectionContributors(companyId, zeckId, sectionId),
    [],
  );

  const taggableUsers = pipe(
    taggableUsersQuery,
    foldResult({
      error: () => [],
      loading: () => [],
      success: (d) => d,
    }),
  );

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const availableTags = taggableUsers.filter(isNotPendingUser).map((u) => ({
    userId: u.id,
    displayName: `${u.firstName} ${u.lastName}`,
  }));

  return (
    <div
      // prevent clicks inside this tree (incl portal) from propagating
      onClick={stopPropagation(noop)}
      className={styles.comments__container}
      ref={mergeRefs([forwardedRef, scrollContainerRef])}
      onCut={stopPropagation(noop)}
      onCopy={stopPropagation(noop)}
      onKeyDown={callHandlers<React.KeyboardEvent>([
        // we handle these key events, don't let editor handle them
        handleKey({ key: 'Enter' }, (e) => {
          e.stopPropagation();
        }),
        handleKey({ key: 'Backspace' }, (e) => {
          e.stopPropagation();
        }),
        handleKey({ key: 'Delete' }, (e) => {
          e.stopPropagation();
        }),
      ])}
    >
      {comments.map((comment, idx) => (
        <Comment
          key={comment.id}
          idx={idx}
          userInitials={`${comment.user.firstName[0]}${comment.user.lastName[0]}`}
          edited={comment.updatedAt > comment.createdAt}
          userName={`${comment.user.firstName} ${comment.user.lastName}`}
          onSaveEdit={async (newCommentContent) => {
            const { actions, ...commentContent } = comment;
            await actions.update({
              ...commentContent,
              content: newCommentContent,
            });
          }}
          availableTags={availableTags}
          allowEdit={comment.user.id === user.id}
          onShowDelete={() => {
            setIsDeletingComment(comment);
          }}
          commentContent={comment.content}
          scrollContainerRef={scrollContainerRef}
        />
      ))}
      <CommentsMenuAddComment
        autofocus={autofocus}
        userInitials={`${user.firstName[0]}${user.lastName[0]}`}
        availableTags={availableTags}
        onSubmit={onPostComment}
        scrollContainerRef={scrollContainerRef}
      />
      <ConfirmDeleteCommentModal
        isOpen={!!isDeletingComment}
        onCancel={() => {
          setIsDeletingComment(null);
        }}
        onConfirm={async () => {
          if (!isDeletingComment) {
            return;
          }
          const { actions } = isDeletingComment;
          const { remove } = actions;

          setDisableDeleteCommentModalButtons(true);
          await remove();
          setDisableDeleteCommentModalButtons(false);
          setIsDeletingComment(null);
        }}
        disableButtons={disableDeleteCommentModalButton}
      />
    </div>
  );
});

const SelectionCommentsMenuWrapper = React.forwardRef<
  HTMLDivElement,
  SelectionCommentsMenuWrapperProps
>(function SelectionCommentsMenuWrapper(props, forwardedRef) {
  const activeCompany = useActiveCompany();
  return (
    <SelectionCommentsMenu
      {...props}
      companyId={activeCompany.id}
      ref={forwardedRef}
    />
  );
});

export default SelectionCommentsMenuWrapper;
