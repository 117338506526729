import { useState } from 'react';
import { Prevote } from '../../../types/Prevote.ts';
import OutlineButtonWithLoading from '../../molecules/OutlineButtonWithLoading.tsx';
import styles from './ClearPrevoteButton.module.scss';

const ClearPrevoteButton: React.FC<{
  onRemovePrevote: ((id: string) => Promise<void>) | null;
  currentUserPrevote: Pick<Prevote, 'id'>;
}> = ({ onRemovePrevote, currentUserPrevote }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <OutlineButtonWithLoading
      disabled={!onRemovePrevote || isLoading}
      onClick={
        onRemovePrevote
          ? async () => {
              setIsLoading(true);
              await onRemovePrevote(currentUserPrevote.id);
              setIsLoading(false);
            }
          : () => {}
      }
      size="medium"
      color="secondary"
      className={styles.removePrevoteButton}
      isLoading={isLoading}
    >
      Clear<span className={styles.mobileHidden}>&nbsp;My Pre-Vote</span>
    </OutlineButtonWithLoading>
  );
};

export default ClearPrevoteButton;
