import { ChartBlockData } from 'editor-content/ChartBlock.ts';
import {
  ChartJsSchema,
  makeChartJsSchema,
} from 'editor-content/lib/chartBlockConversions.ts';
import { CheckmarkIcon, TrashIcon } from 'icons';
import { isError } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import {
  AiChartGenerateResponse,
  isAiChartGenerateResponseError,
} from '../../../../api/endpoints/createAiApi.ts';
import Button from '../../../../design-system/atoms/Button.tsx';
import { ButtonSizedLoader } from '../../../../design-system/molecules/LoadingButton.tsx';
import Chart from '../../../../design-system/zeck/chart/chart.tsx';
import EmptyChart from '../../../../design-system/zeck/chart/emptyChart.tsx';
import { logError } from '../../../../logging/logger.ts';
import { AiChartFlowState } from './AiChartFlow.tsx';
import { AiChartFlowLoading } from './AiChartFlowLoading.tsx';
import { AiChartFlowPrompting } from './AiChartFlowPrompting.tsx';
import styles from './AiChartFlowReviewing.module.scss';

export type AiChartFlowReviewingState = {
  type: 'reviewing';
  chart: ChartBlockData;
  pastMessages: string[];
};

type AiChartFlowReviewingProps = {
  chart: ChartBlockData;
  pastMessages?: string[];
  onDiscard: () => void;
  onAccept: (base64ImageData: string) => void;
  generateAiChart: (
    currentMessage: string,
    lastSchema?: ChartJsSchema,
    pastMessages?: string[],
  ) => Promise<AiChartGenerateResponse>;
  setChartState: (state: AiChartFlowState) => void;
};

export const AiChartFlowReviewing: React.FC<AiChartFlowReviewingProps> = ({
  chart,
  pastMessages,
  onDiscard,
  onAccept,
  generateAiChart,
  setChartState,
}) => {
  const [bufferingAccept, setBufferingAccept] = useState(false);
  const [blockId] = useState<string>(v4());
  const [iterating, setIterating] = useState(false);
  const imageContentsSetPromiseRef = useRef<Promise<string>>(); // this ensures that the chart image is set before the user can accept the chart
  const resolveImageRef = useRef<(data: string) => void>();
  const [iterationPrompt, setIterationPrompt] = useState('');

  useEffect(() => {
    // Create new promise if it doesn't exist
    if (!imageContentsSetPromiseRef.current) {
      imageContentsSetPromiseRef.current = new Promise<string>((resolve) => {
        resolveImageRef.current = resolve;
      });
    }
  }, []);

  const onSubmitChartPrompt = async (prompt: string) => {
    setIterating(true);
    imageContentsSetPromiseRef.current = new Promise<string>((resolve) => {
      resolveImageRef.current = resolve;
    });
    const lastSchema = makeChartJsSchema(chart);

    try {
      const chartResponse: AiChartGenerateResponse = await generateAiChart(
        prompt,
        lastSchema,
        pastMessages,
      );

      setIterationPrompt('');

      const newState = isAiChartGenerateResponseError(chartResponse)
        ? {
            type: 'error' as const,
            errorType: chartResponse.errorType,
          }
        : {
            type: 'reviewing' as const,
            chart: chartResponse.chart,
            pastMessages: chartResponse.pastMessages,
          };

      setIterating(false);
      setChartState(newState);
    } catch (e) {
      if (isError(e)) {
        logError(e);
      }

      setChartState({
        type: 'error',
        errorType: 'unknown',
      });
    }
  };

  return (
    <>
      {iterating ? (
        <>
          <EmptyChart />
          <AiChartFlowLoading />
        </>
      ) : (
        <>
          <Chart
            block={{ id: blockId, data: { chartData: chart } }}
            className={styles.previewChart}
            onClick={() => {}}
            preview
            onHasImage={(data: string) => {
              resolveImageRef.current?.(data);
            }}
            showWarning
          />
          <AiChartFlowPrompting
            onSubmitChartPrompt={onSubmitChartPrompt}
            onContentChange={setIterationPrompt}
            reviewPrompt
          />
        </>
      )}
      {!iterationPrompt && (
        <div className={styles.buttonMenu}>
          <Button
            onClick={async () => {
              setBufferingAccept(true);

              const imageData = await imageContentsSetPromiseRef.current;
              if (imageData) {
                onAccept(imageData);
              }
            }}
            size="medium"
            color="transparent"
            className={styles.button}
          >
            {bufferingAccept ? (
              <ButtonSizedLoader />
            ) : (
              <CheckmarkIcon className={styles.buttonIcon} />
            )}
            Accept
          </Button>

          <Button
            onClick={() => {
              onDiscard();
            }}
            size="medium"
            color="transparent"
            className={styles.button}
          >
            <TrashIcon className={styles.buttonIcon} />
            Discard
          </Button>
        </div>
      )}
    </>
  );
};
