import { TableBlock } from 'editor-content/TableBlock.ts';
import { useAtom } from 'jotai';
import { AiContext } from '../../pages/zeck/editor/AiChartFlow/createAiContext.ts';
import Toolbar, {
  ToolbarButton,
  ToolbarGroup,
  // ToolbarMultiSelectMenu,
} from './Toolbar.tsx';
import {
  EditorErrorMessage,
  useErrorModal,
} from '../../editor/errorModal/ErrorModal.tsx';

type TableFormattingMenuProps = {
  showSyncButton: boolean;
  onClickSync: () => void;
  onClickFreeze: (
    stickyOptions: Pick<TableBlock, 'stickyColumns' | 'stickyRows'>,
  ) => void;
  block: TableBlock;
  aiContext: AiContext;
};

export const MERGES_IN_FREEZE_ERROR: EditorErrorMessage = {
  title: "Can't Freeze Row or Column",
  message:
    "Sorry, you can't freeze columns or rows which contain part of a merged cell. Try unmerging the cells and re-syncing or re-uploading.",
};

const TableFormattingMenu: React.FC<TableFormattingMenuProps> = ({
  showSyncButton,
  onClickSync,
  // onClickFreeze,
  block,
  aiContext,
}) => {
  const [chartMakingTable, setChartMakingTable] = useAtom(
    aiContext.getChartMakingFamily(block),
  );

  const { error } = useErrorModal();

  const showChartButton = !chartMakingTable;

  if (error || (!showChartButton && !showSyncButton)) {
    return null;
  }

  return (
    <>
      <Toolbar>
        {/*<ToolbarGroup>*/}
        {/*  <ToolbarMultiSelectMenu*/}
        {/*    label="Freeze"*/}
        {/*    iconName="tableGroup"*/}
        {/*    options={[*/}
        {/*      {*/}
        {/*        label: 'No rows',*/}
        {/*        onClick: () =>*/}
        {/*          onClickFreeze({*/}
        {/*            stickyRows: undefined,*/}
        {/*            stickyColumns: block.stickyColumns,*/}
        {/*          }),*/}
        {/*        selected: block.stickyRows === undefined,*/}
        {/*      },*/}
        {/*      {*/}
        {/*        label: '1 Row',*/}
        {/*        onClick: () => {*/}
        {/*          const hasMergesInRow = (block.data.merges ?? []).some(*/}
        {/*            (merge) => merge.startRow === 0 && merge.rowSpan > 1,*/}
        {/*          );*/}
        {/*          if (hasMergesInRow) {*/}
        {/*            setError(MERGES_IN_FREEZE_ERROR);*/}
        {/*            return;*/}
        {/*          }*/}
        {/*          onClickFreeze({*/}
        {/*            stickyRows: 1,*/}
        {/*            stickyColumns: block.stickyColumns,*/}
        {/*          });*/}
        {/*        },*/}
        {/*        selected: block.stickyRows === 1,*/}
        {/*      },*/}
        {/*      'divider',*/}
        {/*      {*/}
        {/*        label: 'No columns',*/}
        {/*        onClick: () =>*/}
        {/*          onClickFreeze({*/}
        {/*            stickyColumns: undefined,*/}
        {/*            stickyRows: block.stickyRows,*/}
        {/*          }),*/}
        {/*        selected: block.stickyColumns === undefined,*/}
        {/*      },*/}
        {/*      {*/}
        {/*        label: '1 Column',*/}
        {/*        onClick: () => {*/}
        {/*          const hasMergesInColumn = (block.data.merges ?? []).some(*/}
        {/*            (merge) => merge.startColumn === 0 && merge.columnSpan > 1,*/}
        {/*          );*/}
        {/*          if (hasMergesInColumn) {*/}
        {/*            setError(MERGES_IN_FREEZE_ERROR);*/}
        {/*            return;*/}
        {/*          }*/}
        {/*          onClickFreeze({*/}
        {/*            stickyColumns: 1,*/}
        {/*            stickyRows: block.stickyRows,*/}
        {/*          });*/}
        {/*        },*/}
        {/*        selected: block.stickyColumns === 1,*/}
        {/*      },*/}
        {/*    ]}*/}
        {/*  />*/}
        {/*</ToolbarGroup>*/}
        <ToolbarGroup>
          {showSyncButton && (
            <ToolbarButton
              iconName="refresh"
              onMouseDown={(e) => {
                // doing this to prevent changing active element focus
                e.preventDefault();
                e.stopPropagation();
                onClickSync();
              }}
              aria-label="sync table"
            >
              Sync
            </ToolbarButton>
          )}
          {showChartButton && (
            <ToolbarButton
              iconName="aiPencil"
              aria-label="generate a chart with ai"
              onClick={() => {
                setChartMakingTable(true);
                // call the ai service, get the chart and add to the editor
              }}
            >
              Generate Chart
            </ToolbarButton>
          )}
        </ToolbarGroup>
      </Toolbar>
    </>
  );
};

export default TableFormattingMenu;
