import { useRef, useState } from 'react';
import styles from './ViewTopBar.module.scss';
import IconButton from '../../../design-system/atoms/IconButton.tsx';
import TopBarLayout from '../../../design-system/layouts/TopBarLayout.tsx';
import ZeckEditMenu, {
  ZeckEditMenuLinkItem,
} from '../sharedTopBar/ZeckEditMenu.tsx';
import { PublishedZeck, Zeck } from '../../../types.ts';
import useApi from '../../../api/useApi.ts';
import { getSystemTimezone } from '../../../dateTime/timestamp.ts';
import { useSignal } from '../../../signals/useSignal.ts';
import ExportPdfModal, { Pdf } from './ExportPdfModal.tsx';
import { ReactComponent as ZeckLogo } from '../../../images/ZeckBlocksLogoBlack.svg';
import { CommentsState } from './publish/commentsSidebar/useComments/useComments.ts';
import WithTooltip from '../../../design-system/organisms/WithTooltip.tsx';

type ViewTopBarProps = {
  onClickOpenSidebar(e: React.MouseEvent<HTMLButtonElement>): void;
  zeck: Zeck | PublishedZeck;
  children: React.ReactNode;
  showKebab: boolean;
  showComments: boolean;
  commentsState?: CommentsState;
};

const ViewTopBar = ({
  children,
  onClickOpenSidebar,
  zeck,
  showKebab,
  showComments,
  commentsState,
}: ViewTopBarProps) => {
  const { getPublishedZeckPdf } = useApi();
  const pdf$ = useSignal<null | Pdf>(null);

  const kebabButtonRef = useRef<HTMLButtonElement>(null);
  const [showManagementMenu, setShowManagementMenu] = useState(false);

  const getKebabIconButton = () => {
    return showKebab ? (
      <IconButton
        name="kebab"
        ref={kebabButtonRef}
        aria-label="open section management menu"
        onClick={() => {
          setShowManagementMenu(!showManagementMenu);
        }}
      />
    ) : undefined;
  };

  const getCommentIconButton = () => {
    return showComments && !commentsState?.sidebarOpen ? (
      <WithTooltip<HTMLButtonElement> text="Comments">
        {(ref, listeners) => (
          <IconButton
            name="commentFilled"
            aria-label="show comments"
            data-testid="view-top-bar-show-comments"
            onClick={() => {
              commentsState?.openComments();
            }}
            ref={ref}
            {...listeners}
          />
        )}
      </WithTooltip>
    ) : undefined;
  };

  const getRightSlotIconButtons = () => {
    return (
      <span className={styles.rightSlotIcons}>
        {getCommentIconButton()}
        {getKebabIconButton()}
      </span>
    );
  };

  return (
    <>
      <TopBarLayout
        className={styles.sectionPreviewTopBar}
        {...{
          leftSlot: (
            <IconButton
              name="hamburger"
              aria-label="open sidebar"
              onClick={onClickOpenSidebar}
            />
          ),
          rightSlot: getRightSlotIconButtons(),
        }}
      >
        <div className={styles.sectionPreviewTopBar__title}>{children}</div>
      </TopBarLayout>
      {showKebab && (
        <ZeckEditMenu
          className={styles.sectionPreviewTopBar__zeckEditMenu}
          element={kebabButtonRef}
          positionStrategy={(el, childElement) => {
            const childElementWidth =
              childElement.getBoundingClientRect().width;
            const rect = el.getBoundingClientRect();
            return [
              rect.x + rect.width - childElementWidth,
              rect.y + rect.height + 8,
            ];
          }}
          isOpen={showManagementMenu}
          onClose={() => {
            setShowManagementMenu(false);
          }}
        >
          <ZeckEditMenuLinkItem
            {...{
              iconName: 'export',
              label: 'Export to PDF',
              sublabel: 'Save to PDF or Print',
              href: '#',
            }}
            onClick={(event) => {
              if (!('zeckId' in zeck)) {
                return;
              }

              event.preventDefault();
              event.stopPropagation();

              pdf$.set({
                zeck,
                url: getPublishedZeckPdf({
                  zeckId: zeck.zeckId,
                  timeZone: getSystemTimezone(),
                }),
              });
            }}
          />
          <a
            href="https://www.zeck.app/zeck-trust-and-security?utm_source=published_zeck&utm_medium=owned&utm_campaign=secured_by_zeck"
            target="_blank"
            rel="noreferrer"
            className={styles.sectionPreviewTopBar__securedBy}
          >
            <span>Secured by</span>
            <ZeckLogo height={24} />
          </a>
        </ZeckEditMenu>
      )}
      <ExportPdfModal pdf$={pdf$} />
    </>
  );
};
export default ViewTopBar;
