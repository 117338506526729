import React from 'react';
import SelectableDiv from './SelectableDiv.js';
import BlockActions from './BlockActions.js';
import mergeRefs from '../../../../junkDrawer/mergeRefs.js';
import cx from 'classnames';
import Chart from '../../../../design-system/zeck/chart/chart.tsx';
import { ChartBlock } from 'editor-content/ChartBlock.ts';

type ChartEditableWithKeyboardProps = {
  className?: string;
  isInBlockSelection: boolean;
  'data-testid'?: string;
} & BlockActions<ChartBlock>;

export default React.forwardRef<HTMLElement, ChartEditableWithKeyboardProps>(
  function ChartEditableWithKeyboard(
    {
      className,
      block,
      selection,
      onSelect,
      isInBlockSelection,
      'data-testid': dataTestid,
    },
    forwardedRef,
  ) {
    const selectableRef = React.useRef<HTMLDivElement>(null);

    return (
      <SelectableDiv
        square
        onSelect={onSelect}
        className={cx(className)}
        internalSelection={selection}
        isInBlockSelection={isInBlockSelection}
        data-testid={dataTestid}
        ref={mergeRefs([forwardedRef, selectableRef])}
      >
        <Chart
          block={block}
          onClick={() => {
            if (selectableRef.current) {
              selectableRef.current.focus();
            }
          }}
          preview={false}
          showWarning
        />
      </SelectableDiv>
    );
  },
);
