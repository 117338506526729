import { Comment } from 'api-client/types.ts';
import { CommentsStateForSection } from 'app/pages/zeck/previewAndPublish/publish/commentsSidebar/useComments/useComments.ts';
import cx from 'classnames';
import initials from 'initials';
import CommentVisibilityHelp from '../../../../../pages/zeck/previewAndPublish/publish/commentsSidebar/CommentVisibilityHelp.tsx';
import PublishedCommentForm from '../../../../../pages/zeck/previewAndPublish/publish/commentsSidebar/PublishedCommentForm.tsx';
import { isNotPendingUser } from '../../../../../types/User.ts';
import styles from './PublishedSelectionCommentForm.module.scss';
import DetectsOutsideClick from '../../../../../junkDrawer/DetectsOutsideClick.tsx';
import UserInitials from '../../../../../design-system/atoms/UserInitials.tsx';
import useBreakpoints from '../../../../../services/useBreakpoints.ts';

type Props = {
  onClose: () => void;
  selection: Comment['selection'];
  commentsStateForSection?: CommentsStateForSection;
  className?: string;
};

const PublishedSelectionCommentForm = ({
  onClose,
  selection,
  commentsStateForSection: commentsState,
  className,
}: Props) => {
  const { isMobile } = useBreakpoints();

  if (!commentsState) return null;

  const viewers = commentsState.viewers.filter(isNotPendingUser);
  const availableTags =
    viewers?.map((u) => ({
      userId: u.id,
      displayName: `${u.firstName} ${u.lastName}`,
    })) ?? [];

  const placeholder = isMobile
    ? 'Add a comment...'
    : 'Add a comment or question...';

  return (
    <DetectsOutsideClick
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClose();
      }}
    >
      {(ref) => (
        <div
          ref={ref}
          className={cx(
            isMobile
              ? styles.publishedSelectionCommentForm__mobile
              : styles.publishedSelectionCommentForm,
            className,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.publishedSelectionCommentForm__initials}>
            <UserInitials size="medium">
              {initials(commentsState.userName)}
            </UserInitials>
          </div>
          <div className={styles.publishedSelectionCommentForm__form}>
            <PublishedCommentForm
              testId="published-comment-form"
              placeholder={placeholder}
              renderHelp={({ isDirectMessage }) =>
                isDirectMessage ? null : (
                  <CommentVisibilityHelp
                    viewers={commentsState?.viewers ?? []}
                  />
                )
              }
              mini={true}
              availableTags={availableTags}
              onCancel={onClose}
              selection={selection}
              submitText="Comment"
              onSubmit={async function (comment) {
                commentsState?.openSectionComments();
                const newComment = await commentsState.postComment(
                  comment.content,
                  comment.isDirectMessage,
                  comment.selection,
                );
                commentsState.openComment(newComment.id);

                onClose();
              }}
              initialValue={{
                content: [],
                isDirectMessage: false,
              }}
              canToggleDirectMessage={true}
            />
          </div>
        </div>
      )}
    </DetectsOutsideClick>
  );
};

export default PublishedSelectionCommentForm;
