import { atom, useAtomValue } from 'jotai';
import { CompanyLite } from '../types.ts';

// create an activeCompany jotai atom
export const activeCompanyAtom = atom<CompanyLite | undefined>(undefined);

export const useActiveCompany: () => CompanyLite = () => {
  const activeCompany = useAtomValue(activeCompanyAtom);
  if (!activeCompany) {
    throw new Error('Active company not found');
  }
  return activeCompany;
};
