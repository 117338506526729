import { createParagraphBlock } from 'editor-content/Block.js';
import AddBlockEditorActions, {
  AddBlockBlockEditorInterface,
} from '../../../../editor/addBlock/AddBlockEditorActions.js';
import generateZeckBlockEditorAddBlock from '../../../../editor/addBlock/zeck/generateZeckBlockEditorAddBlock.js';
import { EditorConfiguration } from '../../../../editor/EditorAction.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import { EditorContent } from '../../edit/useEditorState.js';
import BodyEditor from '../BodyEditor/BodyEditor.js';
import EditorData from '../EditorData.js';
import HeadlineEditor from '../HeadlineEditor/HeadlineEditor.js';
import pressEnterZeck from '../pressEnter/pressEnterZeck.js';
import pressForwardSlash from '../pressForwardSlash.ts';
import TitleEditor from '../TitleEditor/TitleEditor.js';
import ZeckEditorSelection, {
  isBodySelection,
} from '../ZeckEditorSelection.js';
import { convertToStateVoid } from './convertToStateVoid.js';
import { navLeft, navRight } from './KeyboardNavigationActions.js';
import loseFocus from './loseFocus.js';
import pressBackspace from './pressBackspace.js';
import {
  nonSelectionBasedBodyAction,
  nonSelectionBasedHeadlineAction,
  nonSelectionBasedTitleAction,
} from './nonSelectionBasedAction.js';
import {
  selectionBasedAction,
  selectionBasedActionWithEffect,
} from './selectionBasedAction.ts';
import { pressArrowLeft } from './pressArrowLeft.js';
import { pressArrowRight } from './pressArrowRight.js';
import { pressArrowUpNonTextBlock } from './pressArrowUpNonTextBlock.js';
import { pressArrowDownNonTextBlock } from './pressArrowDownNonTextBlock.js';

const editorConfiguration: EditorConfiguration<
  HydratedBlock,
  AddBlockBlockEditorInterface
> = {
  generateBlockEditor: generateZeckBlockEditorAddBlock,
  createDefaultBlock: createParagraphBlock,
};

// ZeckEditor: this object contains all the ZeckEditor actions, the actions are routed via selectionBasedAction
// which takes as its argument n object with handlers for actions emited from title, body and headline
const ZeckEditor = {
  editTitle: selectionBasedAction({ title: TitleEditor.edit }),
  editHeadline: selectionBasedAction({ headline: HeadlineEditor.edit }),
  editBlock: selectionBasedAction({ body: BodyEditor.editBlock }),

  selectTitle: nonSelectionBasedTitleAction(
    convertToStateVoid(TitleEditor.select),
  ),
  selectHeadline: nonSelectionBasedHeadlineAction(
    convertToStateVoid(HeadlineEditor.select),
  ),
  selectBody: nonSelectionBasedBodyAction(
    convertToStateVoid(BodyEditor.select),
  ),

  navLeft,
  navRight,

  selectUp: selectionBasedAction({
    body: BodyEditor.selectUp,
  }),
  selectDown: selectionBasedAction({
    body: BodyEditor.selectDown,
  }),

  pressArrowUp: pressArrowUpNonTextBlock,
  pressArrowDown: pressArrowDownNonTextBlock,
  pressArrowLeft,
  pressArrowRight,

  pressBackspace,

  pressDelete: selectionBasedAction({
    body: BodyEditor.pressDelete,
  }),

  pressEnter: pressEnterZeck,

  pressForwardSlash,

  pressSpacebar: selectionBasedAction({
    body: BodyEditor.pressSpacebar,
  }),

  pastePlaintext: selectionBasedAction({
    body: BodyEditor.pastePlaintext,
  }),

  pasteBlocks: selectionBasedAction({
    body: BodyEditor.pasteBlocks,
  }),

  pasteText: selectionBasedAction({
    body: BodyEditor.pasteText,
  }),

  pasteImage: selectionBasedAction({
    body: BodyEditor.pasteImage,
  }),

  cut: selectionBasedActionWithEffect({
    body: BodyEditor.cut,
  }),

  copy(
    content: EditorContent,
    selection: ZeckEditorSelection,
  ): EditorData<HydratedBlock> | void {
    if (isBodySelection(selection)) {
      return BodyEditor.copy({
        content: content.body,
        selection,
      });
    }
  },

  loseFocus,

  addHighlight: selectionBasedAction({
    headline: HeadlineEditor.addHighlight,
    body: BodyEditor.addHighlight,
  }),
  toggleFormat: selectionBasedAction({
    headline: HeadlineEditor.toggleFormat,
    body: BodyEditor.toggleFormat,
  }),
  addLink: selectionBasedAction({
    headline: HeadlineEditor.addLink,
    body: BodyEditor.addLink,
  }),

  setImageWidth: selectionBasedAction({ body: BodyEditor.setImageWidth }),
  setImageAlign: selectionBasedAction({ body: BodyEditor.setImageAlign }),
  replaceImage: selectionBasedAction({ body: BodyEditor.replaceImage }),
  replaceFile: selectionBasedAction({ body: BodyEditor.replaceFile }),
  replaceTable: selectionBasedAction({ body: BodyEditor.replaceTable }),
  turnInto: selectionBasedAction({ body: BodyEditor.turnInto }),
  indent: selectionBasedAction({ body: BodyEditor.indent }),

  addBlockAtEnd: nonSelectionBasedBodyAction(
    convertToStateVoid(BodyEditor.addBlockAtEnd),
  ),
  insertAiContentAbove: nonSelectionBasedBodyAction(
    convertToStateVoid(BodyEditor.insertAiContentAbove),
  ),

  AddBlock: {
    addNewBlock: nonSelectionBasedBodyAction(
      AddBlockEditorActions.addNewBlock(editorConfiguration),
    ),
    replaceNewBlock: nonSelectionBasedBodyAction(
      AddBlockEditorActions.replaceNewBlock(editorConfiguration),
    ),
  },

  dropDraggedBlock: nonSelectionBasedBodyAction(
    convertToStateVoid(BodyEditor.dropDraggedBlock),
  ),
};

export default ZeckEditor;
