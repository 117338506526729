import { z } from 'zod';
import { baseBlock, createBlock } from './BaseBlock.js';
import { Block } from './Block.js';

export const chartBlockLineData = z.object({
  version: z.literal(1),
  type: z.literal('line'),
  lineAxis: z.union([z.literal('x'), z.literal('y')]).optional(),
  title: z.object({
    text: z.string(),
    position: z.union([
      z.literal('top'),
      z.literal('left'),
      z.literal('right'),
      z.literal('bottom'),
    ]),
  }),
  subtitle: z.object({
    text: z.string(),
  }),
  legend: z.object({
    display: z.boolean(),
    position: z.union([
      z.literal('top'),
      z.literal('left'),
      z.literal('right'),
      z.literal('bottom'),
    ]),
  }),
  scales: z.object({
    x: z.object({
      title: z.object({
        text: z.string(),
      }),
      position: z.union([z.literal('top'), z.literal('bottom')]),
      type: z.union([
        z.literal('category'),
        z.literal('linear'),
        z.literal('logarithmic'),
      ]),
      min: z.union([z.number(), z.null()]),
    }),
    y: z.object({
      title: z.object({
        text: z.string(),
      }),
      position: z.union([z.literal('left'), z.literal('right')]),
      type: z.union([
        z.literal('category'),
        z.literal('linear'),
        z.literal('logarithmic'),
      ]),
      min: z.union([z.number(), z.null()]),
    }),
  }),
  labels: z.array(z.string()),
  datasets: z.array(
    z.object({
      label: z.string(),
      data: z.array(z.number()),
      backgroundColor: z.union([z.string(), z.array(z.string())]),
      borderColor: z.union([z.string(), z.array(z.string())]),
      borderWidth: z.number(),
      order: z.number(),
    }),
  ),
});

export const chartBlockBarData = z.object({
  version: z.literal(1),
  type: z.literal('bar'),
  indexAxis: z.union([z.literal('x'), z.literal('y')]),
  title: z.object({
    text: z.string(),
    position: z.union([
      z.literal('top'),
      z.literal('left'),
      z.literal('right'),
      z.literal('bottom'),
    ]),
  }),
  subtitle: z.object({
    text: z.string(),
  }),
  legend: z.object({
    display: z.boolean(),
    position: z.union([
      z.literal('top'),
      z.literal('left'),
      z.literal('right'),
      z.literal('bottom'),
    ]),
  }),
  scales: z.object({
    x: z.object({
      title: z.object({
        text: z.string(),
      }),
      position: z.union([z.literal('top'), z.literal('bottom')]),
      type: z.union([
        z.literal('category'),
        z.literal('linear'),
        z.literal('logarithmic'),
      ]),
      min: z.union([z.number(), z.null()]),
      stacked: z.boolean(),
    }),
    y: z.object({
      title: z.object({
        text: z.string(),
      }),
      position: z.union([z.literal('left'), z.literal('right')]),
      type: z.union([
        z.literal('category'),
        z.literal('linear'),
        z.literal('logarithmic'),
      ]),
      min: z.union([z.number(), z.null()]),
      stacked: z.boolean(),
    }),
  }),
  labels: z.array(z.string()),
  datasets: z.array(
    z.object({
      label: z.string(),
      data: z.array(z.number()),
      backgroundColor: z.union([z.string(), z.array(z.string())]),
      borderColor: z.union([z.string(), z.array(z.string())]),
      borderWidth: z.number(),
      order: z.number(),
    }),
  ),
});

export const chartBlockPieData = z.object({
  version: z.literal(1),
  type: z.literal('pie'),
  cutout: z.string(),
  title: z.object({
    text: z.string(),
    position: z.union([
      z.literal('top'),
      z.literal('left'),
      z.literal('right'),
      z.literal('bottom'),
    ]),
  }),
  subtitle: z.object({
    text: z.string(),
  }),
  legend: z.object({
    display: z.boolean(),
    position: z.union([
      z.literal('top'),
      z.literal('left'),
      z.literal('right'),
      z.literal('bottom'),
    ]),
  }),
  labels: z.array(z.string()),
  datasets: z.array(
    z.object({
      label: z.string(),
      data: z.array(z.number()),
      backgroundColor: z.union([z.string(), z.array(z.string())]),
      borderWidth: z.number(),
      order: z.number(),
    }),
  ),
});

export type ChartBlockPieData = z.infer<typeof chartBlockPieData>;
export type ChartBlockLineData = z.infer<typeof chartBlockLineData>;
export type ChartBlockBarData = z.infer<typeof chartBlockBarData>;

// add type guards here
export const isChartBlockLineData = (
  data: ChartBlockData,
): data is ChartBlockLineData => data.type === 'line';

export const isChartBlockBarData = (
  data: ChartBlockData,
): data is ChartBlockBarData => data.type === 'bar';

export const isChartBlockPieData = (
  data: ChartBlockData,
): data is ChartBlockPieData => data.type === 'pie';

export type ChartBlockData =
  | ChartBlockLineData
  | ChartBlockBarData
  | ChartBlockPieData;

export const ChartBlockDataSchema = z.union([
  chartBlockLineData,
  chartBlockBarData,
  chartBlockPieData,
]);

export const isChartBlock = (block: Block): block is ChartBlock =>
  block.type === 'chart';

export type ChartBlockSourceData = Array<Array<string | number>>;

export type ChartBlock = {
  id: string;
  type: 'chart';
  guid: string;
  data: {
    chartData: ChartBlockData;
    sourceData: ChartBlockSourceData;
  };
};

export const ChartBlock = (
  id: string,
  guid: string,
  chartData: ChartBlockData,
  sourceData: ChartBlockSourceData,
): ChartBlock =>
  baseBlock('chart', id, {
    guid,
    data: {
      chartData,
      sourceData,
    },
  });

export const createChartBlock = (
  guid: string,
  chartData: ChartBlockData,
  sourceData: ChartBlockSourceData,
): ChartBlock =>
  createBlock('chart', {
    guid, // must be globally unique, used for artifacts
    data: {
      chartData,
      sourceData,
    },
  });

export type AssetContainingBlock = ChartBlock;

export const isAssetContainingBlock = (
  block: Block,
): block is AssetContainingBlock => isChartBlock(block);
