import { ImageBlock } from 'editor-content/Block.js';

export const canAlignImage = (
  block: Pick<ImageBlock, 'dimensions'>,
): boolean => {
  // 700 max width of column
  return !!block.dimensions?.width && block.dimensions.width < 700;
};

export const canAlignBlockImage = (block: ImageBlock): boolean => {
  return canAlignImage(block) && block.width === 'column';
};
