import { TextBlock } from 'editor-content/Block.ts';
import { removeHighlights } from './removeHighlights.js';
import { TextNode } from 'editor-content/TextNode.ts';
import addHighlightToTextNodes from '../../editor/BodyEditor/addHighlightToTextNodes.js';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.js';

const applyBlockHighlights = <B extends TextBlock>(
  block: B,
  updatedHighlights: {
    commentId: string;
    contentSelection: ContentSelection;
  }[],
): TextNode[] => {
  let updatedContent: TextNode[] = removeHighlights(block.content);

  if (!updatedHighlights.length) {
    return updatedContent;
  }

  updatedHighlights.forEach((highlight) => {
    updatedContent = addHighlightToTextNodes(
      updatedContent,
      highlight.contentSelection,
      highlight.commentId,
    );
  });

  return updatedContent;
};

export default applyBlockHighlights;
