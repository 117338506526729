import { AnimatePresence } from 'framer-motion';
import ButtonWithIcon from '../../../../../../design-system/atoms/ButtonWithIcon.js';
import { isNotPendingUser } from '../../../../../../types/User.js';
import CommentMotion from '../CommentMotion.tsx';
import { CommentCelebrationWrapper } from '../CommentCelebrationWrapper.tsx';
import { CommentsStateForSection } from '../useComments/useComments.js';
import NewCommentForm from './../NewCommentForm.js';
import styles from './CommentsSection.module.scss';

export type CommentsProps = {
  className?: string;
  state: CommentsStateForSection;
};

export function CommentsSection({
  state: {
    userName,
    viewers,
    filteredCommentsWithActions: comments,
    postComment,
    section,
    newComment,
    refs,
    selectedComment,
    isCommentToCelebrate,
    scrollSectionIntoViewIfNeeded,
  },
}: CommentsProps) {
  const availableViewerTags = viewers.filter(isNotPendingUser).map((u) => ({
    userId: u.id,
    displayName: `${u.firstName} ${u.lastName}`,
  }));

  return (
    <div ref={refs.setCommentSectionRef} data-testid="comments-section">
      <CommentMotion className={styles.commentsSection__header}>
        <span
          className={styles.commentsSection__sectionTitle}
          onClick={() => scrollSectionIntoViewIfNeeded()}
        >
          {section.title}
        </span>
        {newComment.canAddNewComment && (
          <ButtonWithIcon
            className={styles.commentsSection__addComment}
            iconName="addComment"
            position="left"
            size="medium"
            color="transparent"
            onClick={newComment.openNewComment}
          >
            Add Comment
          </ButtonWithIcon>
        )}
      </CommentMotion>
      <div className={styles.commentsSection__commentsList}>
        <AnimatePresence initial={false}>
          {newComment.canAddNewComment && newComment.isNewCommentFormOpen && (
            <CommentMotion>
              <NewCommentForm
                userName={userName}
                viewers={viewers}
                onCancel={newComment.cancelNewComment}
                onSubmit={(comment) =>
                  postComment(comment.content, comment.type === 'DirectMessage')
                }
              />
            </CommentMotion>
          )}
          {comments.map((comment) => {
            const viewersForComment =
              comment.type === 'DirectMessage'
                ? availableViewerTags.filter((viewer) =>
                    comment.viewers.includes(viewer.userId),
                  )
                : availableViewerTags;

            return (
              <CommentCelebrationWrapper
                key={comment.id}
                comment={comment}
                viewersForComment={viewersForComment}
                setCommentElementRef={refs.setCommentElementRef}
                selectedCommentThreadId={selectedComment.getSelectedCommentThreadId()}
                selectCommentThread={selectedComment.selectCommentThread}
                isCommentToCelebrate={isCommentToCelebrate(comment.id)}
              />
            );
          })}
          {comments.length === 0 && !newComment.isNewCommentFormOpen && (
            <CommentMotion key="no-comments">
              <div className={styles.commentsSection__empty}>No comments.</div>
            </CommentMotion>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
