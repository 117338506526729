import cx from 'classnames';
import styles from './Comments.module.scss';
import { Icon } from 'icons';
import { CommentsStateForSection } from './useComments/useComments.js';

export function CommentsButton({
  className,
  state: { activeCommentCount: commentCount, openSectionComments },
}: {
  className?: string;
  state: CommentsStateForSection;
}) {
  return (
    <button
      aria-label="Show Comments"
      data-testid="show-comments"
      type="button"
      onClick={() => openSectionComments()}
      className={cx(styles.comments__button, className)}
    >
      <Icon name="comment" className={styles.comments__icon} />

      <span className={styles.comments__count}>
        {commentCount} {commentCount === 1 ? 'Comment' : 'Comments'}
      </span>
    </button>
  );
}
