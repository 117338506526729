import { Block, ChartBlockData } from 'editor-content/Block.ts';
import { makeMakeApiRequest, RequestError } from '../makeMakeApiRequest.js';
import { EditorContent } from '../../pages/zeck/edit/useEditorState.js';
import {
  AiChartGenerateDTO,
  AiChartGenerateErrorType,
  isAiChartGenerateErrorResponse,
} from 'api-client/aiContract.ts';
import AiApiClient from './AiApiClient.ts';
import { ChartJsSchema } from 'editor-content/lib/chartBlockConversions.ts';
export type AiOptimizeSectionSuccessResponse = {
  data?: {
    body?: Block[];
    metadata: {
      jobId: string;
    };
  };
};

export type AiOptimizeSectionErrorResponse = {
  error: string;
};

type AiOptimizeSectionResponse =
  | AiOptimizeSectionSuccessResponse
  | AiOptimizeSectionErrorResponse;

export const isAiOptimizeSectionErrorResponse = (
  response: AiOptimizeSectionResponse,
): response is AiOptimizeSectionErrorResponse =>
  response.hasOwnProperty('error');

export type AiChartGenerateResponseErrorType =
  | AiChartGenerateErrorType
  | 'timeout';

export type AiChartGenerateResponseError = {
  errorType: AiChartGenerateResponseErrorType;
};

export type AiChartGenerateResponse =
  | {
      chart: ChartBlockData;
      lastSchema: ChartJsSchema;
      pastMessages: string[];
    }
  | AiChartGenerateResponseError;

export const isAiChartGenerateResponseError = (
  response: AiChartGenerateResponse,
): response is AiChartGenerateResponseError => 'errorType' in response;

const MAX_RETRIES = 600;

// low effort backoff. Network delay already built in to polling, but want to have low latency for common AI response times
const getSleepDuration = (count: number) => {
  if (count < 200) {
    return 100;
  }

  if (count < 300) {
    return 500;
  }

  return 1000;
};

const createAiApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  aiApiClient: typeof AiApiClient,
  accessToken: string | null,
) => ({
  aiOptimizeSection: async (
    sectionId: string,
    editorContent: EditorContent,
  ): Promise<AiOptimizeSectionResponse> => {
    const jobResponse = await makeLambdaApiRequest(
      `/section/${sectionId}/ai-optimize`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ section: editorContent }),
      },
    );

    const s3Url = jobResponse.jobUrl;

    let s3JobResponse = null;
    while (!s3JobResponse) {
      await sleep(1000);
      const response = await fetch(s3Url);
      if (response.status !== 404) {
        s3JobResponse = await response.json();
      }
    }

    // todo a codec or something
    return s3JobResponse;
  },

  aiChartGenerate: async (
    payload: AiChartGenerateDTO,
  ): Promise<AiChartGenerateResponse> => {
    const jobResponse = await aiApiClient.createChartJob({
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
      body: payload,
    });

    switch (jobResponse.status) {
      case 200: {
        if (isAiChartGenerateErrorResponse(jobResponse.body)) {
          return jobResponse.body.errorBody;
        }

        const s3Url = jobResponse.body.jobUrl;

        let s3JobResponse: null | {
          data: {
            chart: ChartBlockData;
            lastSchema: ChartJsSchema;
            pastMessages: string[];
          };
        } = null;
        for (let count = 0; count < MAX_RETRIES; count++) {
          const sleepDuration = getSleepDuration(count);
          await sleep(sleepDuration);
          const response = await fetch(s3Url);
          if (response.status !== 404) {
            s3JobResponse = await response.json();
            break;
          }
        }

        if (!s3JobResponse) {
          return { errorType: 'timeout' as const };
        }

        if (!s3JobResponse?.data?.chart) {
          return { errorType: 'unknown' as const };
        }

        // todo a codec or something
        return {
          chart: s3JobResponse.data.chart,
          lastSchema: s3JobResponse.data.lastSchema,
          pastMessages: s3JobResponse.data.pastMessages,
        };
      }
      // adapting from ts-rest returning error codes
      // to our code that expects thrown errors
      default: {
        throw new RequestError(
          'aiApiClient.createChartJob',
          '',
          jobResponse.status,
          jobResponse,
        );
      }
    }
  },
});

export default createAiApi;

async function sleep(time: number) {
  await new Promise((resolve) => setTimeout(resolve, time));
}
