import React from 'react';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import useTextBlockKeyboardEvents from '../selection/useTextBlockKeyboardEvents.ts';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import styles from './BlockWithHint.module.scss';
import cx from 'classnames';
import { usePlaintextEditableWithSelection } from '../usePlaintextEditable.js';
import Title from '../../../../design-system/zeck/Title.js';
import { getSafePlaintextFromClipboardData } from '../../../../junkDrawer/getSafePlaintextFromClipboardData.js';

type TitleEditableWithSelectionProps = {
  className?: string;
  onSelect: (selection: ContentSelection) => void;
  selection: ContentSelection | null;
  value: string;
  onChange(newValue: string, selection: ContentSelection): void;
  onNavUp(): void;
  onNavDown(): void;
};

const TitleEditableWithSelection = React.forwardRef<
  HTMLHeadingElement,
  TitleEditableWithSelectionProps
>(function TitleEditableWithSelection(
  {
    onSelect,
    selection,
    value,
    onChange,
    onNavUp,
    onNavDown,
    className,
    ...otherProps
  },
  forwardedRef,
) {
  const contentEditableProps = usePlaintextEditableWithSelection(
    { content: value, selection },
    (newValue) => {
      onChange(newValue.content, newValue.selection);
    },
    (contentSelection) => contentSelection && onSelect(contentSelection),
  );

  const { handleKeyDown, ref: keyboardEventRef } =
    useTextBlockKeyboardEvents<HTMLHeadingElement>({
      onArrowUpOut() {
        onNavUp();
      },
      onArrowDownOut() {
        onNavDown();
      },
      onSelectOut() {
        //
      },
    });

  return (
    <Title
      {...{
        ...otherProps,
        ...contentEditableProps,
        onCopy: (e) => {
          e.stopPropagation();
        },
        onCut: (e) => {
          e.stopPropagation();
        },
        onPaste: (e) => {
          e.preventDefault();
          e.stopPropagation();

          const text = getSafePlaintextFromClipboardData(e.clipboardData);
          const strings = text.split('\n');
          document.execCommand('insertText', false, strings.join(' '));
        },
        ref: mergeRefs([
          contentEditableProps.ref,
          keyboardEventRef,
          forwardedRef,
        ]),
        onKeyDown: handleKeyDown,
        className: cx(
          className,
          styles.blockWithHint,
          !!selection && styles.blockWithHint_selected,
        ),
        'data-block-type': 'Title',
      }}
    />
  );
});

export default TitleEditableWithSelection;
