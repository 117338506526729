import { ChartBlock } from 'editor-content/ChartBlock.ts';
import { TableBlock } from 'editor-content/TableBlock.js';
import React from 'react';
import TableFromBlock from '../../../../design-system/zeck/TableFromBlock.tsx';
import {
  TableScrollShadowContainer,
  WideWidthBlockContainer,
} from '../../../../design-system/zeck/TableView.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import { AiChartFlow } from '../AiChartFlow/AiChartFlow.tsx';
import { AiContext } from '../AiChartFlow/createAiContext.ts';
import { useGenerateAiChartFlowProps } from '../AiChartFlow/useGenerateAiChartFlowProps.tsx';
import BlockActions from './BlockActions.ts';
import SelectableDiv from './SelectableDiv.tsx';
import { useAtom } from 'jotai';

type TableEditableWithKeyboardProps = {
  className?: string;
  isInBlockSelection: boolean;
  'data-testid'?: string;
  onInsertChartBlock: (chart: ChartBlock) => void;
  aiContext: AiContext;
} & BlockActions<TableBlock>;

export default React.forwardRef<HTMLElement, TableEditableWithKeyboardProps>(
  function TableEditableWithKeyboard(
    {
      className,
      block,
      selection,
      onSelect,
      isInBlockSelection,
      'data-testid': dataTestid,
      onInsertChartBlock,
      aiContext,
    },
    forwardedRef,
  ) {
    const [addingChart, setAddingChart] = useAtom(
      aiContext.getChartMakingFamily(block),
    );

    const { forceTableSelection, isChartWizardVisible, ...aiChartFlowProps } =
      useGenerateAiChartFlowProps({
        setAddingChart,
        onInsertChartBlock,
        tableBlock: block,
        selection,
        aiContext,
      });

    return (
      <WideWidthBlockContainer>
        <SelectableDiv
          square
          className={className}
          onSelect={onSelect}
          internalSelection={selection}
          isInBlockSelection={isInBlockSelection || forceTableSelection}
          data-testid={dataTestid}
          ref={mergeRefs([forwardedRef])}
        >
          <TableScrollShadowContainer>
            <TableFromBlock block={block} />
          </TableScrollShadowContainer>
        </SelectableDiv>
        {addingChart && isChartWizardVisible && (
          <AiChartFlow {...aiChartFlowProps} />
        )}
      </WideWidthBlockContainer>
    );
  },
);
