import { TableBlock, compressTableBlock } from 'editor-content/TableBlock.js';
import { useEffect, useRef, useState } from 'react';
import {
  CredentialResponse,
  ErrorCredentialResponse,
  ValidCredentialResponse,
} from '../../../../api/endpoints/createCredentialApi.ts';
import {
  Integration,
  IntegrationListItem,
  IntegrationListItemData,
} from '../../../../api/endpoints/createIntegrationApi.ts';
import useApi from '../../../../api/useApi.ts';
import { ModalContainer } from '../../../../design-system/organisms/Modal.tsx';

import { ZeckCommunicationLoader } from '../addBlock/integrationsModal/IntegrationLoader.tsx';
import { getArrayBufferOfOneDriveItem } from '../addBlock/integrationsModal/utils/excel/getArrayBufferOfOneDriveItem.ts';
import { createExcelWorkbookData } from '../addBlock/integrationsModal/utils/excel/createExcelWorkbookData.ts';
import createTableFromExcelWorksheet, {
  getDefinedNameData,
} from '../addBlock/integrationsModal/utils/excel/createTableFromExcelWorksheet.ts';
import {
  ExcelIntegrationUIState,
  MicrosoftIntegrationFlow,
} from '../addBlock/integrationsModal/microsoft/MicrosoftIntegrationsModalFlow.tsx';
import { logError } from '../../../../logging/logger.ts';
import {
  MicrosoftErrorState,
  MicrosoftSyncingState,
} from './InteractiveState.ts';
import useTableSyncStickyMergeCheck from '../addBlock/integrationsModal/utils/useTableSyncStickyMergeCheck.ts';

type MicrosoftTableSyncFlowProps = {
  block: TableBlock;
  getEl: () => HTMLElement | undefined;
  onReplaceTable: (block: TableBlock) => void;
  integrationData: IntegrationListItemData;
  onSyncComplete: () => void;
  integration: IntegrationListItem;
  showToast: (message: string, duration: number) => void;
};

const MicrosoftTableSyncFlow: React.FC<MicrosoftTableSyncFlowProps> = ({
  block,
  integrationData,
  onReplaceTable,
  onSyncComplete,
  integration,
  showToast,
}) => {
  const initialized = useRef(false);
  const [interactiveState, setInteractiveState] =
    useState<MicrosoftSyncingState>({
      type: 'syncing',
    });

  const { updateIntegration, fetchCredentialResponse: fetchCredential } =
    useApi();

  // using this key as a way to force a reset of the state in the microsoft error flow
  const [microsoftUIKey, setMicrosoftUIKey] = useState(0);

  const { updateIntegration: updateClientIntegration } = integrationData;

  useEffect(() => {
    (async () => {
      // fetch credential for integration with token
      if (!initialized.current) {
        initialized.current = true;
        const credentials = await fetchCredential(integration.credentialId);
        handleCredentialResponse(credentials, integration);
      }
    })();
  });

  const { getStickyValuesAndMaybeSetError } = useTableSyncStickyMergeCheck();

  const showMicrosoftError = (errorState: MicrosoftErrorState) => {
    setInteractiveState(errorState);
    setMicrosoftUIKey((k) => k + 1); // force reset of microsoft error flow state
  };

  async function updateTableWithMicrosoftCredential(
    credential: ValidCredentialResponse,
    integration: IntegrationListItem,
  ) {
    try {
      const fileWithModified = await getArrayBufferOfOneDriveItem(
        credential.accessToken,
        integration.providerMeta.documentId,
        integration.providerMeta.driveId,
      );

      const workbookData = await createExcelWorkbookData(fileWithModified, {
        documentId: integration.providerMeta.documentId,
        documentName: integration.providerMeta.documentName,
      });

      const range =
        integration.providerMeta.selectionType === 'range'
          ? getDefinedNameData(
              workbookData.workbook,
              integration.providerMeta.selectionName,
            )
          : undefined;

      const worksheet = workbookData.workbook.getWorksheet(
        range ? range.sheetName : integration.providerMeta.selectionName,
      );

      const updatedTableBlock = createTableFromExcelWorksheet(
        worksheet,
        workbookData.valueTypes,
        range?.range,
      );

      onReplaceTable(
        compressTableBlock({
          ...TableBlock(block.id, updatedTableBlock, block.integrationId),
          ...getStickyValuesAndMaybeSetError(block, updatedTableBlock),
        }),
      );

      onSyncComplete();

      if (workbookData.documentMeta.lastModified) {
        showToast(
          `Synced to OneDrive file last modified at ${workbookData.documentMeta.lastModified.toLocaleString()}`,
          8000,
        );
      }
    } catch (e) {
      logError(e as Error);

      showMicrosoftError({
        type: 'error',
        provider: 'microsoft',
        credential: credential,
        errorType: 'documentNotFound',
      });
    }
  }

  const handleCredentialResponse = async (
    credential: CredentialResponse,
    integration: IntegrationListItem,
  ) => {
    if (credential.status == 'error') {
      showMicrosoftError({
        type: 'error',
        provider: 'microsoft',
        credential,
        errorType: 'credentialError',
      });
      return;
    }

    await updateTableWithMicrosoftCredential(credential, integration);
  };

  return (
    <>
      <ModalContainer isOpen>
        {interactiveState.type === 'syncing' && <ZeckCommunicationLoader />}
        {/* could eventually move this to the google integration flow */}
        {interactiveState.type === 'error' &&
          interactiveState.provider == 'microsoft' && (
            <MicrosoftErrorFlow
              key={microsoftUIKey}
              onClose={onSyncComplete}
              handleIntegrationData={async (data) => {
                const returnedIntegration = await updateIntegration({
                  ...integration,
                  providerMeta: data.providerMeta,
                });

                updateClientIntegration({
                  ...returnedIntegration,
                  userId: integration.userId,
                });

                return returnedIntegration;
              }}
              addIntegration={() => {}}
              onCreateTable={(tableBlock) => {
                onReplaceTable(compressTableBlock(tableBlock));

                onSyncComplete();
              }}
              initialState={
                interactiveState.errorType === 'documentNotFound'
                  ? {
                      type: 'DOCUMENT_NOT_FOUND',
                      integrationType: 'excelOnedrive',
                      accessToken: interactiveState.credential.accessToken,
                      credentialId: integration.credentialId,
                    }
                  : {
                      type: 'SELECT_CREDENTIAL',
                      integrationType: 'excelOnedrive',
                      errorCredential: interactiveState.credential,
                    }
              }
              onReconnected={async (credential) => {
                setInteractiveState({ type: 'syncing' });
                await handleCredentialResponse(credential, integration);
              }}
            />
          )}
      </ModalContainer>
    </>
  );
};

export default MicrosoftTableSyncFlow;

type MicrosoftErrorFlowProps = {
  onClose: () => void;
  handleIntegrationData: (
    integrationData: Omit<Integration, 'id'>,
  ) => Promise<IntegrationListItem>;
  addIntegration: (integration: IntegrationListItem) => void;
  onCreateTable: (table: TableBlock) => void;
  initialState: ExcelIntegrationUIState;
  onReconnected: (
    credential: ValidCredentialResponse | ErrorCredentialResponse,
  ) => void;
};

const MicrosoftErrorFlow = ({
  initialState,
  onClose,
  onReconnected,
  handleIntegrationData,
  addIntegration,
  onCreateTable,
}: MicrosoftErrorFlowProps) => {
  const [uiState, setUIState] = useState<ExcelIntegrationUIState>(initialState);

  return (
    <MicrosoftIntegrationFlow
      uiState={uiState}
      setUIState={setUIState}
      onClose={onClose}
      onReconnected={onReconnected}
      handleIntegrationData={handleIntegrationData}
      addIntegration={addIntegration}
      onCreateTable={onCreateTable}
    />
  );
};
