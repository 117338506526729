import React, { PropsWithChildren } from 'react';
import styles from './UserInitials.module.scss';
import cx from 'classnames';

type UserInitialsProps = {
  className?: string;
  size: 'small' | 'medium' | 'large';
};

function getSizeClassName(size: 'small' | 'medium' | 'large') {
  switch (size) {
    case 'small':
      return styles.userInitials_small;
    case 'medium':
      return styles.userInitials_medium;
    case 'large':
      return styles.userInitials_large;
  }
}

const UserInitials = React.forwardRef<
  HTMLElement,
  PropsWithChildren<UserInitialsProps>
>(({ children, size, className }, forwardedRef) => {
  return (
    <figure
      ref={forwardedRef}
      className={cx(styles.userInitials, getSizeClassName(size), className)}
    >
      {children}
    </figure>
  );
});
export default UserInitials;
