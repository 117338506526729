import { ValueType, Workbook } from 'exceljs';
import {
  Integration,
  IntegrationListItem,
} from '../../../../../../api/endpoints/createIntegrationApi.ts';
import { TableBlock, createTableBlock } from 'editor-content/TableBlock.ts';
import { ZeckCommunicationLoader } from '../IntegrationLoader.tsx';
import MicrosoftDocumentPicker from './MicrosoftDocumentPicker.tsx';
import MicrosoftExcelSheetPicker from './MicrosoftExcelSheetPicker.tsx';
import MicrosoftDocumentNotFound from './MicrosoftDocumentNotFound.tsx';
import { DocumentMeta } from '../integrationModalTypes.ts';
import PickCredential from '../PickCredential.tsx';
import {
  ErrorCredentialResponse,
  ValidCredentialResponse,
} from '../../../../../../api/endpoints/createCredentialApi.ts';
import MicrosoftExcelUploadPanel from '../MicrosoftExcelUploadPanel.tsx';

export type ExcelIntegrationUIState =
  | {
      type: 'SELECT_CREDENTIAL';
      integrationType: 'excelOnedrive';
      errorCredential?: ErrorCredentialResponse;
    }
  | {
      type: 'UPLOAD_DOCUMENT';
      integrationType: 'excelUpload';
    }
  | {
      type: 'DOCUMENT_NOT_FOUND';
      integrationType: 'excelOnedrive';
      credentialId: string;
      accessToken: string;
    }
  | {
      type: 'PICKING_DOCUMENT';
      credentialId: string;
      accessToken: string;
      sheetError: SheetError | null;
      integrationType: 'excelOnedrive';
    }
  | {
      type: 'LOADING_DOCUMENT';
      integrationType: 'excelOnedrive' | 'excelUpload';
    }
  | {
      type: 'PICKING_SHEET';
      integrationType: 'excelUpload';
      workbook: Workbook;
      documentMeta: DocumentMeta;
      valueTypes: typeof ValueType;
    }
  | {
      type: 'PICKING_SHEET';
      integrationType: 'excelOnedrive';
      workbook: Workbook;
      documentMeta: DocumentMeta;
      valueTypes: typeof ValueType;
      credentialId: string;
    };

type SheetError = 'COULD_NOT_GET_SHEET_DATA';

type MicrosoftIntegrationFlowProps = {
  uiState: ExcelIntegrationUIState;
  setUIState: (uiState: ExcelIntegrationUIState) => void;
  onClose: () => void;
  handleIntegrationData: (
    integrationData: Omit<Integration, 'id'>,
  ) => Promise<IntegrationListItem>;
  addIntegration: (integration: IntegrationListItem) => void;
  onCreateTable: (table: TableBlock) => void;
  onReconnected?: (
    credential: ErrorCredentialResponse | ValidCredentialResponse,
  ) => void;
};

export const MicrosoftIntegrationFlow = ({
  uiState,
  onClose,
  setUIState,
  handleIntegrationData,
  onCreateTable,
  addIntegration,
  onReconnected,
}: MicrosoftIntegrationFlowProps) => {
  switch (uiState.type) {
    case 'SELECT_CREDENTIAL': {
      return (
        <PickCredential
          type="microsoft"
          errorCredential={uiState.errorCredential}
          onCredentialResponse={onReconnected}
          onErrorCredential={(errorCredential) => {
            setUIState({
              type: 'SELECT_CREDENTIAL',
              integrationType: 'excelOnedrive',
              errorCredential,
            });
          }}
          onPickCredential={(credential) => {
            setUIState({
              integrationType: 'excelOnedrive',
              type: 'PICKING_DOCUMENT',
              credentialId: credential.id,
              accessToken: credential.accessToken,
              sheetError: null,
            });
          }}
          onClose={onClose}
        />
      );
    }

    case 'UPLOAD_DOCUMENT': {
      return (
        <MicrosoftExcelUploadPanel
          onPickWorkbook={({ workbook, documentMeta, valueTypes }) => {
            setUIState({
              type: 'PICKING_SHEET',
              integrationType: 'excelUpload',
              workbook,
              documentMeta,
              valueTypes,
            });
          }}
        />
      );
    }

    case 'PICKING_DOCUMENT': {
      return (
        <MicrosoftDocumentPicker
          accessToken={uiState.accessToken}
          onClose={onClose}
          onPickWorkbook={({ workbook, documentMeta, valueTypes }) => {
            setUIState({
              type: 'PICKING_SHEET',
              integrationType: 'excelOnedrive',
              credentialId: uiState.credentialId,
              workbook,
              documentMeta,
              valueTypes,
            });
          }}
        />
      );
    }
    case 'DOCUMENT_NOT_FOUND': {
      return (
        <MicrosoftDocumentNotFound
          onClose={onClose}
          onContinue={() => {
            setUIState({
              type: 'PICKING_DOCUMENT',
              integrationType: 'excelOnedrive',
              credentialId: uiState.credentialId,
              accessToken: uiState.accessToken,
              sheetError: null,
            });
          }}
        />
      );
    }
    case 'PICKING_SHEET': {
      return (
        <MicrosoftExcelSheetPicker
          documentMeta={uiState.documentMeta}
          onClose={onClose}
          onPickSheet={async (tableData, selectionData) => {
            if (uiState.integrationType === 'excelUpload') {
              return onCreateTable(createTableBlock(tableData));
            }

            setUIState({
              type: 'LOADING_DOCUMENT',
              integrationType: uiState.integrationType,
            });

            const integration = await handleIntegrationData({
              credentialId: uiState.credentialId,
              provider: 'microsoft',
              providerMeta: {
                documentId: uiState.documentMeta.documentId,
                documentName: uiState.documentMeta.documentName,
                selectionName: selectionData.sheetOrRangeName,
                selectionId: selectionData.sheetId,
                selectionType: selectionData.selectionType,
                driveId: uiState.documentMeta.driveId,
              },
            });

            onCreateTable(
              createTableBlock(tableData, { integrationId: integration.id }),
            );

            addIntegration({ ...integration, provider: 'microsoft' });
          }}
          workbook={uiState.workbook}
          valueTypes={uiState.valueTypes}
        />
      );
    }

    case 'LOADING_DOCUMENT': {
      return <ZeckCommunicationLoader />;
    }

    default: {
      return null;
    }
  }
};
