import { PublishedSection, Comment } from 'api-client/types.ts';
import { getTextFromNodes } from 'editor-content/TextNode.ts';
import getContentSelection from '../../../getContentSelection.ts';
import { Block, isTextBlock } from 'editor-content/Block.ts';
import { isBlockChangedForComments } from './isBlockChangedForComments.ts';

export type CommentEffectiveSelectionSubset = Pick<
  Comment,
  'content' | 'selection'
>;

export type CommentSelectionNoneState = {
  selectionEffectiveState: 'none';
};

export type CommentSelectionBlockNotFoundState = {
  selectionEffectiveState: 'blockNotFound';
};

export type CommentSelectionSelectionTextNotFoundState = {
  selectionEffectiveState: 'selectionTextNotFound';
  blockIndex: number;
};

export type CommentSelectionValidState = {
  selectionEffectiveState: 'valid';
  blockHasChanged: boolean;
  blockIndex: number;
  effectiveStartOfSelection: number | null;
};

export type CommentSelectionState =
  | CommentSelectionNoneState
  | CommentSelectionBlockNotFoundState
  | CommentSelectionSelectionTextNotFoundState
  | CommentSelectionValidState;

export const isNoneCommentState = (comment: CommentSelectionState): boolean => {
  return comment.selectionEffectiveState === 'none';
};

export const isBlockNotFoundCommentState = (
  comment: CommentSelectionState,
): boolean => {
  return comment.selectionEffectiveState === 'blockNotFound';
};

export const isValidCommentState = <T extends CommentSelectionState>(
  comment: T,
): comment is T & CommentSelectionValidState => {
  return comment.selectionEffectiveState === 'valid';
};

export const isSelectionTextNotFoundCommentState = (
  comment: CommentSelectionState,
): boolean => {
  return comment.selectionEffectiveState === 'selectionTextNotFound';
};

export const getEffectiveCommentContent = (
  comment: CommentEffectiveSelectionSubset,
  publishedSection: PublishedSection,
): CommentSelectionState => {
  if (comment.selection === null) {
    return {
      selectionEffectiveState: 'none',
    };
  }

  const { block, snippetSelection } = comment.selection;

  const blockIndex = publishedSection.body.findIndex(
    (publishedBlock) => publishedBlock.id === block.id,
  );

  const publishedBlock: Block | undefined = publishedSection.body[blockIndex];

  if (blockIndex === -1 || !publishedBlock) {
    return {
      selectionEffectiveState: 'blockNotFound',
    };
  }

  const blockHasChanged = isBlockChangedForComments(block, publishedBlock);

  if (
    !isTextBlock(block) ||
    !isTextBlock(publishedBlock) ||
    !snippetSelection
  ) {
    return {
      selectionEffectiveState: 'valid',
      blockIndex,
      effectiveStartOfSelection: null,
      blockHasChanged,
    };
  }

  const effectiveContentSelection = getContentSelection(
    getTextFromNodes(block.content),
    getTextFromNodes(publishedBlock.content),
    snippetSelection,
  );

  if (!effectiveContentSelection) {
    return {
      selectionEffectiveState: 'selectionTextNotFound',
      blockIndex,
    };
  }

  return {
    selectionEffectiveState: 'valid',
    blockIndex,
    effectiveStartOfSelection: Math.min(
      effectiveContentSelection.focusOffset,
      effectiveContentSelection.anchorOffset,
    ),
    blockHasChanged,
  };
};
