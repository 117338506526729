import { KeyboardEventHandler, useRef } from 'react';
import getSelectionRangeSafely from '../../../../editor/selection/contentSelection/getSelectionRangeSafely.js';
import { isOnFirstLine } from '../../../../editor/selection/contentSelection/lineDetection/isOnFirstLine.js';
import { isOnLastLine } from '../../../../editor/selection/contentSelection/lineDetection/isOnLastLine.js';

const selectionReachesStartOfBlock = (
  containerNode: HTMLElement,
  range: Range,
) => {
  const rangeForMeasurement = range.cloneRange();
  rangeForMeasurement.collapse(true);
  rangeForMeasurement.setStartBefore(containerNode);
  return rangeForMeasurement.toString().length === 0;
};
const selectionReachesEndOfBlock = (
  containerNode: HTMLElement,
  range: Range,
) => {
  const rangeForMeasurement = range.cloneRange();
  rangeForMeasurement.collapse(false);
  rangeForMeasurement.setEndAfter(containerNode);
  return rangeForMeasurement.toString().length === 0;
};

type UseTextBlockKeyboardEventsHandlers = {
  onArrowUpOut(): void;
  onArrowDownOut(): void;
  onSelectOut(): void;
};

/**
 * @Deprecated Put key listeners in the top-level editor listener
 */
const useTextBlockKeyboardEvents = <ElementType extends HTMLElement>({
  onArrowUpOut,
  onArrowDownOut,
  onSelectOut,
}: UseTextBlockKeyboardEventsHandlers) => {
  const ref = useRef<ElementType>(null);

  const handleKeyDown: KeyboardEventHandler = (e) => {
    const containerNode = ref.current;
    const range = getSelectionRangeSafely();
    if (!range || !containerNode) return;

    if (e.key === 'ArrowUp') {
      if (e.getModifierState('Shift')) {
        if (selectionReachesStartOfBlock(containerNode, range)) {
          e.preventDefault();
          onSelectOut();
          return;
        }

        return;
      }
      if (range.collapsed) {
        if (isOnFirstLine(containerNode, range)) {
          e.preventDefault(); //stops the caret from first jumping to the top
          onArrowUpOut();
        }
      }
    } else if (e.key === 'ArrowDown') {
      if (e.getModifierState('Shift')) {
        if (selectionReachesEndOfBlock(containerNode, range)) {
          e.preventDefault();
          onSelectOut();
          return;
        }

        return;
      }
      if (range.collapsed) {
        if (isOnLastLine(containerNode, range)) {
          e.preventDefault(); //stops the caret from first jumping to the top
          onArrowDownOut();
        }
      }
    }
  };

  return { handleKeyDown, ref };
};

export default useTextBlockKeyboardEvents;
