import {
  PrevoteResult,
  PrevoteTally,
} from '../../../pages/zeck/voting/VoteCapability.js';
import { VoteDetails } from '../vote/Vote.tsx';
import VoteControlHeading from '../vote/VoteControlHeading.tsx';
import styles from './PrevoteTally.module.scss';

const PREVOTE_TALLY_TOOLTIP =
  'Board Directors that are also Viewers of this Zeck are included in Pre-vote tallies.';
const PrevoteTally: React.FC<{
  prevoteTally: PrevoteTally;
  prevoteResults?: PrevoteResult;
  className?: string;
}> = ({ prevoteResults, prevoteTally, className }) => {
  return (
    <VoteDetails className={className}>
      <span className={styles.voteCounts}>
        <span data-testid="prevote-tally">
          <VoteControlHeading tooltip={PREVOTE_TALLY_TOOLTIP}>
            {prevoteTally.votedBoardDirectorCount} out of{' '}
            {prevoteTally.boardDirectorCount} board directors have pre-voted.
          </VoteControlHeading>
        </span>
        {prevoteResults && (
          <span
            className={styles.voteCounts__results}
            data-testid="prevote-result"
          >
            <span data-testid="prevote-approve">
              <span className={styles.voteCounts__approve}>Approved: </span>
              {prevoteResults.approvedCount}
            </span>

            <span data-testid="prevote-not-approve">
              <span className={styles.voteCounts__notApprove}>
                Not Approved:{' '}
              </span>
              {prevoteResults.notApprovedCount}
            </span>

            <span data-testid="prevote-abstain">
              <span className={styles.voteCounts__abstain}>Abstained: </span>
              {prevoteResults.abstainedCount}
            </span>
          </span>
        )}
      </span>
    </VoteDetails>
  );
};

export default PrevoteTally;
