import styles from './EditAppearanceSidebar.module.scss';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';
import { useState } from 'react';
import ImageUploadModal from '../image/ImageUploadModal.tsx';
import useSidebarAnimation from '../../../../services/useSidebarAnimation.ts';
import UploadedImage from '../../UploadedImage.tsx';
import defaultImagePath from '../../../../images/defaultZeckCoverBackground.jpg';
import ZeckWithActions from './ZeckWithActions.ts';
import Toast from '../../../../design-system/molecules/toast/Toast.tsx';
import useToast from '../../../../design-system/molecules/toast/useToast.ts';

type EditAppearanceSidebarProps = {
  zeck: ZeckWithActions;
  isOpen: boolean;
  onCloseSidebar: () => void;
};

export default function EditAppearanceSidebar({
  zeck,
  isOpen,
  onCloseSidebar,
}: EditAppearanceSidebarProps) {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { elementRef, show } = useSidebarAnimation<HTMLDivElement>(
    'right',
    isOpen,
  );

  const { showToast, ...toast } = useToast();

  return (
    <>
      {show && (
        <div
          className={styles.sidebar}
          ref={elementRef}
          data-testid="right-sidebar"
        >
          <div className={styles.header}>
            Cover Appearance
            <IconButton
              name="close"
              aria-label="close right sidebar"
              onClick={onCloseSidebar}
              className={styles.close}
            />
          </div>

          <div className={styles.background}>
            Background
            <button
              aria-label="upload cover"
              onClick={() => {
                setShowUploadModal(true);
              }}
              className={styles.uploadImageButton}
            >
              {zeck.coverImageId ? (
                <UploadedImage
                  imageId={zeck.coverImageId}
                  spinnerSize="medium"
                  onClick={() => {
                    setShowUploadModal(true);
                  }}
                  className={styles.image}
                  imagePreset="thumbnail"
                  alt="zeck cover"
                />
              ) : (
                <img
                  src={defaultImagePath}
                  className={styles.image}
                  alt="zeck cover"
                />
              )}
            </button>
          </div>

          <ImageUploadModal
            isOpen={showUploadModal}
            onUploadSuccess={({ imageId }) => {
              setShowUploadModal(false);
              zeck.actions.update({ coverImageId: imageId });
            }}
            onClose={() => {
              setShowUploadModal(false);
            }}
            showToast={showToast}
          />
        </div>
      )}
      <Toast {...toast} />
    </>
  );
}
