import getSelectionRangeSafely from '../getSelectionRangeSafely.js';
import { getColumnMatchedContentSelectionFromTop } from './getColumnMatchedContentSelection.js';
import { ElementAndData } from '../../../../junkDrawer/useElementAndDataArray.js';
import { MaybeHandled } from '../../../EditorAction.js';
import ContentSelection from '../ContentSelection.js';

export const fancyNavDown =
  <BlockEditor>(blockEditorsWithEl: ElementAndData<BlockEditor>[]) =>
  (
    blockIndex: number,
    newBlockIndex: number,
  ): MaybeHandled<ContentSelection> => {
    const selectedBlockEditorWithEl = blockEditorsWithEl[blockIndex];
    const selectionRange = getSelectionRangeSafely();

    if (!(selectionRange && selectedBlockEditorWithEl)) return;

    const blockEl = selectedBlockEditorWithEl.getEl();
    if (!blockEl) return;

    const prevBlockEditorWithEl = blockEditorsWithEl[newBlockIndex];

    if (!prevBlockEditorWithEl) return;

    const prevBlockEl = prevBlockEditorWithEl.getEl();

    if (!prevBlockEl) return;

    return getColumnMatchedContentSelectionFromTop(selectionRange, prevBlockEl);
  };
